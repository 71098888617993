import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { TOP_FORM, TABLE_LABEL, TABLE_CONFIG } from './config/list';
import { DIALOG_FORM_CONFIG, DIALOG_FORM_DATA, DIALOG_FORM_DETAIL } from './config/add';
import { cloneDeep } from 'lodash';
import { Message } from 'element-ui';
import { useSuccessTip } from '@/hooks/use-tip';
import { getHeaders } from '@/api-new/service';
import url from '@/api-new/url';
import api from '@/api-new';
export default {
  name: 'PageInfoList',
  data: function data() {
    return {
      filtersData: {},
      ids: [],
      title: '',
      topForm: cloneDeep(TOP_FORM),
      dialogFormConfig: cloneDeep(DIALOG_FORM_CONFIG),
      dialogFormDetail: cloneDeep(DIALOG_FORM_DETAIL),
      dialogFormData: cloneDeep(DIALOG_FORM_DATA),
      dialogVisible: false,
      dialogTitle: '新增',
      tableConfig: cloneDeep(TABLE_CONFIG),
      tableLabel: cloneDeep(TABLE_LABEL),
      imageUrl: process.env.VUE_APP_CLOUD_UPLOAD,
      dialogDetailVisible: false
    };
  },
  // computed: {
  //   computedList() {
  //     return function(str) {
  //       return [ this.computedSrc(str) ]
  //     }
  //   },
  //   computedSrc() {
  //     return function(str) {
  //       return `${this.imageUrl}${str}`
  //     }
  //   },
  // },
  methods: {
    /* 弹窗确认 */
    dialogConfirm: function dialogConfirm() {
      var _this = this;

      var _this$dialogFormData = this.dialogFormData,
          _this$dialogFormData$ = _this$dialogFormData.linkUrl,
          linkUrl = _this$dialogFormData$ === void 0 ? {} : _this$dialogFormData$,
          coverUrl = _this$dialogFormData.coverUrl;
      var _linkUrl$url = linkUrl.url,
          lUrl = _linkUrl$url === void 0 ? '' : _linkUrl$url;
      var _coverUrl$url = coverUrl.url,
          cUrl = _coverUrl$url === void 0 ? '' : _coverUrl$url;

      var params = _objectSpread(_objectSpread({}, this.dialogFormData), {}, {
        linkUrl: lUrl,
        coverUrl: cUrl
      });

      api.ADD_INFO(params).then(function (res) {
        useSuccessTip();

        _this.refresh();

        _this.dialogVisible = false;
      }).catch(function () {});
    },

    /* 弹窗确认 */
    dialogSubmit: function dialogSubmit() {},

    /** 接口参数过滤函数 */
    filtersFormatter: function filtersFormatter(params) {
      var startTime = params.startTime,
          endTime = params.endTime;
      delete params.releaseTime;
      return _objectSpread(_objectSpread({}, params), {}, {
        startTime: startTime ? "".concat(startTime, " 00:00:00") : null,
        endTime: endTime ? "".concat(endTime, " 23:59:59") : null
      });
    },

    /* 点击新增 */
    tableClick: function tableClick(params) {
      console.log('params: ', params);

      var _ref = params || {},
          clickItem = _ref.clickItem,
          selections = _ref.selections;

      var _ref2 = clickItem || {},
          type = _ref2.type;

      if (type === 'add') {
        this.dialogTitle = '新增';
        this.dialogFormData = {};
        this.dialogVisible = true;
      }
    },

    /** 点击表格项 */
    tableRowClick: function tableRowClick(action) {
      var _ref3 = action || {},
          clickItem = _ref3.clickItem,
          row = _ref3.row,
          type = _ref3.type;
      /* 标题跳转 */


      if (type === 'link') {
        this.dialogDetailVisible = true;
        this.getInfoDetail(row.id);
      } else if (type === 'operation') {
        /* 操作 */
        switch (clickItem.type) {
          case 'edit':
            this.dialogTitle = '修改';
            this.dialogVisible = true;
            this.getInfoDetail(row.id);
            break;

          case 'start':
            this.updateStatus(row);
            break;

          case 'stop':
            this.updateStatus(row);
            break;

          case 'copy':
            this.copyUrl(row);
            break;
        }
      }
    },
    copyUrl: function copyUrl(row) {
      var url = "".concat(this.imageUrl).concat(row.linkUrl);
      var input = document.createElement('input');
      document.body.appendChild(input);
      input.setAttribute('value', url);
      input.select();
      document.execCommand("copy"); // 执行浏览器复制命令

      if (document.execCommand('copy')) {
        document.execCommand('copy');
        useSuccessTip();
      }

      document.body.removeChild(input);
    },
    getInfoDetail: function getInfoDetail(id) {
      var _this2 = this;

      api.GET_INFO({
        id: id
      }).then(function (res) {
        res.linkUrl = {
          url: res.linkUrl
        };
        res.coverUrl = {
          url: res.coverUrl
        };
        _this2.dialogFormData = res;
      });
    },
    updateStatus: function updateStatus(row) {
      var id = row.id,
          status = row.status;
      var isStart = status === 1; // 是否为启用

      api.UPDATE_MATERIAL({
        id: id,
        status: isStart ? 0 : 1
      });
      useSuccessTip();
      this.refresh();
    },

    /* 审核不通过时显示拒绝理由输入框 */
    auditStatusChange: function auditStatusChange(data) {
      // console.log(data, 'data');
      if (data.prop === 'releaseStatus' && data.value === 1) {
        dialogFormConfig.value.failReviewReason.hidden = true;
      }

      if (data.prop === 'releaseStatus' && data.value === 4) {
        dialogFormConfig.value.failReviewReason.hidden = false;
      }
    },

    /* 获取资讯审核详情 */
    getInfoById: function getInfoById(row) {
      dialogFormData.value = _objectSpread(_objectSpread({}, row), {}, {
        releaseStatus: 1,
        failReviewReason: ''
      });
    },

    /** 刷新列表 */
    refresh: function refresh() {
      this.$refs.jPage.getPageData();
    }
  },
  created: function created() {
    var host = url.host,
        apiPrev = url.apiPrev;
    var apiUrl = url.info.material;
    /** 表格请求配置 */

    this.httpConfig = {
      url: "".concat(host).concat(apiPrev).concat(apiUrl.getReviewPageList),
      headers: getHeaders()
    };
  }
};