import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var PUBLIC_CONFIG = {
  labelWidth: 120,
  width: 200
};
export var TOP_FORM = {
  contractCd: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '合同编码',
    props: {
      placeholder: '请输入合同编码'
    }
  }),
  isVvip: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '是否VVIP合同',
    col: 6,
    props: {
      placeholder: '请选择'
    },
    options: [{
      label: '是',
      value: 1
    }, {
      label: '否',
      value: 0
    }]
  })
};
export var TABLE_CONFIG = {
  isSelect: true,
  rowKey: 'id',
  actions: [{
    label: '标识VVIP合同',
    type: 'upgradeContractToVvip',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'Customer-upgradeContractToVvip'
  }, {
    label: '取消VVIP合同',
    type: 'cancelContractVvip',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'Customer-cancelContractVvip'
  }]
};
export var TABLE_LABEL = [{
  label: '合同编码',
  prop: 'contractCd',
  width: 180
}, {
  label: '购销合同号',
  prop: 'buyContractCd',
  width: 180
}, {
  label: '状态',
  prop: 'contractStateValue'
}, {
  label: '产品',
  prop: 'productName'
}, {
  label: '合同金额',
  prop: 'amount'
}, {
  label: '开始日期',
  prop: 'startDate'
}, {
  label: '结束日期',
  prop: 'endDate'
}, {
  label: '期限（月）',
  prop: 'timeLimit'
}, {
  label: '是否是VVIP合同',
  prop: 'isVvip',
  width: 100,
  formatter: function formatter(row, column, cellValue) {
    var res = '';

    switch (cellValue) {
      case 1:
        res = '是';
        break;

      case 0:
        res = '否';
        break;

      default:
        res = '';
    }

    return res;
  }
}];