import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState, mapActions } from 'vuex';
export default {
  computed: _objectSpread({}, mapState('d2admin/user', ['info'])),
  methods: _objectSpread(_objectSpread({}, mapActions('d2admin/account', ['logout'])), {}, {
    /**
     * @description 登出
     */
    logOff: function logOff() {
      this.logout({
        confirm: true
      });
    }
  })
};