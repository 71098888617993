// 转让状态
export default {
  SAVE: {
    value: '1',
    text: '待提交'
  },
  SUBMIT: {
    value: '2',
    text: '待客服审核'
  },
  AUDIT: {
    value: '3',
    text: '待结算审核'
  }
};