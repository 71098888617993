import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { TOP_FORM, TABLE_LABEL, TABLE_CONFIG } from './config/list';
import { DIALOG_FORM_CONFIG, DIALOG_FORM_DATA } from './config/check';
import { cloneDeep } from 'lodash-es';
import { getHeaders } from '@/api-new/service';
import { useSuccessTip } from '@/hooks/use-tip';
import { getOrgTree } from '@api/rm/org';
import api from '@/api-new';
import url from '@/api-new/url';
export default {
  name: 'PageToBeReviewed',
  data: function data() {
    return {
      /* 弹窗数据 */
      dialogFormData: cloneDeep(DIALOG_FORM_DATA),

      /* 弹窗是否显示 */
      dialogVisible: false,

      /* 弹窗标题 */
      dialogTitle: '审核',
      dialogFormConfig: cloneDeep(DIALOG_FORM_CONFIG),
      dialogTitle2: '批量审核',
      dialogFormConfig2: {
        auditStatus: {
          type: 'radio',
          label: '执行操作',
          options: [{
            label: '审核通过',
            value: 1
          }, {
            label: '审核不通过',
            value: 0
          }],
          rules: [{
            required: true,
            message: '状态不能为空',
            trigger: 'blur'
          }]
        },
        auditRemark: {
          type: 'input',
          label: '拒绝原因',
          rules: [{
            required: true,
            message: '拒绝原因不能为空',
            trigger: 'blur'
          }],
          props: {
            type: 'textarea',
            placeholder: '请输入拒绝原因',
            rows: 4,
            maxlength: 200,
            showWordLimit: true
          },
          hidden: true
        }
      },
      dialogFormData2: {
        auditStatus: 1,
        auditRemark: ''
      },
      topForm: cloneDeep(TOP_FORM),
      tableLabel: cloneDeep(TABLE_LABEL),
      tableConfig: cloneDeep(TABLE_CONFIG),

      /** 表格请求配置 */
      httpConfig: {},
      ids: [],
      title: '',
      dialogVisible2: false,
      filtersData: {
        activityStatus: 2
      }
    };
  },
  created: function created() {
    this.getAllOrganization();
    this.getClassifyList();
    this.getTypeList();
    var host = url.host,
        apiPrev = url.apiPrev;
    var apiUrl = url.activity.manage;
    this.httpConfig = {
      url: "".concat(host).concat(apiPrev).concat(apiUrl.listActivity),
      headers: getHeaders()
    };
  },
  methods: {
    formatChildData: function formatChildData(data, childStr) {
      if (data && data.length) {
        data.forEach(function (val) {
          if (val[childStr] && val[childStr].length < 1) {
            delete val[childStr];
          } else {
            this.formatChildData(val[childStr], childStr);
          }
        }.bind(this));
        return data;
      }
    },

    /* 获取发布组织下拉 */
    getAllOrganization: function getAllOrganization() {
      var _this = this;

      getOrgTree({}).then(function (res) {
        _this.topForm.orgCd.options = _this.formatChildData([res.data], 'children');
      });
    },

    /* 获取所有活动分类 */
    getClassifyList: function getClassifyList() {
      var _this2 = this;

      api.GET_CLASSIFY_LIST().then(function (res) {
        _this2.topForm.classifyId.options = res;
      });
    },

    /* 获取所有活动类型 */
    getTypeList: function getTypeList() {
      var _this3 = this;

      api.GET_TYPE_LIST().then(function (res) {
        _this3.topForm.activityType.options = res || [];
      });
    },

    /** 接口参数过滤函数 */
    filtersFormatter: function filtersFormatter(params) {
      var startTime = params.startTime,
          endTime = params.endTime;
      delete params.createTime;
      return _objectSpread(_objectSpread({}, params), {}, {
        activityStatus: 2,
        auditStatus: 0,
        // 0：待审核，1：审核成功， 2：拒绝
        startTime: startTime ? "".concat(startTime, " 00:00:00") : null,
        endTime: endTime ? "".concat(endTime, " 23:59:59") : null
      });
    },

    /* 弹窗确认 */
    dialogConfirm: function dialogConfirm() {
      var _this4 = this;

      this.$nextTick(function () {
        _this4.$refs.form.submitForm();
      });
    },
    dialogConfirm2: function dialogConfirm2() {
      var _this5 = this;

      this.$nextTick(function () {
        _this5.$refs.form2.submitForm();
      });
    },
    close: function close() {
      this.dialogVisible2 = false;
    },

    /* 弹窗表单确认 */
    dialogSubmit: function dialogSubmit() {
      this.dialogVisible = false;
      this.review();
    },
    dialogSubmit2: function dialogSubmit2() {
      this.dialogVisible2 = false;
      this.batchReview();
    },

    /* 操作按钮 */
    tableRowClick: function tableRowClick(action) {
      var _ref = action || {},
          clickItem = _ref.clickItem,
          row = _ref.row;

      var _ref2 = clickItem || {},
          type = _ref2.type;

      console.log('type: ', type);

      switch (type) {
        // 处理列表审核
        case 'audit':
          this.dialogVisible = true;
          this.getDetail(row.id);
          break;
      }
    },

    /* 点击新增 */
    tableClick: function tableClick(params) {
      console.log('params: ', params);

      var _ref3 = params || {},
          clickItem = _ref3.clickItem,
          selections = _ref3.selections;

      var _ref4 = clickItem || {},
          type = _ref4.type;

      if (type === 'batchAudit') {
        // if (selections.length > 0) {

        /* 批量删除 */
        this.ids = selections.map(function (item) {
          return item.id;
        });
        this.title = '已选择' + this.ids.length + '条数据，批量操作请仔细核对';
        this.dialogVisible2 = true; // } else {
        //   this.$message({
        //     type: 'warning',
        //     message: '请勾选需要审批的项！',
        //   });
        // }
      }
    },

    /* 审核 */
    review: function review() {
      var _this6 = this;

      var _this$dialogFormData = this.dialogFormData,
          id = _this$dialogFormData.id,
          auditStatus = _this$dialogFormData.auditStatus,
          auditRemark = _this$dialogFormData.auditRemark;
      var params = {
        ids: [id],
        auditStatus: auditStatus,
        remark: auditRemark
      };
      api.MANAGE_AUDIT(params).then(function () {
        // this.dialogFormConfig.failReviewReason.hidden = true;
        useSuccessTip();

        _this6.refresh();
      });
    },

    /** 批量审核 */
    batchReview: function batchReview() {
      var _this7 = this;

      var _this$dialogFormData2 = this.dialogFormData2,
          auditStatus = _this$dialogFormData2.auditStatus,
          auditRemark = _this$dialogFormData2.auditRemark;
      var params = {
        ids: this.ids,
        auditStatus: auditStatus,
        remark: auditRemark
      };
      api.AUDIT(params).then(function () {
        useSuccessTip();

        _this7.refresh();
      });
    },

    /* 获取弹窗详情 */
    getDetail: function getDetail(id) {
      var _this8 = this;

      api.GET_ACTIVITY_DETAIL(id).then(function (res) {
        _this8.dialogFormData = res; // if (this.dialogFormData.activityRequire !== '') {
        //   this.dialogFormData.activityRequires = this.dialogFormData.activityRequire.split(',');
        // }
      });
    },

    /* 审核不通过时显示拒绝理由输入框 */
    auditStatusChange: function auditStatusChange(data) {
      // console.log(data, 'data');
      if (data.prop === 'auditStatus' && data.value === 1) {
        this.dialogFormConfig.auditRemark.hidden = true;
      } else if (data.prop === 'auditStatus' && data.value === 0) {
        this.dialogFormConfig.auditRemark.hidden = false;
      }
    },
    auditStatusChangeBatch: function auditStatusChangeBatch(data) {
      if (data.prop === 'auditStatus' && data.value === 1) {
        this.dialogFormConfig2.auditRemark.hidden = true;
      } else if (data.prop === 'auditStatus' && data.value === 0) {
        this.dialogFormConfig2.auditRemark.hidden = false;
      }
    },

    /** 刷新列表 */
    refresh: function refresh() {
      this.$refs.jPage.getPageData();
    }
  }
};