import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import SearchUser from "@/components/business/search-user";
import { addBatchSpecial } from "@api/crm/commission/commission";
export default {
  name: "commission-special",
  props: {
    propsModel: {
      type: String,
      default: ''
    }
  },
  components: {
    SearchUser: SearchUser
  },
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      formModel: {},
      //表单
      showSearchUser: false,
      selectData: {},
      rules: {
        bonusUserName: [{
          required: true,
          message: "请选择推介费人员",
          trigger: "input"
        }],
        bonus: [{
          required: true,
          message: "请填写推介费金额",
          trigger: "input"
        }],
        remark: [{
          required: true,
          message: "请填写特殊推介费备注",
          trigger: "input"
        }]
      }
    };
  },
  computed: {},
  created: function created() {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
        } else {
          this.formModel = {};
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.$refs.form.resetFields();
    },
    // TODO 确定
    confirm: function confirm() {
      var _this = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          var param = {
            object: _objectSpread({}, _this.formModel)
          };
          param.object.batchId = _this.propsModel;
          addBatchSpecial(param).then(function (res) {
            _this.loading = false;
            _this.showDialog = false;

            _this.$emit("refresh");
          }).catch(function (e) {
            _this.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    popSelectUser: function popSelectUser(user) {
      this.formModel.bonusUserId = user.personuuid;
      this.formModel.bonusUserName = user.name;
      this.showSearchUser = false;
    }
  }
};