var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    { staticClass: "page-table" },
    [
      _c(
        "el-row",
        { staticClass: "page-table-container", attrs: { type: "flex" } },
        [
          _c(
            "el-col",
            { staticClass: "list-container", attrs: { span: 24 } },
            [
              _c("dynamic-form", {
                ref: "form",
                attrs: { props: _vm.filterProps, forms: _vm.filterForms }
              }),
              _c(
                "el-row",
                {
                  staticClass: "actions",
                  attrs: { type: "flex", justify: "space-between" }
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: "renew-newAdd",
                              expression: "'renew-newAdd'"
                            }
                          ],
                          attrs: {
                            type: "primary",
                            plain: "",
                            size: "mini",
                            icon: "el-icon-plus"
                          },
                          on: {
                            click: function($event) {
                              return _vm.clickAdd()
                            }
                          }
                        },
                        [_vm._v("新增")]
                      ),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: "renew-merge",
                              expression: "'renew-merge'"
                            }
                          ],
                          attrs: { type: "primary", plain: "", size: "mini" },
                          on: { click: _vm.clickMerge }
                        },
                        [_vm._v("合并续约")]
                      ),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: "renew-split",
                              expression: "'renew-split'"
                            }
                          ],
                          attrs: { type: "primary", plain: "", size: "mini" },
                          on: { click: _vm.clickSplit }
                        },
                        [_vm._v("拆分续约")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "mini", icon: "el-icon-refresh" },
                          on: {
                            click: function($event) {
                              return _vm.clickRefresh()
                            }
                          }
                        },
                        [_vm._v("刷新")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            plain: "",
                            size: "mini",
                            icon: "el-icon-bottom-left"
                          },
                          on: {
                            click: function($event) {
                              return _vm.clickReset()
                            }
                          }
                        },
                        [_vm._v("重置")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            plain: "",
                            size: "mini",
                            icon: "el-icon-search"
                          },
                          on: {
                            click: function($event) {
                              return _vm.clickSearch()
                            }
                          }
                        },
                        [_vm._v("查询")]
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "dynamic-table",
                {
                  ref: "jTable",
                  attrs: {
                    headers: _vm.headers,
                    table: _vm.table,
                    pagination: _vm.pagination,
                    data: _vm.tableData,
                    loading: _vm.loading
                  },
                  on: {
                    load: _vm.loadData,
                    "current-change": _vm.selectRow,
                    "selection-change": _vm.handleSelectionChange
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "contractState",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c(
                            "span",
                            {
                              style: {
                                color: _vm.setStatusColor(row.contractState)
                              }
                            },
                            [_vm._v(_vm._s(row.contractStateText))]
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("el-table-column", {
                    attrs: { slot: "operation", fixed: "right", label: "操作" },
                    slot: "operation",
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _vm.canEditStatus.includes(row.contractState) &&
                            _vm.getOriginTyepe(row)
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.clickEdit(row)
                                      }
                                    }
                                  },
                                  [_vm._v("编辑")]
                                )
                              : _c(
                                  "el-button",
                                  {
                                    staticClass: "btn-info",
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.clickInfo(row)
                                      }
                                    }
                                  },
                                  [_vm._v("查看")]
                                )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("dialog-add", {
        attrs: {
          propsModel: _vm.currentRow,
          isChangeProduct: _vm.isChangeProduct
        },
        on: { refresh: _vm.init },
        model: {
          value: _vm.showAddDialog,
          callback: function($$v) {
            _vm.showAddDialog = $$v
          },
          expression: "showAddDialog"
        }
      }),
      _c("dialog-add-hjcz", {
        attrs: {
          propsModel: _vm.currentRow,
          isChangeProduct: _vm.isChangeProduct
        },
        on: { refresh: _vm.init },
        model: {
          value: _vm.showAddHjczDialog,
          callback: function($$v) {
            _vm.showAddHjczDialog = $$v
          },
          expression: "showAddHjczDialog"
        }
      }),
      _c("dialog-add-jxwy", {
        attrs: {
          propsModel: _vm.currentRow,
          isChangeProduct: _vm.isChangeProduct
        },
        on: { refresh: _vm.init },
        model: {
          value: _vm.showAddJxwyDialog,
          callback: function($$v) {
            _vm.showAddJxwyDialog = $$v
          },
          expression: "showAddJxwyDialog"
        }
      }),
      _c("dialog-info", {
        attrs: { propsModel: _vm.currentRow },
        model: {
          value: _vm.showInfoDialog,
          callback: function($$v) {
            _vm.showInfoDialog = $$v
          },
          expression: "showInfoDialog"
        }
      }),
      _c("search-contract", {
        ref: "searchContract",
        on: { onSelectItem: _vm.popSelectContract },
        model: {
          value: _vm.showSearchContractDialog,
          callback: function($$v) {
            _vm.showSearchContractDialog = $$v
          },
          expression: "showSearchContractDialog"
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择产品",
            width: "25%",
            visible: _vm.clickEditProductVisible
          },
          on: {
            "update:visible": function($event) {
              _vm.clickEditProductVisible = $event
            },
            close: _vm.clickEditProductDialogClose
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "clickEditProductForm",
              attrs: {
                model: _vm.clickEditProductFormModel,
                rules: _vm.clickEditProductRules
              }
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品", prop: "productId" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            placeholder: "请选择",
                            filterable: ""
                          },
                          model: {
                            value: _vm.clickEditProductFormModel.productId,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.clickEditProductFormModel,
                                "productId",
                                $$v
                              )
                            },
                            expression: "clickEditProductFormModel.productId"
                          }
                        },
                        _vm._l(_vm.selectData.productId, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.text, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.clickEditProductVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.clickEditProductSubmit }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "拆分合同",
            visible: _vm.splitDialogVisible,
            width: "50%"
          },
          on: {
            "update:visible": function($event) {
              _vm.splitDialogVisible = $event
            },
            close: _vm.splitDialogClose
          }
        },
        [
          _c("el-divider", { attrs: { "content-position": "left" } }, [
            _vm._v("续约信息")
          ]),
          _c(
            "div",
            { staticStyle: { "padding-bottom": "40px" } },
            [
              _c(
                "el-row",
                { staticClass: "block-col-3" },
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("span", { staticClass: "d2-fwb" }, [
                      _vm._v("原合同编号: ")
                    ]),
                    _c("span", { staticClass: "d2-emphasis" }, [
                      _vm._v(_vm._s(_vm.splitInfo.originalContractCd))
                    ])
                  ]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("span", { staticClass: "d2-fwb" }, [
                      _vm._v("续约合同金额: ")
                    ]),
                    _c("span", { staticClass: "d2-emphasis" }, [
                      _vm._v(_vm._s(_vm.splitInfo.renewAmount))
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("el-divider", { attrs: { "content-position": "left" } }, [
            _vm._v("拆分明细")
          ]),
          _c(
            "div",
            { staticStyle: { "padding-bottom": "40px" } },
            _vm._l(_vm.splitList, function(item, index) {
              return _c(
                "el-row",
                { key: index },
                [
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "splitCode" }, [
                      index === _vm.splitList.length - 1
                        ? _c("i", {
                            staticClass: "isCursor el-icon-circle-plus-outline",
                            on: { click: _vm.splitAdd }
                          })
                        : _c("i", {
                            staticClass: "isCursor el-icon-remove-outline",
                            on: {
                              click: function($event) {
                                return _vm.splitdelete(index)
                              }
                            }
                          }),
                      _c("span", { staticStyle: { "margin-left": "5px" } }, [
                        _vm._v(" 合同编码: " + _vm._s(item.contractCd) + " ")
                      ])
                    ])
                  ]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("span", [_vm._v(" 合同金额：")]),
                    _c(
                      "span",
                      [
                        _c("el-input", {
                          staticStyle: { width: "150px" },
                          attrs: {
                            size: "mini",
                            type: "number",
                            placeholder: "请输入"
                          },
                          on: {
                            change: function($event) {
                              return _vm.handleInput($event, index)
                            }
                          },
                          model: {
                            value: item.renewAmount,
                            callback: function($$v) {
                              _vm.$set(item, "renewAmount", $$v)
                            },
                            expression: "item.renewAmount"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c("span", [_vm._v("续约方式: ")]),
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            placeholder: "请选择",
                            size: "mini"
                          },
                          on: {
                            change: function($event) {
                              return _vm.renewMethodChange($event, index)
                            }
                          },
                          model: {
                            value: item.renewMethod,
                            callback: function($$v) {
                              _vm.$set(item, "renewMethod", $$v)
                            },
                            expression: "item.renewMethod"
                          }
                        },
                        _vm._l(_vm.selectData.renewMethod, function(item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.text, value: item.value }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }),
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: { click: _vm.splitDialogClose }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.splitSubmit }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }