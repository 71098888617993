import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import COLOR from '@/maps/enum/color';
import STATUS from '@/maps/enum/status/buy';
import PRODUCT_CATEGORY2 from '@/maps/enum/product-category2';
import DATA_FORMAT_TYPE from '@/maps/enum/data-format-type';
import { FILTER_PROPS, FILTER_FORMS } from './option/filter'; // 过滤条件的form

import TABLE_LIST from './option/list'; // 表头

import { list, exportExcelSAPSaleTemplate, exportExcelSAPInvoiceTemplate, exportExcelJYFJewelrySaleTemplate, exportExcelJYFJewelryInheritSaleTemplate } from '@api/crm/buy/buy';
import DynamicForm from '@/components/common/dynamic-form';
import DynamicTable from '@/components/common/dynamic-table';
import DialogInfo from '../dialog-info';
export default {
  name: 'buy-sap',
  components: {
    DynamicForm: DynamicForm,
    DynamicTable: DynamicTable,
    DialogInfo: DialogInfo
  },
  data: function data() {
    return {
      loading: false,
      filterProps: FILTER_PROPS,
      filterForms: FILTER_FORMS,
      headers: TABLE_LIST,
      table: {
        auto: false
      },
      pagination: {},
      query: {
        arrayProductCategory2: [PRODUCT_CATEGORY2.HJCZ, PRODUCT_CATEGORY2.HJWT, PRODUCT_CATEGORY2.HJXLS]
      },
      tableData: [],
      showInfoDialog: false,
      // 展示信息
      currentRow: {} // 当前点击行

    };
  },
  computed: {},
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
      this.$refs.jTable.pageChange(1);
    },
    //初始化搜索条件
    initFilter: function initFilter() {
      var query = this.$refs.form.getForm();
      this.query = _objectSpread(_objectSpread({}, this.query), query);
    },
    //点击刷新
    clickRefresh: function clickRefresh() {
      this.$refs.jTable.onRefresh();
      this.$message({
        message: '数据已刷新',
        type: 'info',
        duration: 500
      });
    },
    //点击重置
    clickReset: function clickReset() {
      this.$refs.form.reset();
    },
    // TODO 搜索
    clickSearch: function clickSearch() {
      this.initFilter();
      this.$refs.jTable.pageChange(1);
    },
    // TODO 获取列表
    loadData: function loadData(_ref) {
      var _this = this;

      var _ref$page = _ref.page,
          page = _ref$page === void 0 ? {} : _ref$page;
      this.loading = true;
      this.initFilter();
      list({
        page: page,
        query: this.query
      }, DATA_FORMAT_TYPE.USRE_SETUP).then(function (res) {
        _this.loading = false;
        _this.tableData = [];

        if (res.data != null) {
          var _res$data = res.data,
              data = _res$data === void 0 ? [] : _res$data,
              _page = res.page;
          var _page$totalCount = _page.totalCount,
              totalCount = _page$totalCount === void 0 ? 0 : _page$totalCount;
          _this.tableData = data;
          _this.pagination = {
            total: totalCount
          };
        } else {
          _this.$message({
            message: '暂无数据',
            type: 'info'
          });
        }
      }).catch(function (e) {
        _this.loading = false;

        _this.$message({
          message: '查询列表数据出错',
          type: 'error'
        });
      });
    },
    // TODO 选中行
    selectRow: function selectRow(row) {
      this.currentRow = row;
    },
    //查看详情
    clickInfo: function clickInfo() {
      var row = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.currentRow = row;
      this.showInfoDialog = true;
    },
    //首饰销售订单
    clickExportJYFJewelrySale: function clickExportJYFJewelrySale() {
      var _this2 = this;

      this.initFilter();

      if (this.query.arrayPayMethod.length == 0) {
        this.$message('请选择收款方式');
      } else {
        this.$confirm('确定按条件导出吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _this2.initFilter();

          var param = {
            query: _this2.query
          };
          exportExcelJYFJewelrySaleTemplate(param).catch(function (e) {
            _this2.$message({
              message: e,
              type: 'error'
            });
          });
        }).catch(function () {});
      }
    },
    //传承金销售订单
    clickExportJYFJewelryInheritSale: function clickExportJYFJewelryInheritSale() {
      var _this3 = this;

      this.initFilter();

      if (this.query.arrayPayMethod.length == 0) {
        this.$message('请选择收款方式');
      } else {
        this.$confirm('确定按条件导出吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _this3.initFilter();

          var param = {
            query: _this3.query
          };
          exportExcelJYFJewelryInheritSaleTemplate(param).catch(function (e) {
            _this3.$message({
              message: e,
              type: 'error'
            });
          });
        }).catch(function () {});
      }
    },
    //SAP销售订单
    clickExportSAPSale: function clickExportSAPSale() {
      var _this4 = this;

      this.initFilter();

      if (this.query.arrayPayMethod.length == 0) {
        this.$message('请选择收款方式');
      } else {
        this.$confirm('确定按条件导出吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _this4.initFilter();

          var param = {
            query: _this4.query
          };
          exportExcelSAPSaleTemplate(param).catch(function (e) {
            _this4.$message({
              message: e,
              type: 'error'
            });
          });
        }).catch(function () {});
      }
    },
    //SAP发票
    clickExportSAPInvoice: function clickExportSAPInvoice() {
      var _this5 = this;

      this.initFilter();

      if (this.query.arrayPayMethod.length == 0) {
        this.$message('请选择收款方式');
      } else {
        this.$confirm('确定按条件导出吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _this5.initFilter();

          var param = {
            query: _this5.query
          };
          exportExcelSAPInvoiceTemplate(param).catch(function (e) {
            _this5.$message({
              message: e,
              type: 'error'
            });
          });
        }).catch(function () {});
      }
    },
    setStatusColor: function setStatusColor(status) {
      //合同状态颜色
      var className = '';

      switch (status) {
        case STATUS.AUDIT_FAIL.value:
          className = COLOR.RED;
          break;

        case STATUS.FINANCE_AUDIT_FAIL.value:
          className = COLOR.RED;
          break;

        case STATUS.COUNT_AUDIT_FAIL.value:
          className = COLOR.RED;
          break;

        case STATUS.DEAL_FAIL.value:
          className = COLOR.RED;
          break;
      }

      return className;
    }
  },
  created: function created() {}
};