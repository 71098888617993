import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.concat.js";
//查询条件
var PUBLIC_CONFIG = {
  labelWidth: 80
};
var PUBLIC_CONFIGS = {
  labelWidth: 100
};
export var TOP_FORM = {
  // 组织选择
  orgCds: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '组织',
    props: {
      placeholder: '请选择组织',
      multiple: true,
      // clearable: true,
      collapseTags: true
    },
    options: [],
    rules: [{
      required: true,
      message: '组织不能为空',
      trigger: 'blur'
    }]
  }),
  agentName: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '理顾姓名',
    col: 6,
    props: {
      placeholder: '请输入理顾姓名'
    }
  }),
  productName: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '产品名称',
    col: 6,
    props: {
      placeholder: '请输入产品名称'
    }
  }),
  // productCode: {
  //   ...PUBLIC_CONFIG,
  //   type: 'input',
  //   label: '产品编码',
  //   col: 6,
  //   props: {
  //     placeholder: '请输入产品编码',
  //   }
  // },
  // productId: {
  //   ...PUBLIC_CONFIG,
  //   type: 'input',
  //   label: '产品Id',
  //   col: 6,
  //   props: {
  //     placeholder: '请输入产品Id',
  //   }
  // },
  achievementDate: _objectSpread(_objectSpread({}, PUBLIC_CONFIGS), {}, {
    type: 'datePicker',
    label: '统计日期',
    col: 6,
    propGroup: ['achievementDateStart', 'achievementDateEnd'],
    props: {
      type: 'daterange'
    },
    rules: [{
      required: true,
      message: '统计日期不能为空',
      trigger: 'blur'
    }]
  })
};
export var TABLE_LABEL = [{
  type: 'operation',
  label: '操作',
  // fixed: 'right',
  formatter: function formatter(row) {
    var arr = [];

    if (row.agentName !== '合计') {
      arr.push({
        type: 'jbfDetail',
        label: '明细导出',
        permission: 'financejbfachievementdetail-export'
      });
    }

    return arr;
  }
}, {
  label: '序号',
  prop: 'rank',
  width: 70
}, {
  label: '区域',
  prop: 'contractLevel3OrgName'
}, {
  label: '姓名',
  prop: 'agentName'
}, {
  label: '职位',
  prop: 'positionName'
}, {
  label: '个人业绩（单位：万元）',
  prop: 'AchievementAgent',
  columns: [{
    label: '新客户业绩',
    prop: 'newCusAchievementAgent'
  }, {
    label: '前端产品',
    prop: 'frontAchievementAgent'
  }, {
    label: '后端产品',
    prop: 'endAchievementAgent'
  }, {
    label: '信托产品',
    prop: 'xtAchievementAgent'
  }, {
    label: 'XQS',
    prop: 'xqsAchievementAgent'
  }, {
    label: '小计',
    prop: 'subAchievementAgent'
  }, {
    label: '同比',
    prop: 'yachievementAgent',
    type: 'html',
    formatter: function formatter(row, column, cellValue) {
      // return row.yachievementAgent + "\n" + row.yrateAgent + '%';
      if (Number(row.yrateAgent) > 0) {
        return "<div style='color: #606266;'>".concat(row.yachievementAgent, "</div><div style='color: red;'>").concat(row.yrateAgent, "%</div>");
      } else if (Number(row.yrateAgent) < 0) {
        return "<div style='color: #606266;'>".concat(row.yachievementAgent, "</div><div style='color: green;'>").concat(row.yrateAgent, "%</div>");
      }

      return "<div style='color: #606266;'>".concat(row.yachievementAgent, "</div><div style='color: #606266;'>").concat(row.yrateAgent, "%</div>");
    }
  }, // {
  //   label: '同比',
  //   prop: 'yrateAgent',
  //   formatter: function (row, column, cellValue) {
  //     return row.yrateAgent + '%';
  //   },
  // },
  {
    label: '环比',
    prop: 'machievementAgent',
    type: 'html',
    formatter: function formatter(row, column, cellValue) {
      if (Number(row.mrateAgent) > 0) {
        return "<div style='color: #606266;'>".concat(row.machievementAgent, "</div><div style='color: red;'>").concat(row.mrateAgent, "%</div>");
      } else if (Number(row.mrateAgent) < 0) {
        return "<div style='color: #606266;'>".concat(row.machievementAgent, "</div><div style='color: green;'>").concat(row.mrateAgent, "%</div>");
      }

      return "<div style='color: #606266;'>".concat(row.machievementAgent, "</div><div style='color: #606266;'>").concat(row.mrateAgent, "%</div>");
    }
  } // {
  //   label: '环比',
  //   prop: 'mrateAgent',
  //   formatter: function (row, column, cellValue) {
  //     return row.mrateAgent + '%';
  //   },
  // },
  ]
}, {
  label: '团队业绩（含个人业绩，单位：万元）',
  columns: [{
    label: '新客户业绩',
    prop: 'newCusAchievementTeam',
    formatter: function formatter(row, column, cellValue) {
      return row.leader === 1 ? row.newCusAchievementTeam : '-';
    }
  }, {
    label: '前端产品',
    prop: 'frontAchievementTeam',
    formatter: function formatter(row, column, cellValue) {
      return row.leader === 1 ? row.frontAchievementTeam : '-';
    }
  }, {
    label: '后端产品',
    prop: 'endAchievementTeam',
    formatter: function formatter(row, column, cellValue) {
      return row.leader === 1 ? row.endAchievementTeam : '-';
    }
  }, {
    label: '信托产品',
    prop: 'xtAchievementTeam',
    formatter: function formatter(row, column, cellValue) {
      return row.leader === 1 ? row.xtAchievementTeam : '-';
    }
  }, {
    label: 'XQS',
    prop: 'xqsAchievementTeam',
    formatter: function formatter(row, column, cellValue) {
      return row.leader === 1 ? row.xqsAchievementTeam : '-';
    }
  }, {
    label: '小计',
    prop: 'subAchievementTeam',
    formatter: function formatter(row, column, cellValue) {
      return row.leader === 1 ? row.subAchievementTeam : '-';
    }
  }]
}];
export var TABLE_CONFIG = {
  // isSelect: true,
  // showSummary: true,
  rowKey: 'id',
  actions: [{
    label: '导出',
    type: 'export',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'financejbfachievement-export'
  }],
  virtualScroll: true,
  // 合并最后合计单元格
  spanMethod: function spanMethod(_ref) {
    var row = _ref.row,
        columnIndex = _ref.columnIndex;

    if (row.agentName === '合计') {
      if (columnIndex === 1) {
        return {
          rowspan: 1,
          colspan: 5
        };
      } else if (columnIndex < 5) {
        return {
          rowspan: 0,
          colspan: 0
        };
      }
    }
  } // // 自定义单元格样式
  // cellStyle: ({ row, columnIndex }) => {
  //   if (columnIndex === 11) {
  //     if (Number(row.yrateAgent) > 0) {
  //       return 'color: red;';
  //     } else if (Number(row.yrateAgent) < 0) {
  //       return 'color: green;';
  //     }
  //     return 'color: #606266;';
  //   }
  //   else if (columnIndex === 13) {
  //     if (Number(row.mrateAgent) > 0) {
  //       return 'color: red;';
  //     } else if (Number(row.mrateAgent) < 0) {
  //       return 'color: green;';
  //     }
  //     return 'color: #606266;';
  //   }
  // },

};