import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import QRCode from 'qrcodejs2';
import { cloneDeep } from 'lodash';
import { FORM_CONFIG } from './config';
import { useRouter } from '@/router';
import { useSuccessTip } from '@/hooks/use-tip';
import url from '@/api-new/url';
import api from '@/api-new';
import EnumReleaseStatus from '@/maps/info/releaseStatus';
var _process$env = process.env,
    VUE_APP_SERVER = _process$env.VUE_APP_SERVER,
    VUE_APP_H5_PATH = _process$env.VUE_APP_H5_PATH,
    VUE_APP_H5_SERVER = _process$env.VUE_APP_H5_SERVER;
var apiUrl = url.info.document;
export default {
  name: 'PageInfoAdd',
  data: function data() {
    return {
      form: {
        config: cloneDeep(FORM_CONFIG),
        model: {
          informationType: 1
        }
      },
      releaseType: 1,
      releaseTime: '',
      releaseStatus: 0,
      isEdit: false,
      submiting: false,
      dialogVisible: false,
      qrcode: '',
      qr: '',
      URL: '',
      site: ''
    };
  },
  watch: {
    'form.model.informationType': {
      handler: function handler(val) {
        if (val === 2) {
          this.form.config[0].children[2].hidden = false;
          this.form.config[1].children[0].hidden = true;
          this.form.config[1].title = '';
        } else {
          this.form.config[0].children[2].hidden = true;
          this.form.config[1].children[0].hidden = false;
        }
      },
      deep: true,
      immediate: true
    }
  },
  created: function created() {
    this.init();
    var router = useRouter();
    /* 从哪跳转过来的 */

    var query = router.currentRoute.query;
    /* 判断是否为编辑 */

    this.isEdit = query.type === 'edit';
  },
  methods: {
    // 展示二维码
    payOrder: function payOrder() {
      var _this = this;

      var path = "".concat(VUE_APP_H5_SERVER).concat(VUE_APP_H5_PATH);
      var paths = "".concat(path, "pages/info/detail?id=").concat(this.$route.query.id, "&fromWeb=true");
      this.site = paths;
      this.dialogVisible = true; // 二维码内容

      this.qrcode = paths;
      this.URL = paths; // 使用$nextTick确保数据渲染

      this.$nextTick(function () {
        _this.crateQrcode();
      });
    },
    // 生成二维码
    crateQrcode: function crateQrcode() {
      this.qr = new QRCode('qrcode', {
        width: 150,
        height: 150,
        // 高度
        text: this.qrcode // 二维码内容

      }); // console.log(this.qrcode)
    },
    // 关闭弹框,清除已经生成的二维码
    hide: function hide() {
      this.$refs.qrcode.innerHTML = '';
      this.site = '';
    },

    /* 取消 */
    Cancel: function Cancel() {
      /* 跳转到资讯管理列表页 */
      this.$router.push({
        name: 'infoManager'
      });
    },

    /* 存为草稿 */
    SaveDraft: function SaveDraft() {
      this.dialogVisible = false;
      this.releaseStatus = 2;
      this.FormSubmit(this.form.model);
    },

    /* 发布 */
    Release: function Release() {
      this.releaseStatus = 1;
      this.FormSubmit(this.form.model); // this.$form.submitForm();
    },

    /* 更新 */
    Update: function Update() {
      this.releaseStatus = 1;
      this.FormSubmit(this.form.model); // this.$form.submitForm();
    },

    /** 触发表单提交 */
    FormSubmit: function FormSubmit(model) {
      var _model$coverUrl,
          _model$classifyId,
          _this2 = this;

      console.log('model: ', model);
      this.submiting = true;
      model.releaseStatus = this.releaseStatus; // releaseStatus 发布状态：0: 未发布，1：已发布，2：草稿，3：待审核，4：审核不通过

      model.releaseType = this.releaseType; // 发布类型(1:立即发布 2:定时发布)

      model.releaseTime = this.releaseTime; // 发布时间

      var _url = '';

      if (this.isEdit || model.id) {
        // 编辑修改
        _url = apiUrl.updateDocument;
      } else if (this.releaseStatus === EnumReleaseStatus.DRAFT) {
        // 草稿
        _url = apiUrl.draft;
      } else {
        _url = apiUrl.addDocument;
      }

      var params = _objectSpread(_objectSpread({}, model), {}, {
        coverUrl: (_model$coverUrl = model.coverUrl) === null || _model$coverUrl === void 0 ? void 0 : _model$coverUrl.url,
        classifyId: model.classifyId[((_model$classifyId = model.classifyId) === null || _model$classifyId === void 0 ? void 0 : _model$classifyId.length) - 1] || model.classifyId
      });

      api.COMMON_REQUEST(_url, params).then(function () {
        useSuccessTip();

        _this2.Cancel();
      }).catch(function () {
        _this2.submiting = false;
      });
    },
    findPathByLeafId: function findPathByLeafId(leafId, nodes, valueName, path) {
      if (path === undefined) {
        path = [];
      }

      for (var i = 0; i < nodes.length; i++) {
        var tmpPath = path.concat();
        tmpPath.push(nodes[i][valueName]);

        if (leafId === nodes[i][valueName]) {
          return tmpPath;
        }

        if (nodes[i].children) {
          var findResult = this.findPathByLeafId(leafId, nodes[i].children, valueName, tmpPath);

          if (findResult) {
            return findResult;
          }
        }
      }
    },

    /* 获取文章信息详情 */
    getDetail: function getDetail(id) {
      var _this3 = this;

      api.COMMON_REQUEST(apiUrl.getInfoById, {
        id: id
      }).then(function (res) {
        var _releaseType = res.releaseType,
            _releaseStatus = res.releaseStatus,
            _releaseTime = res.releaseTime,
            coverUrl = res.coverUrl;
        _this3.releaseType = _releaseType;
        _this3.releaseTime = _releaseTime;
        _this3.releaseStatus = _releaseStatus;
        _this3.form.model = res;
        _this3.form.model.coverUrl = {
          url: coverUrl
        };
        _this3.form.model.classifyId = _this3.findPathByLeafId(_this3.form.model.classifyId, _this3.form.config[0].children[4].options, 'id');
        /* 目前能跑通，后期需优化 */
      }).catch(function () {});
    },

    /* 获取所属分类下拉 */
    getChooseList: function getChooseList() {
      var _this4 = this;

      api.GET_CHOOSE_LIST().then(function (res) {
        _this4.form.config[0].children[4].options = res;
        /* 点击修改跳过来的 */

        if (_this4.isEdit) {
          _this4.getDetail(_this4.$route.query.id);
        }
      });
    },
    init: function init() {
      this.getChooseList();
    }
  }
};