import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var PUBLIC_CONFIG = {
  labelWidth: 100
}; // 弹窗中form表单配置

export var DIALOG_FORM_CONFIG = {
  operationMethod: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '调整积分',
    col: 12,
    props: {
      placeholder: '请选择调整积分'
    },
    options: [{
      label: '增加',
      value: 1
    }, {
      label: '扣减',
      value: 2
    }],
    rules: [{
      required: true,
      message: '调整积分不能为空',
      trigger: 'blur'
    }]
  }),
  score: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '分值',
    col: 12,
    rules: [{
      required: true,
      message: '分值不能为空',
      trigger: 'blur'
    }]
  }),
  validTime: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    labelWidth: 110,
    type: 'datePicker',
    label: '有效时间',
    propGroup: ['effectDate', 'expiryDate'],
    props: {
      type: 'daterange'
    }
  }),
  costOrgId: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    label: '成本机构',
    type: 'cascader',
    options: [],
    props: {
      placeholder: '请选择成本机构',
      filterable: true,
      // showAllLevels: false,
      props: {
        checkStrictly: true,
        emitPath: false,
        value: 'orgCd',
        label: 'orgName',
        children: 'children'
      }
    },
    rules: [{
      required: true,
      message: '分值不能为空',
      trigger: 'blur'
    }]
  }),
  remark: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '备注',
    props: {
      type: 'textarea',
      rows: 4,
      maxlength: 300,
      showWordLimit: true
    }
  })
};
export var DIALOG_FORM_DATA = {
  customerId: null,
  operationMethod: 1,
  score: 0,
  costOrgId: null,
  remark: null,
  // 操作类型(6: 后台操作)
  operationType: 6
};