import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var PUBLIC_CONFIG = {
  labelWidth: 100
}; // 弹窗中form表单配置

export var DIALOG_FORM_CONFIG = {
  id: {
    hidden: true
  },
  name: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '模板名称',
    props: {
      placeholder: '请输入模板名称'
    },
    rules: [{
      required: true,
      message: '模板名称不能为空',
      trigger: 'blur'
    }]
  }),
  questions: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'slot',
    label: '评分项',
    desc: '输入问题按回车以新增',
    rules: [{
      required: true,
      message: '评分项不能为空',
      trigger: 'blur'
    }]
  }),
  adviceSwitch: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'radio',
    label: '您的建议',
    options: [{
      'label': '开启',
      'value': 1
    }, {
      'label': '关闭',
      'value': 0
    }]
  }),
  sort: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'inputNumber',
    label: '排序',
    props: {
      max: 999
    }
  }),
  status: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'radio',
    label: '状态',
    options: [{
      'label': '启用',
      'value': 1
    }, {
      'label': '停用',
      'value': 0
    }]
  })
};
export var DIALOG_FORM_DATA = {
  id: null,
  name: '',
  questions: '',
  adviceSwitch: 1,
  sort: '',
  status: 1
};