import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.concat.js";

var files = require.context('./modules', true, /\.url\.js$/);

var _process$env = process.env,
    VUE_APP_SERVER = _process$env.VUE_APP_SERVER,
    VUE_APP_API_NEW = _process$env.VUE_APP_API_NEW;
export default files.keys().reduce(function (urls, key) {
  return _objectSpread(_objectSpread({}, urls), files(key).default);
}, {
  host: VUE_APP_SERVER,
  apiPrev: VUE_APP_API_NEW,
  baseURL: "".concat(VUE_APP_SERVER).concat(VUE_APP_API_NEW)
});