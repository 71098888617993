import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { TOP_FORM, TABLE_LABEL, TABLE_CONFIG } from './config/list';
import { DIALOG_FORM_CONFIG, DIALOG_FORM_DETAIL, DIALOG_FORM_DATA } from './config/addVideo';
import EnumReleaseStatus from '@/maps/info/releaseStatus';
import { cloneDeep } from 'lodash';
import { Message, MessageBox } from 'element-ui';
import { useSuccessTip } from '@/hooks/use-tip';
import { getHeaders } from '@/api-new/service';
import headers from './config/table-header';
import DynamicTable from '@/components/common/dynamic-table';
import url from '@/api-new/url';
import api from '@/api-new';
var host = url.host,
    apiPrev = url.apiPrev;
var apiUrl = url.info.document;
export default {
  name: 'PageInfoList',
  components: {
    DynamicTable: DynamicTable
  },
  data: function data() {
    return {
      releaseType: 1,
      releaseTime: '',
      dialogFormDetail: cloneDeep(DIALOG_FORM_DETAIL),
      headers: headers,
      table: {},
      tableData: [],
      pagination: {},
      topForm: cloneDeep(TOP_FORM),
      tableConfig: cloneDeep(TABLE_CONFIG),
      tableLabel: cloneDeep(TABLE_LABEL),
      httpConfig: {},
      releaseStatus: EnumReleaseStatus,

      /* 弹窗数据 */
      dialogFormData: cloneDeep(DIALOG_FORM_DATA),
      dialogFormConfig: cloneDeep(DIALOG_FORM_CONFIG),

      /* 弹窗是否显示 */
      dialogVisible: false,

      /* 弹窗标题 */
      dialogTitle: '新增视频',
      showReadDialog: false,
      infoId: '',
      // 资讯ID
      filtersData: {},
      showVideoDialog: false
    };
  },
  watch: {
    'filtersData.classifyId': {
      handler: function handler(val) {
        if (val && val.length) {
          this.refresh();
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    goCustomerDetail: function goCustomerDetail(id) {
      if (id === 'xx-visitor-xx') return;
      this.$router.push({
        name: 'customerDetail',
        query: {
          id: id
        }
      });
    },
    selectRow: function selectRow() {},
    getReadInfo: function getReadInfo() {
      var _this = this;

      api.READ_INFO({
        id: this.infoId
      }).then(function (res) {
        _this.tableData = res.list || [];
        _this.pagination = {
          total: res.total || 0
        };
      });
    },
    findPathByLeafId: function findPathByLeafId(leafId, nodes, valueName, path) {
      if (path === undefined) {
        path = [];
      }

      for (var i = 0; i < nodes.length; i++) {
        var tmpPath = path.concat();
        tmpPath.push(nodes[i][valueName]);

        if (leafId === nodes[i][valueName]) {
          return tmpPath;
        }

        if (nodes[i].children) {
          var findResult = this.findPathByLeafId(leafId, nodes[i].children, valueName, tmpPath);

          if (findResult) {
            return findResult;
          }
        }
      }
    },

    /* 弹窗确认 */
    dialogConfirm: function dialogConfirm() {
      var _this2 = this;

      this.$nextTick(function () {
        _this2.$refs.form.submitForm();
      });
    },
    dialogClose: function dialogClose(dialog) {
      dialog = false;
    },

    /* 弹窗表单确认 */
    dialogSubmit: function dialogSubmit() {
      var _this3 = this;

      this.dialogVisible = false;
      var classifyId = this.dialogFormData.classifyId;

      var params = _objectSpread(_objectSpread({}, this.dialogFormData), {}, {
        releaseTime: this.releaseTime,
        releaseType: this.releaseType,
        classifyId: classifyId && classifyId.length ? classifyId[classifyId.length - 1] : ''
      });

      api.ADD_VIDEO(params).then(function () {
        useSuccessTip();

        _this3.refresh();
      });
    },

    /** 接口参数过滤函数 */
    filtersFormatter: function filtersFormatter(params) {
      var startReleaseTime = params.startReleaseTime,
          endReleaseTime = params.endReleaseTime;
      delete params.releaseTime;
      return _objectSpread(_objectSpread({}, params), {}, {
        startReleaseTime: startReleaseTime ? "".concat(startReleaseTime, " 00:00:00") : null,
        endReleaseTime: endReleaseTime ? "".concat(endReleaseTime, " 23:59:59") : null,
        classifyId: params.classifyId && params.classifyId.length ? params.classifyId[params.classifyId.length - 1] : null
      });
    },

    /* 点击新增 */
    tableClick: function tableClick(params) {
      var _ref = params || {},
          clickItem = _ref.clickItem,
          selections = _ref.selections;

      var _ref2 = clickItem || {},
          type = _ref2.type;

      if (type === 'addInfo') {
        /* 新增资讯 */
        this.$router.push({
          name: 'infoAdd',
          query: {
            id: params.classifyId
          }
        });
      } else if (type === 'addVideo') {
        this.dialogFormData = {};
        this.releaseType = 1;
        this.releaseTime = '';
        this.dialogVisible = true;
      }
    },

    /** 表格数据改变 */
    tableRowChange: function tableRowChange(params) {
      var _ref3 = params || {},
          prop = _ref3.prop,
          row = _ref3.row;

      switch (prop) {
        case 'sort':
          this.Sort(row);
          break;
      }
    },

    /** 点击表格项 */
    tableRowClick: function tableRowClick(action) {
      var _ref4 = action || {},
          clickItem = _ref4.clickItem,
          row = _ref4.row,
          prop = _ref4.prop,
          type = _ref4.type;
      /* 标题跳转 */


      if (type === 'link') {
        if (prop === 'readViews') {
          if (row.readViews > 0) {
            this.showReadDialog = true;
            this.infoId = row.id;
          }
        } else {
          if (row.informationType !== 3) {
            this.$router.push({
              name: 'infoView',
              query: {
                type: 'edit',
                id: row.id
              }
            });
          } else {
            this.showVideoDialog = true;
            this.getDetail(row.id);
          }
        }
      } else if (type === 'switch') {
        /* 是否置顶 */
        if (prop === 'isTop') {
          var id = row.id,
              isTop = row.isTop;
          api.COMMON_REQUEST(apiUrl.setTop, {
            id: id,
            isTop: isTop
          }).then(function () {
            useSuccessTip();
          });
        }
      } else if (type === 'operation') {
        /* 操作 */
        switch (clickItem.type) {
          /* 操作编辑 */
          case 'edit':
            if (row.informationType !== 3) {
              this.Edit(row);
            } else {
              this.dialogVisible = true;
              this.getDetail(row.id);
            }

            break;

          /* 删除 */

          case 'delete':
            this.Delete([row.id], false);
            break;

          /* 启用 */

          case 'start':
            this.Update(row);
            break;

          /* 禁用 */

          case 'stop':
            this.Update(row);
            break;
        }
      }
    },

    /* 获取文章信息详情 */
    getDetail: function getDetail(id) {
      var _this4 = this;

      api.COMMON_REQUEST(apiUrl.getInfoById, {
        id: id
      }).then(function (res) {
        var classifyId = res.classifyId,
            releaseTime = res.releaseTime,
            releaseType = res.releaseType;
        _this4.releaseTime = releaseTime;
        _this4.releaseType = releaseType;
        res.classifyId = _this4.findPathByLeafId(classifyId, _this4.dialogFormConfig.classifyId.options, 'id');
        _this4.dialogFormData = res;
      }).catch(function () {});
    },
    // 排序数值
    Sort: function Sort(row) {
      var _this5 = this;

      console.log(row);
      var id = row.id,
          sort = row.sort;
      api.GET_DOCUMENT_SORT({
        id: id,
        sort: sort
      }).then(function () {
        _this5.$message({
          message: '操作成功!',
          type: 'success'
        });

        _this5.$refs.jPage.getPageData();
      }).catch(function (error) {
        console.log(error);
      });
    },

    /* 处理操作启用禁用 */
    Update: function Update(row) {
      var _this6 = this;

      var id = row.id,
          status = row.status;
      var statusText = status === 1 ? '禁用' : '启用';
      MessageBox.confirm("\u662F\u5426\u786E\u8BA4".concat(statusText, "\uFF1F"), '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        api.COMMON_REQUEST(apiUrl.updateStatus, {
          id: id,
          status: status === 0 ? 1 : 0
        }).then(function () {
          useSuccessTip();

          _this6.refresh();
        });
      });
    },

    /* 处理操作编辑 */
    Edit: function Edit(row) {
      console.log('row: ', row);
      this.$router.push({
        name: 'infoAdd',
        query: {
          type: 'edit',
          id: row.id
        }
      });
    },

    /* 处理操作删除 */
    Delete: function Delete(list, isMultiple) {
      var _this7 = this;

      var _url = '';
      var params = {};
      /* 批量删除 */

      if (isMultiple) {
        _url = apiUrl.deleteBatchByIds;
        params = {
          idList: list
        };
        /* 单个删除 */
      } else {
        _url = apiUrl.deleteById;
        params = {
          id: list[0]
        };
      }

      MessageBox.confirm('删除后不可恢复，是否确定删除所选资讯？', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        api.COMMON_REQUEST(_url, params).then(function () {
          useSuccessTip();

          _this7.refresh();
        });
      });
    },

    /* 获取所属分类下拉 */
    getChooseList: function getChooseList() {
      var _this8 = this;

      api.GET_CHOOSE_LIST().then(function (res) {
        _this8.topForm.classifyId.options = res;
        _this8.dialogFormConfig.classifyId.options = res;
        _this8.dialogFormDetail.classifyId.options = res;
        _this8.filtersData.classifyId = _this8.findPathByLeafId(_this8.$route.query.classifyId, _this8.topForm.classifyId.options, 'id');
      });
    },

    /* 获取视频下拉 */
    getAllVideo: function getAllVideo() {
      var _this9 = this;

      var params = {
        page: {
          pageNum: 1,
          pageSize: 999
        },
        status: 1
      };
      api.GET_VIDEO(params).then(function (res) {
        _this9.dialogFormConfig.materialId.options = (res.list || []).map(function (item) {
          return {
            label: item.name,
            value: item.id
          };
        });
        _this9.dialogFormDetail.materialId.options = _this9.dialogFormConfig.materialId.options;
      }).catch(function () {});
    },
    init: function init() {
      this.getChooseList();
      this.getAllVideo(); // this.getAllOrganization();
    },

    /** 刷新列表 */
    refresh: function refresh() {
      this.$refs.jPage.tableData_ = [];
      this.$refs.jPage.getPageData();
    }
  },
  created: function created() {
    /** 表格请求配置 */
    this.httpConfig = {
      url: "".concat(host).concat(apiPrev).concat(apiUrl.infoList),
      headers: getHeaders()
    };
    this.init();
  }
};