


































































































































































































































































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/interest/financeManager/components/dialog-audit.vue"
}
