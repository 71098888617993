import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var PUBLIC_CONFIGS = {
  labelWidth: 100
};
export var FORM_CONFIG = [_objectSpread(_objectSpread({}, PUBLIC_CONFIGS), {}, {
  type: 'slot',
  label: '客户',
  prop: 'customerId'
}), _objectSpread(_objectSpread({}, PUBLIC_CONFIGS), {}, {
  type: 'input',
  label: '机构',
  prop: 'orgName',
  props: {
    disabled: true
  }
}), _objectSpread(_objectSpread({}, PUBLIC_CONFIGS), {}, {
  type: 'slot',
  label: '礼品',
  prop: 'giftId'
}), _objectSpread(_objectSpread({}, PUBLIC_CONFIGS), {}, {
  type: 'input',
  label: '数量',
  prop: 'exchangeAmount',
  rules: [{
    required: true,
    message: '请输入数量',
    trigger: 'blur'
  }]
}), _objectSpread(_objectSpread({}, PUBLIC_CONFIGS), {}, {
  type: 'select',
  label: '取件方式',
  prop: 'pickingMethod',
  value: 2,
  options: [{
    label: '自取',
    value: 1
  }, {
    label: '直邮',
    value: 2
  }],
  rules: [{
    required: true,
    message: '请选择',
    trigger: 'change'
  }]
}), _objectSpread(_objectSpread({}, PUBLIC_CONFIGS), {}, {
  type: 'input',
  label: '收件人',
  prop: 'receiver',
  hidden: false,
  rules: [{
    required: true,
    message: '请输入收件人',
    trigger: 'blur'
  }]
}), _objectSpread(_objectSpread({}, PUBLIC_CONFIGS), {}, {
  type: 'input',
  label: '收件地址',
  prop: 'receiverAddress',
  hidden: false,
  rules: [{
    required: true,
    message: '请输入收件地址',
    trigger: 'blur'
  }]
}), _objectSpread(_objectSpread({}, PUBLIC_CONFIGS), {}, {
  type: 'input',
  label: '收件电话',
  prop: 'receiverMobile',
  hidden: false,
  rules: [{
    required: true,
    message: '请输入收件电话',
    trigger: 'blur'
  }]
}), _objectSpread(_objectSpread({}, PUBLIC_CONFIGS), {}, {
  type: 'input',
  label: '备注',
  prop: 'remark',
  props: {
    type: 'textarea',
    autosize: {
      minRows: 4,
      maxRows: 4
    },
    maxlength: 60,
    showWordLimit: true,
    placeholder: '请输入'
  }
})];