import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var PUBLIC_CONFIG = {
  labelWidth: 80
};
export var SELECT_FROM = {
  dept: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '部门',
    props: {
      placeholder: '请选择部门'
    }
  }),
  name: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '姓名',
    props: {
      placeholder: '请输入姓名'
    }
  })
};
export var ALLOT_TABLE_LABEL = [{
  label: '姓名',
  prop: 'name'
}, {
  label: '部门',
  prop: 'organizationName'
}, {
  label: '编号',
  prop: 'id'
}];
export var ALLOT_TABLE_CONFIG = {
  isSelect: true
};