import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import COLOR from '@/maps/enum/color';
import STATUS from '@/maps/enum/status/gift-apply';
import EXPRESS from '@/maps/enum/express';
import DATA_FORMAT_TYPE from '@/maps/enum/data-format-type';
import { FILTER_PROPS, FILTER_FORMS } from './option/filter';
import TABLE_LIST from './option/list';
import DynamicForm from '@/components/common/dynamic-form';
import DynamicTable from '@/components/common/dynamic-table';
import DialogInfo from '../dialog-info';
import { list, exportExcel } from '@api/crm/gift/apply';
import ElePermission from '@/store/modules/d2admin/modules/elePermission';
export default {
  name: 'gift-apply-search',
  components: {
    DynamicForm: DynamicForm,
    DynamicTable: DynamicTable,
    DialogInfo: DialogInfo
  },
  data: function data() {
    return {
      loading: false,
      filterProps: FILTER_PROPS,
      filterForms: FILTER_FORMS,
      headers: TABLE_LIST,
      table: {
        auto: false
      },
      pagination: {},
      query: {},
      tableData: [],
      showInfoDialog: false,
      currentRow: {}
    };
  },
  computed: {},
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
      this.$refs.jTable.pageChange(1);
    },
    //初始化搜索条件
    initFilter: function initFilter() {
      var query = this.$refs.form.getForm();
      this.query = _objectSpread(_objectSpread({}, this.query), query);
    },
    //点击刷新
    clickRefresh: function clickRefresh() {
      this.$refs.jTable.onRefresh();
      this.$message({
        message: '数据已刷新',
        type: 'info',
        duration: 500
      });
    },
    //点击重置
    clickReset: function clickReset() {
      this.$refs.form.reset();
      console.log(this.$refs.form.forms);
    },
    // TODO 搜索
    clickSearch: function clickSearch() {
      this.initFilter();
      this.$refs.jTable.pageChange(1);
    },
    // TODO 获取列表
    loadData: function loadData(_ref) {
      var _this = this;

      var _ref$page = _ref.page,
          page = _ref$page === void 0 ? {} : _ref$page;
      this.initFilter();
      var param = {
        page: page,
        query: this.query
      };
      this.loading = true;
      list(param, DATA_FORMAT_TYPE.USRE_SETUP).then(function (res) {
        _this.loading = false;
        _this.tableData = [];

        if (res.data != null) {
          var _res$data = res.data,
              data = _res$data === void 0 ? [] : _res$data,
              _page = res.page;
          var _page$totalCount = _page.totalCount,
              totalCount = _page$totalCount === void 0 ? 0 : _page$totalCount;
          _this.pagination = {
            total: totalCount
          };
          _this.tableData = data;
        } else {
          _this.$message({
            message: '暂无数据',
            type: 'info'
          });
        }
      }).catch(function (e) {
        _this.loading = false;

        _this.$message({
          message: '查询列表数据出错',
          type: 'error'
        });
      });
    },
    // TODO 选中行
    selectRow: function selectRow(row) {
      this.currentRow = row;
    },
    clickInfo: function clickInfo(row) {
      this.currentRow = row;
      this.showInfoDialog = true;
    },
    clickExport: function clickExport() {
      var _this2 = this;

      this.$confirm('确定按查询条件导出吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this2.initFilter();

        var param = {
          query: _this2.query
        };
        exportExcel(param).catch(function (e) {
          _this2.$message({
            message: e,
            type: 'error'
          });
        });
      }).catch(function () {});
    },
    //物流跟踪
    clickExpressCheck: function clickExpressCheck(row) {
      //顺丰
      if (row.express == EXPRESS.SF.value) {
        var url = EXPRESS.SF.searchUrl + row.expressNo;
        window.open(url);
      }
    },
    setStatusColor: function setStatusColor(status) {
      //合同状态颜色
      var className = '';

      switch (status) {
        case STATUS.SAVE.value:
          className = COLOR.BLUE;
          break;

        case STATUS.SUBMIT.value:
          className = COLOR.BLUE;
          break;

        case STATUS.SERVICE_AUDIT.value:
          className = COLOR.BLUE;
          break;

        case STATUS.DELIVER_GOODS.value:
          className = COLOR.BLUE;
          break;

        case STATUS.CONFIRM_RECEIPT.value:
          className = COLOR.BLUE;
          break;

        case STATUS.SUCCESS.value:
          className = COLOR.GREEN;
          break;

        case STATUS.SERVICE_AUDIT_FAILURE.value:
          className = COLOR.RED;
          break;

        case STATUS.FINANCE_AUDIT_FAILURE.value:
          className = COLOR.RED;
          break;
      }

      return className;
    }
  },
  created: function created() {},
  mounted: function mounted() {
    var array = this.$refs.form.forms;
    var permission = ElePermission.state.permission;

    if (permission && permission.length > 0) {
      // 判断是否拥有删除权限
      if (permission.indexOf('gift-applyDelete') > -1) {
        array.forEach(function (item) {
          if (item.valueKey === 'arrayStatus') {
            // 是否已添加过删除状态
            if (!item.options.includes(STATUS.DELETE)) {
              item.options.push(STATUS.DELETE);
            }
          }
        });
      }
    }
  }
};