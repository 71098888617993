//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import api from '@/api-new';
import Pagination from '@/components/Pagination';
export default {
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      tableKey: 0,
      list: null,
      total: 0,
      listLoading: true,
      customerId: null,
      listQuery: {
        page: {
          pageNum: 1,
          pageSize: 10
        },
        customerId: null,
        contractCd: null
      }
    };
  },
  methods: {
    getList: function getList() {
      var _this = this;

      api.GET_CUSTOMER_CONTRACT(this.listQuery).then(function (res) {
        _this.total = res.total;
        _this.list = res.list;
      });
    },
    handleFilter: function handleFilter() {
      this.listQuery.page = {
        pageNum: 1,
        pageSize: 10
      };
      this.listQuery.customerId = this.customerId;
      this.getList();
    },
    markVvipContract: function markVvipContract() {
      var tableRef = this.$refs.contractTable;
      console.log('tableRef', tableRef);
    }
  },
  created: function created() {
    var query = this.$route.query;
    this.customerId = query.id;
    this.listQuery.customerId = query.id;
    this.getList();
  }
};