import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import DynamicForm from "@/components/common/dynamic-form";
import { FILTER_PROPS, FILTER_FORMS } from "./option/filter"; // 过滤条件的form

import TreeOrganization from "@/components/business/tree-organization";
import { listOrgTree as orgTree } from "@api/rm/org";
import { list as personList } from "@api/rm/person";
import { listOrg as listOrgRight, saveOrg } from "@api/rm/right";
export default {
  name: "right-org",
  components: {
    DynamicForm: DynamicForm,
    TreeOrganization: TreeOrganization
  },
  data: function data() {
    return {
      loading: false,
      filename: __filename,
      filterProps: FILTER_PROPS,
      filterForms: FILTER_FORMS,
      personTableData: [],
      treeData: [],
      rightTableData: [],
      query: {
        enabled: "1",
        isSystemUser: "1"
      },
      currentOrg: {},
      //当前机构
      currentPerson: {} //当前用户

    };
  },
  created: function created() {
    this.loadOrg();
  },
  computed: {
    // 树的配置
    treeConfig: function treeConfig() {
      var orgCd = this.query.orgCd;
      return {
        currentNodeKey: orgCd
      };
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.personTableData = [];
      this.treeData = [];
      this.rightTableData = [];
      this.$refs.jTable.pageChange(1);
    },
    //初始化搜索条件
    initFilter: function initFilter() {
      var query = this.$refs.form.getForm();
      this.query = _objectSpread(_objectSpread({}, this.query), query);
    },
    //点击重置
    clickReset: function clickReset() {
      this.$refs.form.reset();
    },
    // TODO 搜索
    clickSearch: function clickSearch() {
      this.initFilter();
      this.query.orgCd = "01";
      this.loadPerson(this.query);
    },
    // TODO 点击树节点
    treeNodeClick: function treeNodeClick(node) {
      var orgCd = node.orgCd;
      this.query = _objectSpread(_objectSpread({}, this.query), {}, {
        orgCd: orgCd
      });
      this.currentOrg = node;
      this.loadPerson(this.query);
    },
    //职员列表
    loadPerson: function loadPerson(query) {
      var _this = this;

      this.initFilter();
      var param = {
        page: {
          pageSize: 99999
        },
        query: query
      };
      personList(param).then(function (res) {
        if (res.data != null) {
          _this.personTableData = res.data;
        } else {
          _this.personTableData = [];
          _this.rightTableData = [];

          _this.$refs.rightTree.setCheckedKeys(_this.rightTableData);
        }
      });
    },
    //加载机构
    loadOrg: function loadOrg() {
      var _this2 = this;

      //机构列表，分组成树形结构
      var param = {
        query: {
          enabled: null //查询全部

        }
      };
      orgTree(param).then(function (res) {
        _this2.treeData = res;
      });
    },
    //用户行点击，加载用户已分配机构
    clickPersonRow: function clickPersonRow(row) {
      var _this3 = this;

      this.currentPerson = row;
      var param = {
        personuuid: row.personuuid
      };
      listOrgRight(param).then(function (res) {
        _this3.rightTableData = [];
        res.data.forEach(function (n) {
          var result = {
            is: false
          };

          _this3.isLastNodeMenu(_this3.treeData, n.orgCd, result);

          if (result.is) {
            //是最底层菜单，标记选中
            _this3.rightTableData.push(n.orgCd);
          }
        });

        _this3.$refs.rightTree.setCheckedKeys(_this3.rightTableData);
      });
    },
    //保存
    clickSave: function clickSave() {
      var _this4 = this;

      var arrayChecked = this.$refs.rightTree.getCheckedKeys(); //已勾选产品

      var param = {
        personuuid: this.currentPerson.personuuid,
        arrayId: arrayChecked
      };
      saveOrg(param).then(function (res) {
        _this4.$message({
          message: "已保存",
          type: "success"
        });
      });
    },
    //判断是否是最下层菜单
    isLastNodeMenu: function isLastNodeMenu(arr, orgCd, result) {
      for (var i = 0; i < arr.length; i++) {
        var n = arr[i];

        if (n.orgCd == orgCd) {
          if (!n.hasOwnProperty("subList") || n.subList.length == 0) {
            result.is = true;
          }

          return;
        } else {
          this.isLastNodeMenu(n.subList, orgCd, result);
        }
      }
    }
  }
};