import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var PUBLIC_CONFIG = {
  labelWidth: 120,
  col: 15
};
export var FORM_BASE = [{
  title: '基本信息',
  children: [_objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '平台名称',
    prop: 'name',
    props: {
      type: 'text',
      placeholder: '请输入平台名称',
      maxlength: 32 // showWordLimit: true

    },
    rules: [{
      required: true,
      message: '平台名称不能为空',
      trigger: 'blur'
    }]
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'uploadImage',
    label: '平台LOGO',
    prop: 'logoUrl',
    props: {
      placeholder: '最多可传1张，图片大小不超过200KB，支GIF,JPEG,JPG,PNG,BMP格式',
      fileSize: 200
    },
    rules: [{
      required: true,
      message: '平台LOGO不能为空',
      trigger: 'blur'
    }]
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'checkbox',
    label: '业务入口',
    prop: 'module',
    options: [{
      label: '合同',
      value: 'contract'
    }, {
      label: '资讯',
      value: 'information'
    }, {
      label: '直播',
      value: 'live'
    }, {
      label: '视频中心',
      value: 'video'
    }]
  })]
}, {
  title: '客服设置',
  children: [_objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'radio',
    label: '在线客服类型',
    prop: 'customerServiceType',
    props: {},
    options: [{
      label: '不启用在线客服',
      value: 1
    }, {
      label: '企业微信客服',
      value: 2
    }],
    rules: [{
      required: true,
      message: '请选择',
      trigger: 'change'
    }]
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '企业id',
    prop: 'enterpriseId',
    props: {
      type: 'text',
      placeholder: '请输入'
    },
    rules: [{
      required: true,
      message: '请输入',
      trigger: 'blur'
    }]
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '客服链接',
    prop: 'customerServiceUrl',
    props: {
      type: 'text',
      placeholder: '请输入'
    },
    rules: [{
      required: true,
      message: '请输入',
      trigger: 'blur'
    }]
  })]
}, {
  title: '用户协议',
  children: [_objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'editor',
    prop: 'protocolContent',
    col: 24,
    props: {}
  })]
}];