import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//查询条件
var PUBLIC_CONFIG = {
  labelWidth: 120 // col: 12

};
var PUBLIC_CONFIGS = {
  labelWidth: 100
};
export var TOP_FORM = {
  participant: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '参与人',
    props: {
      placeholder: '请输入名称'
    }
  }),
  departmentId: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    label: '部门',
    type: 'cascader',
    options: [],
    props: {
      placeholder: '请选择部门',
      filterable: true,
      props: {
        checkStrictly: true,
        emitPath: false,
        value: 'orgCd',
        label: 'orgName',
        children: 'children'
      }
    }
  }),
  accountMobile: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '领取人手机号',
    props: {
      placeholder: '请输入手机号'
    }
  }),
  activityTime: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'datePicker',
    col: 6,
    label: '参与时间',
    propGroup: ['beginTime', 'endTime'],
    props: {
      type: 'daterange'
    }
  }),
  rewardContent: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '奖励内容',
    props: {
      placeholder: '请输入'
    }
  })
};
export var TABLE_LABEL = [{
  label: '参与人',
  prop: 'participant'
}, {
  label: '部门名称',
  prop: 'departmentName'
}, {
  label: '参与人手机号',
  prop: 'mobile'
}, {
  label: '参与时间',
  prop: 'createTime',
  sortable: 'marketingCampaignDetails',
  sortProp: 'createTime'
}, {
  label: '奖励内容',
  prop: 'rewardContent'
}];
export var TABLE_CONFIG = {
  isSelect: true,
  rowKey: 'id',
  actions: []
};