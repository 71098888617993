import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
//查询条件
var PUBLIC_CONFIG = {
  labelWidth: 80
};
var PUBLIC_CONFIGS = {
  labelWidth: 100
};
export var TOP_FORM = {
  // 组织选择
  orgCds: _objectSpread(_objectSpread({}, PUBLIC_CONFIGS), {}, {
    type: 'slot',
    label: '组织',
    props: {
      placeholder: '请选择组织'
    },
    options: [],
    rules: [{
      required: true,
      message: '组织不能为空',
      trigger: 'blur'
    }]
  }),
  contractCd: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '合同号',
    col: 6,
    props: {
      placeholder: '请输入合同号'
    }
  }),
  customerName: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '客户姓名',
    col: 6,
    props: {
      placeholder: '请输入客户姓名'
    }
  }),
  customerCode: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '客户编号',
    col: 6,
    props: {
      placeholder: '请输入客户编号'
    }
  }),
  agentName: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '理顾姓名',
    col: 6,
    props: {
      placeholder: '请输入理顾姓名'
    }
  }),
  contractState: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '合同状态',
    col: 6,
    props: {
      placeholder: '请选择合同状态'
    },
    options: [// {
      //   label: '已成交',
      //   value: '5',
      // },
    ]
  }),
  achievementDate: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'datePicker',
    label: '统计日期',
    col: 6,
    propGroup: ['achievementDateStart', 'achievementDateEnd'],
    props: {
      type: 'daterange'
    }
  }),
  contractStartDate: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'datePicker',
    label: '开始日期',
    col: 6,
    propGroup: ['contractStartDateStart', 'contractStartDateEnd'],
    props: {
      type: 'daterange'
    }
  }),
  contractEndDate: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'datePicker',
    label: '到期日期',
    col: 6,
    propGroup: ['contractEndDateStart', 'contractEndDateEnd'],
    props: {
      type: 'daterange'
    }
  })
};
export var TABLE_LABEL = [{
  label: '序号',
  prop: 'serialNumber',
  width: 50
}, {
  label: '区域',
  prop: 'orgTypeText'
}, {
  label: '渠道分类',
  prop: 'contractLevel3OrgName'
}, {
  label: '渠道明细',
  prop: 'contractLevel5OrgName'
}, {
  label: '顾问',
  prop: 'agentName'
}, {
  label: '客户姓名',
  prop: 'customerName'
}, {
  label: '客户编号',
  prop: 'customerCode'
}, {
  label: '是否新客户',
  prop: 'isFirstContract',
  formatter: function formatter(row, column, cellValue) {
    var res = '';

    switch (Number(cellValue)) {
      case 0:
        res = '否';
        break;

      case 1:
        res = '是';
        break;

      default:
        res = '';
    }

    return res;
  }
}, {
  label: '业绩归属日期',
  prop: 'achievementDate'
}, {
  label: '业绩',
  prop: 'achievement'
}, {
  label: '合同号',
  prop: 'contractCd'
}, {
  label: '产品',
  prop: 'productName'
}, {
  label: '合同状态',
  prop: 'contractStateText'
}, {
  label: '合同金额',
  prop: 'saleAmount'
}, {
  label: '年化入金',
  prop: 'yearAmount'
}, {
  label: '开始日期',
  prop: 'startDate'
}, {
  label: '结束日期',
  prop: 'endDate'
}, {
  label: '合同周期',
  prop: 'timeLimit'
}, {
  label: '机构',
  prop: 'orgName'
}, {
  label: '应收日期',
  prop: 'receivableDate'
}, {
  label: '是否统计业绩',
  prop: 'isPerformance',
  formatter: function formatter(row, column, cellValue) {
    var res = '';

    switch (Number(cellValue)) {
      case 0:
        res = '否';
        break;

      case 1:
        res = '是';
        break;

      default:
        res = '';
    }

    return res;
  }
}, {
  label: '提前终止日期',
  prop: 'clearDate'
}, {
  label: '是否续约',
  prop: 'isRenewContract',
  formatter: function formatter(row, column, cellValue) {
    var res = '';

    switch (Number(cellValue)) {
      case 0:
        res = '否';
        break;

      case 1:
        res = '是';
        break;

      default:
        res = '';
    }

    return res;
  }
}, {
  label: '续约金额',
  prop: 'renewSaleAmount'
}, {
  label: '续约原合同号',
  prop: 'originalContractCd'
}, {
  label: '原合同到期合同金额',
  prop: 'originalAmount'
}, {
  label: '原合同到期日期',
  prop: 'originalEndTime'
} // {
//   type: 'operation',
//   label: '操作',
//   fixed: 'right',
//   formatter: function (row) {
//     const arr = [];
//     arr.push(
//       {
//         type: 'compile',
//         label: '编辑',
//         permission: 'giftClassification-update',
//       },
//       {
//         type: 'start',
//         label: '启用',
//         permission: 'giftClassification-updateStatus',
//       },
//     );
//     return arr;
//   },
// },
];
export var TABLE_CONFIG = {
  // isSelect: true,
  rowKey: 'id',
  actions: [{
    label: '导出',
    type: 'export',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'financezrzachievement-export'
  }]
};