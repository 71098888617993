import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import echarts from "echarts";
import { FILTER_PROPS, FILTER_FORMS } from "./option/filter"; // 过滤条件的form

import DynamicForm from "@/components/common/dynamic-form";
import { formatDate, formatAmountToString } from "@/libs/crm/format";
import { sumYearAmountByMonth, sumYearAmountByOrg } from "@api/crm/report/report";
export default {
  name: "sum-yearAmount-month-org",
  components: {
    DynamicForm: DynamicForm
  },
  data: function data() {
    return {
      loading: false,
      filterProps: FILTER_PROPS,
      filterForms: FILTER_FORMS,
      sum: {
        all: 0
      }
    };
  },
  computed: {},
  mounted: function mounted() {
    //设置初始搜索日期，过去12个月
    var date = new Date();
    date.setMonth(date.getMonth() - 12);
    date.setDate(1);
    var beginDate = formatDate(date);
    this.$refs.form.$data.model.dateBegin = beginDate;
    this.loadData();
  },
  methods: {
    init: function init() {
      this.loading = false;
    },
    //初始化搜索条件
    initFilter: function initFilter() {
      var query = this.$refs.form.getForm();
      this.query = _objectSpread(_objectSpread({}, this.query), query);
    },
    //点击重置
    clickReset: function clickReset() {
      this.$refs.form.reset();
    },
    // TODO 搜索
    clickSearch: function clickSearch() {
      this.loadData();
    },
    // TODO 获取列表
    loadData: function loadData() {
      var _this = this;

      this.initFilter();
      var query = this.query;
      query.orgCd = "0150"; //直营分公司

      var param = {
        query: query
      };
      sumYearAmountByOrg(param).then(function (res) {
        if (res.data != null) {
          var echartsData = {
            x: [],
            //x轴数据
            y: [] //y轴数据

          };

          for (var i = 0; i < res.data.length; i++) {
            var m = res.data[i];
            echartsData.x.push(m.companyName);
            m.amount = Math.floor(m.amount / 10000 * 100) / 100; //转成以“万”为单位

            m.yearAmount = Math.floor(m.yearAmount / 10000 * 100) / 100; //转成以“万”为单位

            m.value = m.yearAmount; //自定义对象数据，必须有value项

            echartsData.y.push(m);
            _this.sum.all += m.yearAmount;
          }

          _this.sum.all = formatAmountToString(Math.floor(_this.sum.all * 100) / 100);

          _this.echartsDraw(echartsData); //绘制机构年化入金图


          _this.loadDataOrgMonth(echartsData.y[0]); //绘制月份入金图，第一个分公司

        } else {
          _this.$message({
            message: "暂无数据",
            type: "info"
          });
        }
      }).catch(function (e) {
        _this.$message({
          message: "查询数据出错",
          type: "error"
        });
      });
    },
    //绘制机构年化入金图
    echartsDraw: function echartsDraw(data) {
      var _this2 = this;

      //绘图
      var echartDom = document.getElementById("echartYearAmountOrgDom");
      var myChart = echarts.init(echartDom);
      var option = {
        title: {
          text: "直营分公司年化入金(万元)",
          left: "left",
          subtext: "",
          sublink: ""
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow" // 默认为直线，可选为：'line' | 'shadow'

          }
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: {
          type: "category",
          data: data.x
        },
        yAxis: {
          type: "value"
        },
        series: [{
          data: data.y,
          //自定义json数据，必须有一项value
          type: "bar"
        }]
      };
      option && myChart.setOption(option); //点击事件

      myChart.on("click", function (param) {
        _this2.loadDataOrgMonth(param.data);
      });
    },
    //查询指定分公司月份数据
    loadDataOrgMonth: function loadDataOrgMonth(obj) {
      var _this3 = this;

      var query = this.$refs.form.getForm();
      query.orgCd = obj.companyCd; //分公司

      var param = {
        query: query
      };
      sumYearAmountByMonth(param).then(function (res) {
        var echartsData = {
          orgName: obj.companyName,
          x: [],
          //x轴数据
          y: [] //y轴数据

        };

        for (var i = 0; i < res.data.length; i++) {
          var m = res.data[i];
          echartsData.x.push(m.month);
          var yearAmountW = Math.floor(m.yearAmount / 10000 * 100) / 100; //转成以“万”为单位

          echartsData.y.push(yearAmountW);
        }

        _this3.echartsDrawMonth(echartsData); //绘制机构月份年化入金图

      }).catch(function (e) {
        _this3.$message({
          message: "查询数据出错",
          type: "error"
        });
      });
    },
    //绘制月份图
    echartsDrawMonth: function echartsDrawMonth(data) {
      //绘图
      var echartDom = document.getElementById("echartYearAmountMonthDom");
      var myChart = echarts.init(echartDom);
      var option = {
        title: {
          text: data.orgName + "月份年化入金(万元)",
          left: "left",
          subtext: "",
          sublink: ""
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow" // 默认为直线，可选为：'line' | 'shadow'

          }
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: {
          type: "category",
          data: data.x
        },
        yAxis: {
          type: "value"
        },
        series: [{
          data: data.y,
          type: "bar"
        }]
      };
      option && myChart.setOption(option);
    }
  },
  created: function created() {}
};