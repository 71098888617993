import _typeof from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/typeof.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from '@/mixins/dialog';
export default {
  name: 'dialog-department',
  props: {
    deptList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    defaultCheckedKeys: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      props: {
        label: 'orgName',
        value: 'orgCd',
        children: 'children'
      },
      isContain: true,
      checkedDept: [],
      selectPermission: '',
      loading: false,
      model: {},
      // 表单值
      formModel: {} //表单

    };
  },
  computed: {
    defaultExpandKeys: function defaultExpandKeys() {
      return this.deptList && this.deptList.length ? [this.deptList[0].orgCd] : [];
    }
  },
  created: function created() {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
        }
      },
      immediate: true
    },
    isContain: function isContain() {
      this.$refs.deptTree.setCheckedKeys([]);
    }
  },
  methods: {
    init: function init() {
      this.checkedDept = this.defaultCheckedKeys || [];
    },
    checkNodes: function checkNodes(data, _ref) {
      var checkedNodes = _ref.checkedNodes;
      this.checkedDept = checkedNodes;
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.showDialog = false;
    },
    // TODO 确定
    confirm: function confirm() {
      if (!this.checkedDept || !this.checkedDept.length) {
        this.$message.warning('请选择公司或部门！');
        return;
      } else if (_typeof(this.checkedDept[0]) !== 'object') {
        this.$message.warning('无操作，请关闭弹窗');
        return;
      }

      this.$emit('callback', this.checkedDept, this.isContain);
      this.dialogClose();
    }
  }
};