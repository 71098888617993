import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
// 查询条件
var PUBLIC_CONFIG = {
  labelWidth: 80
}; // 表格列宽

var PUBLIC_TABLE_LABEL_CONFIG = {
  width: 150
};
export var TOP_FORM = {
  priority: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '紧急程度',
    col: 6,
    props: {
      placeholder: '请选择'
    },
    options: [{
      label: '逾期未办',
      value: 1
    }, {
      label: '高紧急度',
      value: 2
    }, {
      label: '低紧急度',
      value: 3
    }]
  }),
  type: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '待办类型',
    col: 6,
    props: {
      placeholder: '请选择'
    },
    options: [{
      label: '合同',
      value: 1
    }, {
      label: '生日',
      value: 2
    }, {
      label: '纪念日',
      value: 3
    }]
  }),
  todoStatus: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '待办状态',
    col: 6,
    props: {
      placeholder: '请选择'
    },
    options: [{
      label: '未处理',
      value: '0'
    }, {
      label: '已处理',
      value: '1'
    }]
  }),
  dueDateType: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '截止日期',
    col: 6,
    props: {
      placeholder: '请选择'
    },
    options: [{
      label: '全部',
      value: ''
    }, {
      label: '本年',
      value: '1'
    }, {
      label: '本季度',
      value: '2'
    }, {
      label: '本月',
      value: '3'
    }, {
      label: '本周',
      value: '4'
    }, {
      label: '本日',
      value: '5'
    }]
  }),
  createDateType: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '创建日期',
    col: 6,
    props: {
      placeholder: '请选择'
    },
    options: [{
      label: '全部',
      value: ''
    }, {
      label: '本年',
      value: '1'
    }, {
      label: '本季度',
      value: '2'
    }, {
      label: '本月',
      value: '3'
    }, {
      label: '本周',
      value: '4'
    }, {
      label: '本日',
      value: '5'
    }]
  }),
  validStatus: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '有效状态',
    col: 6,
    props: {
      placeholder: '请选择'
    },
    options: [{
      label: '有效',
      value: '0'
    }, {
      label: '作废',
      value: '1'
    }]
  }),
  customerName: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '客户',
    props: {
      placeholder: '请输入客户名称'
    }
  })
};
export var TABLE_LABEL = [{
  label: '流水号',
  prop: 'code',
  width: 200
}, _objectSpread(_objectSpread({}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  label: '待办类型',
  prop: 'typeValue'
}), _objectSpread(_objectSpread({}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  label: '客户名称',
  prop: 'customerName'
}), _objectSpread(_objectSpread({}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  label: '客户等级',
  prop: 'levelValue'
}), {
  label: '待办内容',
  prop: 'content',
  width: 300
}, _objectSpread(_objectSpread({}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  label: '待办状态',
  prop: 'todoStatusValue'
}), _objectSpread(_objectSpread({}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  label: '紧急程度',
  prop: 'priorityValue'
}), {
  label: '创建时间',
  prop: 'createTime',
  width: 200
}, {
  label: '截止日期',
  prop: 'dueDate',
  width: 200
}, _objectSpread(_objectSpread({}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  label: '处理结果',
  prop: 'processStatusValue'
}), {
  label: '原因',
  prop: 'reason',
  width: 200
}, {
  label: '客户流水号',
  prop: 'customerCode',
  width: 200
}, {
  label: '合同编码',
  prop: 'contractCode',
  width: 200
}, _objectSpread(_objectSpread({}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  label: '合同到期金额（元）',
  prop: 'contractAmount'
}), _objectSpread(_objectSpread({}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  label: '客户年限',
  prop: 'customerYearLimit'
}), _objectSpread(_objectSpread({}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  type: 'slot',
  label: '续约明细',
  prop: 'renewalDetails'
}), _objectSpread(_objectSpread({}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  label: '理顾',
  prop: 'agentUserName'
}), {
  label: '部门名称',
  prop: 'orgName',
  width: 250
}, _objectSpread(_objectSpread({}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  label: '有效状态',
  prop: 'validStatusValue'
}), {
  type: 'operation',
  label: '操作',
  fixed: 'right',
  width: 100,
  formatter: function formatter(row) {
    var arr = [];

    if (row.validStatus !== 1) {
      arr.push({
        type: 'cancel',
        label: '作废',
        permission: 'Todo-cancel'
      });
    }

    return arr;
  }
}];
export var TABLE_CONFIG = {
  isSelect: true,
  actions: [{
    label: '导出',
    type: 'export',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'Todo-export'
  }]
};