var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "j-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.loading,
          expression: "loading",
          modifiers: { fullscreen: true, lock: true }
        }
      ],
      attrs: {
        visible: _vm.showDialog,
        title: _vm.title,
        width:
          "" + (_vm.formModel.typeMoney === "分金额段" ? "1500px" : "1365px"),
        footActive: !_vm.isExamine
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        confirm: _vm.clickSubmit,
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "div",
        { staticClass: "box" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.formModel,
                rules: _vm.rules,
                "label-width": "100px",
                size: "mini",
                "label-position": "left"
              }
            },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      { staticStyle: { "margin-right": "10px" } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "比例编码", prop: "code" } },
                          [
                            _c("el-input", {
                              attrs: { disabled: _vm.isExamine, title: "" },
                              model: {
                                value: _vm.formModel.code,
                                callback: function($$v) {
                                  _vm.$set(_vm.formModel, "code", $$v)
                                },
                                expression: "formModel.code"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "比例名称", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: _vm.isExamine, title: "" },
                            model: {
                              value: _vm.formModel.name,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "name", $$v)
                              },
                              expression: "formModel.name"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      { staticStyle: { "margin-left": "10px" } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "是否启用", prop: "enabled" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  disabled: _vm.isExamine,
                                  clearable: "",
                                  placeholder: "请选择"
                                },
                                model: {
                                  value: _vm.formModel.enabled,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "enabled", $$v)
                                  },
                                  expression: "formModel.enabled"
                                }
                              },
                              _vm._l(_vm.selectData.enabled, function(item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "计算公式", prop: "formulaId" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                disabled: _vm.isExamine,
                                clearable: "",
                                filterable: "",
                                placeholder: "请选择"
                              },
                              model: {
                                value: _vm.formModel.formulaId,
                                callback: function($$v) {
                                  _vm.$set(_vm.formModel, "formulaId", $$v)
                                },
                                expression: "formModel.formulaId"
                              }
                            },
                            _vm._l(_vm.selectData.formula, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.text, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 18 } }, [
                    _c(
                      "div",
                      { staticStyle: { "margin-left": "10px" } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "计算公式描述" } },
                          [_c("div", [_vm._v(_vm._s(_vm.formModel.formula))])]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "选择期限", prop: "checkList" } },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          attrs: { disabled: _vm.isExamine },
                          on: { change: _vm.handleTableData },
                          model: {
                            value: _vm.formModel.checkList,
                            callback: function($$v) {
                              _vm.$set(_vm.formModel, "checkList", $$v)
                            },
                            expression: "formModel.checkList"
                          }
                        },
                        _vm._l(_vm.cityOptions, function(city) {
                          return _c(
                            "el-checkbox",
                            { key: city, attrs: { label: city } },
                            [_vm._v(_vm._s(city))]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "类型",
                            "label-width": "100px",
                            prop: "monthType"
                          }
                        },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              attrs: { disabled: _vm.isExamine },
                              on: { change: _vm.handleTableData },
                              model: {
                                value: _vm.formModel.monthType,
                                callback: function($$v) {
                                  _vm.$set(_vm.formModel, "monthType", $$v)
                                },
                                expression: "formModel.monthType"
                              }
                            },
                            [
                              _c("el-checkbox", { attrs: { label: "0" } }, [
                                _vm._v("常规")
                              ]),
                              _c("el-checkbox", { attrs: { label: "1" } }, [
                                _vm._v("直接续约")
                              ]),
                              _c("el-checkbox", { attrs: { label: "5" } }, [
                                _vm._v("出金后当天续约")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "顾问岗位比例分配方式",
                            "label-width": "180px",
                            prop: "typeMoney"
                          }
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { disabled: _vm.isExamine },
                              on: { change: _vm.typeMoneyChanged },
                              model: {
                                value: _vm.formModel.typeMoney,
                                callback: function($$v) {
                                  _vm.$set(_vm.formModel, "typeMoney", $$v)
                                },
                                expression: "formModel.typeMoney"
                              }
                            },
                            [
                              _c("el-radio", {
                                attrs: { label: "不分金额段" }
                              }),
                              _c("el-radio", { attrs: { label: "分金额段" } })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.formModel.typeMoney === "分金额段"
                    ? _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "金额段区间",
                                "label-width": "180px",
                                prop: "typeNum"
                              }
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { disabled: _vm.isExamine },
                                  on: { change: _vm.handleTableData },
                                  model: {
                                    value: _vm.formModel.typeNum,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formModel, "typeNum", $$v)
                                    },
                                    expression: "formModel.typeNum"
                                  }
                                },
                                [
                                  _c("el-radio", {
                                    attrs: { label: "30<=Q<50" }
                                  }),
                                  _c("el-radio", {
                                    attrs: { label: "50<=Q<100" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "form-item" }, [_vm._v("岗位、次数及比例")]),
          _c(
            "el-table",
            {
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: "",
                height: "400px",
                "row-style": { height: "20px" },
                "cell-style": { padding: "6px 0" },
                "row-key": function(row, index) {
                  return index
                }
              }
            },
            [
              _c("el-table-column", {
                attrs: { prop: "deadline", label: "期限（月）", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-input", {
                          attrs: { disabled: _vm.isExamine },
                          model: {
                            value: scope.row.deadline,
                            callback: function($$v) {
                              _vm.$set(scope.row, "deadline", $$v)
                            },
                            expression: "scope.row.deadline"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "type", label: "类型", width: "170" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [_vm._v(" " + _vm._s(_vm.typeText(scope)) + " ")]
                    }
                  }
                ])
              }),
              _vm.formModel.typeMoney === "分金额段"
                ? _c(
                    "el-table-column",
                    {
                      key: "i1",
                      attrs: {
                        label: "顾问/后勤人员（万元）",
                        "header-align": "center",
                        width: "340"
                      }
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "i0",
                          label: _vm.tableDatahead.oneName,
                          width: "110"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("el-input", {
                                    attrs: { disabled: _vm.isExamine },
                                    model: {
                                      value: scope.row.i0,
                                      callback: function($$v) {
                                        _vm.$set(scope.row, "i0", $$v)
                                      },
                                      expression: "scope.row.i0"
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1064888067
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "i0Tow",
                          label: _vm.tableDatahead.twoName,
                          width: "130"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("el-input", {
                                    attrs: { disabled: _vm.isExamine },
                                    model: {
                                      value: scope.row.i0Tow,
                                      callback: function($$v) {
                                        _vm.$set(scope.row, "i0Tow", $$v)
                                      },
                                      expression: "scope.row.i0Tow"
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3993382575
                        )
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "i0Three",
                          label: _vm.tableDatahead.threeName,
                          width: "100"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(scope) {
                                return [
                                  _c("el-input", {
                                    attrs: { disabled: _vm.isExamine },
                                    model: {
                                      value: scope.row.i0Three,
                                      callback: function($$v) {
                                        _vm.$set(scope.row, "i0Three", $$v)
                                      },
                                      expression: "scope.row.i0Three"
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2963049965
                        )
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.formModel.typeMoney === "不分金额段"
                ? _c("el-table-column", {
                    key: "i0",
                    attrs: { prop: "i0", label: "顾问", width: "110" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c("el-input", {
                                attrs: { disabled: _vm.isExamine },
                                model: {
                                  value: scope.row.i0,
                                  callback: function($$v) {
                                    _vm.$set(scope.row, "i0", $$v)
                                  },
                                  expression: "scope.row.i0"
                                }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1064888067
                    )
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: { prop: "i1", label: "团队总监", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-input", {
                          attrs: { disabled: _vm.isExamine },
                          model: {
                            value: scope.row.i1,
                            callback: function($$v) {
                              _vm.$set(scope.row, "i1", $$v)
                            },
                            expression: "scope.row.i1"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "i4", label: " 营业部副总", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-input", {
                          attrs: { disabled: _vm.isExamine },
                          model: {
                            value: scope.row.i4,
                            callback: function($$v) {
                              _vm.$set(scope.row, "i4", $$v)
                            },
                            expression: "scope.row.i4"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "i2", label: "营业部总经理", width: "110" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-input", {
                          attrs: { disabled: _vm.isExamine },
                          model: {
                            value: scope.row.i2,
                            callback: function($$v) {
                              _vm.$set(scope.row, "i2", $$v)
                            },
                            expression: "scope.row.i2"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "i3", label: "区域总经理", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-input", {
                          attrs: { disabled: _vm.isExamine },
                          model: {
                            value: scope.row.i3,
                            callback: function($$v) {
                              _vm.$set(scope.row, "i3", $$v)
                            },
                            expression: "scope.row.i3"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "i5", label: "后勤人员管理层", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c("el-input", {
                          attrs: { disabled: _vm.isExamine },
                          model: {
                            value: scope.row.i5,
                            callback: function($$v) {
                              _vm.$set(scope.row, "i5", $$v)
                            },
                            expression: "scope.row.i5"
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c(
                "el-table-column",
                {
                  attrs: {
                    label: "推介费发放次数及比例",
                    "header-align": "center",
                    width: "500"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "oneYear", label: "第一年", width: "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-input", {
                              attrs: { disabled: _vm.isExamine },
                              model: {
                                value: scope.row.oneYear,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "oneYear", $$v)
                                },
                                expression: "scope.row.oneYear"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "towYear", label: "第二年", width: "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-input", {
                              attrs: { disabled: _vm.isExamine },
                              model: {
                                value: scope.row.towYear,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "towYear", $$v)
                                },
                                expression: "scope.row.towYear"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "threeYear", label: "第三年", width: "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-input", {
                              attrs: { disabled: _vm.isExamine },
                              model: {
                                value: scope.row.threeYear,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "threeYear", $$v)
                                },
                                expression: "scope.row.threeYear"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "fourYear", label: "第四年", width: "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-input", {
                              attrs: { disabled: _vm.isExamine },
                              model: {
                                value: scope.row.fourYear,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "fourYear", $$v)
                                },
                                expression: "scope.row.fourYear"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "fiveYear", label: "第五年", width: "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-input", {
                              attrs: { disabled: _vm.isExamine },
                              model: {
                                value: scope.row.fiveYear,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "fiveYear", $$v)
                                },
                                expression: "scope.row.fiveYear"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "sixYear", label: "第六年", width: "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-input", {
                              attrs: { disabled: _vm.isExamine },
                              model: {
                                value: scope.row.sixYear,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "sixYear", $$v)
                                },
                                expression: "scope.row.sixYear"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }