


































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/count/newSameDate/components/dialog-add.vue"
}
