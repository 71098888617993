import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from '@/mixins/dialog';
import { orgHeadAdd, orgHeadEdit, orgHeadGetInfo } from '@api/rm/org';
import searchUserDialog from '@/components/business/search-user/searchUserDialog';
export default {
  name: 'OrgHeadAdd',
  props: {
    /** 选中部门 */
    org: {
      type: Object,
      default: function _default() {
        return {};
      }
    },

    /** 选中负责人 */
    orgHead: {
      type: Object,
      default: function _default() {
        return {};
      }
    },

    /** 是否为编辑 */
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  components: {
    searchUserDialog: searchUserDialog
  },
  mixins: [dialogMixin],
  data: function data() {
    var _this = this;

    return {
      model: {},
      // 表单值
      formModel: {},
      rules: {
        headName: {
          validator: function validator(rule, value, callback) {
            // 不能用自带的required校验 因为内部校验规则监听不到值
            if (!_this.formModel.headName || !_this.formModel.headCd) {
              callback(new Error('请填写负责人'));
            } else {
              callback();
            }
          },
          trigger: 'blur'
        },
        beginTime: [{
          required: true,
          message: '请填写负责人考核开始日期',
          trigger: 'date'
        }],
        endTime: [{
          required: true,
          message: '请填写负责人考核结束日期',
          trigger: 'date'
        }]
      },
      loading: false,
      selectData: {
        person: []
      },
      searchUserVisible: false
    };
  },
  created: function created() {// 机构类型
  },
  computed: {
    // 弹窗展示的标题
    title: function title() {
      return this.isEdit ? '修改' : '新增';
    }
  },
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();

          if (this.isEdit) {
            this.getInfo();
          } else {
            this.formModel = {
              recId: null,
              // 记录id
              recStatus: 1,
              // 记录状态
              isCurHead: 1,
              orgCd: this.org.orgCd,
              orgName: this.org.orgName,
              joinSuperActualDate: this.org.joinSuperActualDate,
              // 加入上级部门日期_实际
              leaveSuperActualDate: this.org.leaveSuperActualDate,
              // 离开上级部门日期_实际
              beginTime: this.org.joinSuperActualDate,
              // 负责人考核开始日期 生效日期
              endTime: this.org.leaveSuperActualDate // 负责人考核结束日期 失效日期

            };
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
    },
    // 获取详情
    getInfo: function getInfo() {
      var _this2 = this;

      var param = {
        recId: this.orgHead.recId
      };
      orgHeadGetInfo(param).then(function (res) {
        var _res$data = res.data,
            data = _res$data === void 0 ? {} : _res$data;
        _this2.formModel = data;
      });
    },
    //  确定
    clickSave: function clickSave(done) {
      var _this3 = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          var method = _this3.isEdit ? orgHeadEdit : orgHeadAdd;
          _this3.loading = true;
          var param = {
            object: _objectSpread({}, _this3.formModel)
          };
          method(param).then(function (res) {
            _this3.showDialog = false;

            _this3.$emit('confirm');
          }).catch(function (e) {
            _this3.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    searchUserShow: function searchUserShow() {
      this.searchUserVisible = !this.searchUserVisible;
    },

    /** 职员确定 */
    searchUserDialogConfirm: function searchUserDialogConfirm(selectItem) {
      if (selectItem !== null && selectItem !== void 0 && selectItem.personuuid) {
        this.formModel.headCd = selectItem === null || selectItem === void 0 ? void 0 : selectItem.personuuid;
        this.formModel.headName = selectItem === null || selectItem === void 0 ? void 0 : selectItem.name;
      } else {
        this.formModel.headCd = null;
        this.formModel.headName = null;
      }
    }
  }
};