






























































































































































































































































































































































































































































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/commissionV3/service/components/dialog-establish.vue"
}
