import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//查询条件
var PUBLIC_CONFIG = {
  labelWidth: 80
};
var PUBLIC_CONFIGS = {
  labelWidth: 100
};
export var TOP_FORM = {
  // // 顶部page切换
  // isReverse: {
  //   type: 'tabs',
  //   options: [
  //     {
  //       label: '到期出金',
  //       badge: 0,
  //       value: 1,
  //       badgeHidden: true,
  //     },
  //     {
  //       label: '付息出金',
  //       badge: 0,
  //       value: 2,
  //       badgeHidden: true,
  //     },
  //   ],
  // },
  // 组织选择
  orgCds: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'slot',
    label: '组织',
    props: {
      placeholder: '请选择组织'
    },
    options: [],
    rules: [{
      required: true,
      message: '组织不能为空',
      trigger: 'blur'
    }]
  }),
  orgName: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '所属机构',
    col: 6,
    props: {
      placeholder: '请输入机构名称'
    },
    hidden: false
  }),
  applyType: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '出金类型',
    // 字典ID 0228
    col: 6,
    props: {
      placeholder: '请选择出金类型'
    },
    options: [// {
      //   label: '已成交',
      //   value: '5',
      // },
    ],
    hidden: false
  }),
  countType: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '结算类型',
    // 字典ID 0230
    col: 6,
    props: {
      placeholder: '请选择结算类型'
    },
    options: [],
    hidden: false
  }),
  // interestType: {
  //   ...PUBLIC_CONFIG,
  //   type: 'select',
  //   label: '付息类型', 
  //   col: 6,
  //   props: {
  //     placeholder: '请选择付息类型',
  //   },
  //   options: [
  //     {
  //       label: '末次付息',
  //       value: '1',
  //     },
  //     {
  //       label: '分期付息',
  //       value: '2',
  //     },
  //   ],
  //   hidden: true,
  // },
  achievementDate: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'datePicker',
    label: '统计日期',
    col: 6,
    propGroup: ['achievementDateStart', 'achievementDateEnd'],
    props: {
      type: 'daterange'
    },
    hidden: false
  }),
  contractStartDate: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'datePicker',
    label: '开始日期',
    col: 6,
    propGroup: ['contractStartDateStart', 'contractStartDateEnd'],
    props: {
      type: 'daterange'
    },
    hidden: false
  }),
  contractEndDate: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'datePicker',
    label: '到期日期',
    col: 6,
    propGroup: ['contractEndDateStart', 'contractEndDateEnd'],
    props: {
      type: 'daterange'
    },
    hidden: false
  })
}; // 到期出金

export var TABLE_LABEL = [{
  label: '序号',
  prop: 'serialNumber',
  width: 50
}, {
  label: '月份',
  prop: 'monthStr'
}, {
  label: '区域',
  prop: 'orgTypeText'
}, {
  label: '区域分类',
  prop: 'orgLevelName3'
}, {
  label: '合同号',
  prop: 'contractCd'
}, {
  label: '客户编号',
  prop: 'customerCode'
}, {
  label: '客户姓名',
  prop: 'customerName'
}, {
  label: '出金类型',
  prop: 'applyTypeText'
}, {
  label: '结算类型',
  prop: 'countTypeText'
}, {
  label: '合同开始日',
  prop: 'startDate'
}, {
  label: '合同到期日',
  prop: 'endDate'
}, {
  label: '应付日期',
  prop: 'payableDate'
}, {
  label: '提前终止日/提前赎回日',
  prop: 'nEndDate'
}, {
  label: '到期合同金额',
  prop: 'amount'
}, {
  label: '到期年化金额',
  prop: 'yearAmount'
}, {
  label: '续约合同金额',
  prop: 'goOnAmount'
}, {
  label: '所属机构',
  prop: 'orgName'
}]; // 付息出金

export var TOP_FORM2 = {
  // 组织选择
  orgCds: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'slot',
    label: '组织',
    props: {
      placeholder: '请选择组织'
    },
    options: [],
    rules: [{
      required: true,
      message: '组织不能为空',
      trigger: 'blur'
    }]
  }),
  orgName: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '所属机构',
    col: 6,
    props: {
      placeholder: '请输入机构名称'
    },
    hidden: false
  }),
  interestType: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '付息类型',
    col: 6,
    props: {
      placeholder: '请选择付息类型'
    },
    options: [{
      label: '末次付息',
      value: '1'
    }, {
      label: '分期付息',
      value: '2'
    }],
    hidden: false
  }),
  achievementDate: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'datePicker',
    label: '统计日期',
    col: 6,
    propGroup: ['achievementDateStart', 'achievementDateEnd'],
    props: {
      type: 'daterange'
    },
    hidden: false
  }),
  contractStartDate: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'datePicker',
    label: '开始日期',
    col: 6,
    propGroup: ['contractStartDateStart', 'contractStartDateEnd'],
    props: {
      type: 'daterange'
    },
    hidden: false
  }),
  contractEndDate: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'datePicker',
    label: '到期日期',
    col: 6,
    propGroup: ['contractEndDateStart', 'contractEndDateEnd'],
    props: {
      type: 'daterange'
    },
    hidden: false
  })
};
export var TABLE_LABEL2 = [{
  label: '序号',
  prop: 'serialNumber',
  width: 50
}, {
  label: '月份',
  prop: 'monthStr'
}, {
  label: '区域',
  prop: 'orgTypeText'
}, {
  label: '区域分类',
  prop: 'orgLevelName3'
}, {
  label: '合同号',
  prop: 'contractCd'
}, {
  label: '客户编号',
  prop: 'customerCode'
}, {
  label: '付息类型',
  prop: 'interestTypeText'
}, {
  label: '合同开始日',
  prop: 'startDate'
}, {
  label: '合同到期日',
  prop: 'endDate'
}, {
  label: '应付日期',
  prop: 'paymentDate'
}, {
  label: '付息金额',
  prop: 'interest'
}, {
  label: '合同金额',
  prop: 'amount'
}, {
  label: '年化金额',
  prop: 'yearAmount'
}, {
  label: '所属机构',
  prop: 'orgName'
}];
export var TABLE_CONFIG = {
  // isSelect: true,
  rowKey: 'id',
  actions: [{
    label: '导出',
    type: 'export',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'financecashflow-export'
  }]
};