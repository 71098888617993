import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.number.to-fixed.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import crmMixin from "@/mixins/crm";
import ENUM from "@/maps/enum/enum";
import LOG_TYPE from "@/maps/enum/contract-log-type";
import ATTACHMENT_TYPE from "@/maps/enum/attachment-type";
import PRODUCT_CATEGORY1 from '@/maps/enum/product-category1';
import PRODUCT_CATEGORY2 from '@/maps/enum/product-category2';
import PRODUCT_COUNT_TYPE from '@/maps/enum/product-count-type';
import DATA_FORMAT_TYPE from "@/maps/enum/data-format-type";
import Attachment from "@/components/business/attachment";
import ContractLog from "@/components/business/contract-log";
import ContractGoldWeight from "@/components/business/contract-gold-weight";
import { setExtendAttribute } from "@/libs/crm/crm";
import { formatObjectAmountToNumber, formatAmountToNumber } from "@/libs/crm/format";
import { getEnumList } from "@api/common/common";
import { listEnum } from "@api/crm/setup/product";
import { getInfo as _getInfo, save, submit } from "@api/crm/count/count";
export default {
  name: "count-add",
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {
    Attachment: Attachment,
    ContractLog: ContractLog,
    ContractGoldWeight: ContractGoldWeight
  },
  mixins: [dialogMixin, crmMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      tabActive: "tabContract",
      //标签框默认选中
      collapseActive: ["1"],
      //信息折叠默认展开
      attachmentType: ATTACHMENT_TYPE.CONTRACT_STAFF,
      //附件类型
      attachmentTypeReceptionBill: ATTACHMENT_TYPE.RECEPTION_BILL,
      //附件类型：回单凭证
      attachmentTypeCount: ATTACHMENT_TYPE.APPLY_FGSKF_SUBMIT_STAFF,
      //附件类型：核算申请
      showLogDialog: false,
      contractLogType: LOG_TYPE.COUNT,
      attachmentOpened: "",
      //附件打开状态
      formModel: {},
      //表单
      tempFormModel: {},
      // 暂存表单值
      selectData: {
        countType: [],
        //结算类型
        applyType: [],
        //赎回方式
        goOnProductId: [],
        //续约产品
        feeDeductType: [],
        //费用扣减
        customerVisitClaim: [{
          value: "0",
          text: "否"
        }, {
          value: "1",
          text: "是"
        }] //客户是否接受回访

      },
      rules: {
        countDate: [{
          required: true,
          message: "请填写申请日期",
          trigger: "input"
        }],
        countType: [{
          required: true,
          message: "请选择结算类型",
          trigger: "input"
        }],
        countAmountAll: [{
          required: true,
          message: "请填写权益合计",
          trigger: "input"
        }],
        serviceFee: [{
          required: true,
          message: "请填写手续费",
          trigger: "input"
        }],
        applyBackCash: [{
          required: true,
          message: "请填写提取金额",
          trigger: "input"
        }],
        customerVisitClaim: [{
          required: true,
          message: "请选择客户是否接受回访",
          trigger: "input"
        }],
        applyType: [{
          required: true,
          message: "请选择结算方式",
          trigger: "input"
        }]
      }
    };
  },
  created: function created() {
    var _this = this;

    //结算类型
    getEnumList({
      enumCd: ENUM.ENUM_CD_0230
    }).then(function (res) {
      _this.selectData.countType = res;
    }); //结算方式

    getEnumList({
      enumCd: ENUM.ENUM_CD_0228
    }).then(function (res) {
      _this.selectData.applyType = res;
    }); //产品.非私募

    var productParam = {
      query: {
        enabled: "1",
        arrayProductCategory1: [PRODUCT_CATEGORY1.HJ, PRODUCT_CATEGORY1.JJS, PRODUCT_CATEGORY1.YXHH, PRODUCT_CATEGORY1.XT, PRODUCT_CATEGORY1.OTHER]
      }
    };
    listEnum(productParam).then(function (res) {
      _this.selectData.goOnProductId = res.data;
    }); //实物费用扣减类型

    getEnumList({
      enumCd: ENUM.ENUM_CD_DEDUCT_TYPE
    }).then(function (res) {
      _this.selectData.feeDeductType = res;
    });
  },
  computed: {
    //判断表单内容是否变化
    isFormChange: function isFormChange() {
      var strTempForm = JSON.stringify(this.tempFormModel);
      var strForm = JSON.stringify(this.formModel);
      return strTempForm != strForm;
    }
  },
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.getInfo();
        }
      },
      immediate: true
    },
    isFormChange: {
      handler: function handler(val) {}
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tabActive = "tabContract"; //默认打开第一个标签页  
      //费用扣减选项

      if (this.propsModel != null) {
        if (this.propsModel.productCountType == PRODUCT_COUNT_TYPE.SAME_DATE_GOLD.value) {
          //对日到期
          this.selectData.feeDeductType.splice(1, 1); //对日到期，没有扣除收益项
        } else if (this.propsModel.productCountType == PRODUCT_COUNT_TYPE.FIXED_DATE_GOLD.value || this.propsModel.productCountType == PRODUCT_COUNT_TYPE.FIXED_DATE.value) {
          //固定日到期
          this.selectData.feeDeductType.splice(2, 1); //固定日到期，没有扣除租金项
        }
      }
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.showDialog = false;
      this.$refs.form.resetFields();
      this.$emit("refresh");
    },
    cancel: function cancel() {
      //对话框取消按钮
      this.dialogClose();
    },
    onAttachmentOpen: function onAttachmentOpen() {
      //初始化标签
      this.tabActive = "tabContract";
      this.attachmentOpened = "dialog-position";
    },
    // TODO 确定
    clickSave: function clickSave() {
      var _this2 = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          if (_this2.checkForm()) {
            var param = {
              object: {}
            };
            param.object = _objectSpread({}, _this2.formModel);
            _this2.loading = true;
            save(param).then(function (res) {
              _this2.loading = false;
              _this2.model.countId = res.data;

              _this2.$set(_this2.propsModel, "countId", _this2.model.countId);

              _this2.$set(_this2.propsModel, "contractId", _this2.model.contractId);

              _this2.$message({
                message: "信息已保存",
                type: "success"
              }); //this.$emit("refresh");


              _this2.tempFormModel = Object.assign({}, _this2.formModel); //临时表单值，用于比较表单是否变化
            }).catch(function (e) {
              _this2.loading = false;
            });
          }
        } else {
          return false;
        }
      });
    },
    clickSubmit: function clickSubmit() {
      var _this3 = this;

      //审核通过
      var componentAttachmentCountCount = this.$refs.componentAttachmentCount.tableData.length; //回单凭证附件数

      if (componentAttachmentCountCount == 0 && this.formModel.applyBackCash > 0) {
        this.$message("请上传提款申请/结算清单");
      } else if (this.checkForm()) {
        if (this.isFormChange) {
          //表单有变化，没有暂存就提交
          var param = {
            object: {}
          };
          param.object = _objectSpread({}, this.formModel);
          this.loading = true;
          save(param).then(function (res) {
            var param = {
              countId: _this3.formModel.countId
            };
            submit(param).then(function (res) {
              _this3.loading = false;
              _this3.showDialog = false;

              _this3.$message({
                message: "已提交",
                type: "success"
              });

              _this3.dialogClose();
            });
          });
        } else {
          //已暂存过，直接提交
          var _param = {
            countId: this.formModel.countId
          };
          this.loading = true;
          submit(_param).then(function (res) {
            _this3.loading = false;
            _this3.showDialog = false;

            _this3.$message({
              message: "已提交",
              type: "success"
            });

            _this3.dialogClose();
          });
        }
      }
    },
    getInfo: function getInfo() {
      var _this4 = this;

      var countId = this.propsModel.countId;
      var param = {
        countId: countId
      }; //this.loading = true;

      _getInfo(param, DATA_FORMAT_TYPE.USRE_SETUP).then(function (res) {
        //this.loading = false;
        var _res$data = res.data,
            data = _res$data === void 0 ? {} : _res$data;
        _this4.model = data;
        setExtendAttribute(_this4.model); //包装必要展示字段

        _this4.formModel = Object.assign({}, _this4.model); //克隆

        _this4.formModel.countAmountAll = _this4.formModel.countAmountAll.toFixed(2); //克隆对象数字产生精度问题

        formatObjectAmountToNumber(_this4.formModel); //对象中金额，千分位重新转成数值

        if (_this4.model.serviceFee == null) {
          //手续费
          _this4.formModel.serviceFee = 0;
        }

        if (_this4.model.rentRansomType == "1") {
          //归还珠宝实物，删除现金，现金+实物两个选项
          //this.selectData.applyType.splice(0, 2);
          _this4.$set(_this4.formModel, "applyType", _this4.model.applyType);
        }

        _this4.tempFormModel = Object.assign({}, _this4.formModel); //临时表单值，用于比较表单是否变化
      }).catch(function (e) {
        _this4.loading = false;
      });
    },
    showLog: function showLog() {
      //显示审核日志
      this.showLogDialog = true;
    },
    //变更费用
    changeFee: function changeFee() {
      //变更提取金额(原赎回金额-续约金额-相关手续约)
      var countAmountAll = this.formModel.countAmountAll == null ? 0 : formatAmountToNumber(this.formModel.countAmountAll); //原始权益合计

      var applyBackCash = this.formModel.applyBackCash == null ? 0 : formatAmountToNumber(this.formModel.applyBackCash); //原始提取金额

      var goOnAmount = this.formModel.goOnAmount == null ? 0 : parseFloat(this.formModel.goOnAmount); //续约金额

      var serviceFee = this.formModel.serviceFee == null ? 0 : parseFloat(this.formModel.serviceFee); //手续费

      var investGoldFee = this.formModel.investGoldFee == null ? 0 : parseFloat(this.formModel.investGoldFee); //投资加工费

      var packFee = this.formModel.packFee == null ? 0 : parseFloat(this.formModel.packFee); //投资包装费

      countAmountAll = countAmountAll - serviceFee; //权益合计 - 手续费

      countAmountAll = Math.round(countAmountAll * 100) / 100;
      applyBackCash = applyBackCash - goOnAmount - serviceFee - investGoldFee - packFee;
      applyBackCash = Math.round(applyBackCash * 100) / 100;
      this.$set(this.formModel, "countAmountAll", countAmountAll);
      this.$set(this.formModel, "applyBackCash", applyBackCash);

      if (this.formModel.countAmountAll < 0) {
        this.$set(this.formModel, "countAmountAll", 0);
      }

      if (this.formModel.applyBackCash < 0) {
        this.$set(this.formModel, "applyBackCash", 0);
      }
    },
    //变更是否接受采访
    changeCustomerVisitClaim: function changeCustomerVisitClaim() {
      var val = this.formModel.customerVisitClaim;

      if (val == 0) {
        //不接受
        this.$set(this.formModel, "customerVisitRemark", "客户不方便");
      } else if (val == 1) {
        this.$set(this.formModel, "customerVisitRemark", "");
      }
    },
    //表单必填项检查
    checkForm: function checkForm() {
      var rs = true;

      if (this.formModel.applyBackCash > this.formModel.countAmountAll) {
        this.$message("提现金额不能大于权益合计");
        rs = false;
      }

      if (this.formModel.goOnProductId != null && this.formModel.goOnProductId != "" && (this.formModel.goOnAmount == null || this.formModel.goOnAmount <= 0)) {
        this.$message("请填写续约金额");
        rs = false;
      }

      if (this.formModel.goOnAmount > 0 && (this.formModel.goOnProductId == null || this.formModel.goOnProductId == "")) {
        this.$message("请选择续约产品");
        rs = false;
      }

      if (this.formModel.customerVisitClaim == "0" && (this.formModel.customerVisitRemark == "" || this.formModel.customerVisitRemark == null)) {
        this.$message("请填写不需回访原因");
        rs = false;
      }

      return rs;
    }
  }
};