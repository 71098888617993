import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.find-index.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { cloneDeep } from 'lodash-es';
import { FORM_CONFIG } from './config';
import { DIALOG_FORM_CONFIG, DIALOG_FORM_DATA } from './dialog';
import { getOrgTree } from '@api/rm/org';
import { useSuccessTip } from '@/hooks/use-tip';
import url from '@/api-new/url';
import api from '@/api-new';
export default {
  name: 'BasicSetting',
  props: {
    modelData: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      KeFu: null,
      form: {
        config: FORM_CONFIG,
        model: {
          activityType: '',
          classifyId: '',
          activityRequire: [],
          budgetList: []
        }
      },
      dialogFormConfig: cloneDeep(DIALOG_FORM_CONFIG),

      /* 弹窗数据 */
      dialogFormData: cloneDeep(DIALOG_FORM_DATA),
      submiting: false,
      releaseStatus: 0,
      releaseType: 1,
      releaseTime: '',
      dialogVisible: false
    };
  },
  mounted: function mounted() {
    this.init();
    var query = this.$router.currentRoute.query;
    /* 判断是否为编辑 */

    var isEdit = !!query.id;
    /* 点击修改跳过来的 */

    if (isEdit) {
      this.getDetail(query.id);
    } else {
      this.form.model = _objectSpread({}, this.modelData); // 小程序回显

      if (this.form.model.activityType === '5') {
        // 小程序 需要存appid 以及 路径
        var linkUrl = JSON.parse(this.form.model.linkUrl);

        if (linkUrl) {
          this.form.model.appId = linkUrl.appId ? linkUrl.appId : '';
          this.form.model.path = linkUrl.path ? linkUrl.path : '';
        }
      }
    }
  },
  methods: {
    /* 弹窗确认 */
    dialogConfirm: function dialogConfirm() {
      var _this = this;

      this.$nextTick(function () {
        _this.$refs.addform.submitForm();
      });
    },

    /* 弹窗确认 */
    dialogSubmit: function dialogSubmit() {
      this.form.model.budgetList.push(this.dialogFormData);
      this.dialogVisible = false;
    },
    Cancel: function Cancel() {
      this.$router.push({
        name: 'activtyManager'
      });
    },
    addBudget: function addBudget() {
      this.dialogVisible = true;
      this.dialogFormData = {};
    },
    deleteBudget: function deleteBudget(index) {
      var length = this.form.model.budgetList.length;
      this.form.model.budgetList.splice(index, length);
    },

    /* 发布 */
    Release: function Release() {
      this.$refs.form.submitForm();
    },

    /** 触发表单提交 */
    FormSubmit: function FormSubmit(model) {
      var _model$mainPicture,
          _this2 = this;

      this.submiting = true;
      var _url = url.activity.manage.addOrUpdateActivity;
      var _model$activityRequir = model.activityRequire,
          activityRequire = _model$activityRequir === void 0 ? [] : _model$activityRequir,
          _model$restrictOrgCds = model.restrictOrgCds,
          restrictOrgCds = _model$restrictOrgCds === void 0 ? [] : _model$restrictOrgCds;

      var params = _objectSpread(_objectSpread({}, model), {}, {
        mainPicture: (_model$mainPicture = model.mainPicture) === null || _model$mainPicture === void 0 ? void 0 : _model$mainPicture.url,
        activityRequire: activityRequire.join(','),
        restrictOrgCds: restrictOrgCds.join(',')
      }); // 直播类型处理 appid和页面路径数据


      if (params.activityType === '5') {
        params.linkUrl = JSON.stringify({
          path: params.path,
          appId: params.appId
        });
      }

      api.COMMON_REQUEST(_url, params).then(function () {
        useSuccessTip();

        _this2.Cancel();
      }).catch(function () {
        _this2.submiting = false;
      });
    },

    /* 获取文章信息详情 */
    getDetail: function getDetail(id) {
      var _this3 = this;

      api.GET_ACTIVITY_DETAIL(id).then(function (res) {
        var activityRequire = res.activityRequire,
            mainPicture = res.mainPicture,
            restrictOrgCds = res.restrictOrgCds;
        res.mainPicture = {
          url: mainPicture
        };
        res.activityRequire = activityRequire ? activityRequire.split(',') : [];
        res.restrictOrgCds = restrictOrgCds ? restrictOrgCds.split(',') : [];
        _this3.form.model = _objectSpread(_objectSpread({}, res), _this3.modelData); // 小程序回显

        if (_this3.form.model.activityType === '5') {
          // 小程序 需要存appid 以及 路径
          var linkUrl = JSON.parse(_this3.form.model.linkUrl);

          if (linkUrl) {
            _this3.form.model.appId = linkUrl.appId ? linkUrl.appId : '';
            _this3.form.model.path = linkUrl.path ? linkUrl.path : '';
          }
        }
      }).catch(function () {});
    },

    /* 获取所有活动分类 */
    getClassifyList: function getClassifyList() {
      var _this4 = this;

      api.GET_CLASSIFY_LIST().then(function (res) {
        _this4.form.config[0].children[6].options = res;
      });
    },

    /* 获取所有活动类型 */
    getTypeList: function getTypeList() {
      var _this5 = this;

      api.GET_TYPE_LIST().then(function (res) {
        // 活动类型顺序
        _this5.form.config[0].children[1].options = res || [];

        _this5.isKefu();
      });
    },
    formatChildData: function formatChildData(data, childStr) {
      if (data && data.length) {
        data.forEach(function (val) {
          if (val[childStr] && val[childStr].length < 1) {
            delete val[childStr];
          } else {
            this.formatChildData(val[childStr], childStr);
          }
        }.bind(this));
        return data;
      }
    },

    /* 获取发布组织下拉 */
    getAllOrganization: function getAllOrganization() {
      var _this6 = this;

      getOrgTree({}).then(function (res) {
        _this6.form.config[0].children[14].options = _this6.formatChildData([res.data], 'children');
      });
    },
    // 查看是否客服部
    isKefu: function isKefu() {
      var _this7 = this;

      api.IS_KEFU().then(function (res) {
        _this7.KeFu = res;
      });
    },
    init: function init() {
      this.getClassifyList();
      this.getAllOrganization();
      this.getTypeList();
    }
  },
  watch: {
    KeFu: {
      handler: function handler(val) {
        var config = this.form.config[0].children[1].options; // 本部才会显示品牌类

        if (val) {
          this.getTypeList();
        } else {
          var index = config.findIndex(function (item) {
            return item.value === '1';
          });

          if (index !== -1) {
            config.splice(index, 1);
          }
        }
      } // immediate: true

    },
    'form.model.activityType': {
      immediate: true,
      deep: true,
      handler: function handler(newValue, oldValue) {
        // const config = this.form.config[0].children;
        if (newValue === '3') {
          /* 当活动类型选中行业类时则显示  业绩目标 活动需求 */
          this.form.config[0].children[12].hidden = false;
          this.form.config[0].children[11].hidden = false;
        } else {
          this.form.config[0].children[12].hidden = true;
          this.form.config[0].children[11].hidden = true;
        } // 直播类隐藏活动链接 活动地点 和 人数限制


        if (newValue === '5') {
          this.form.config[0].children[3].hidden = false;
          this.form.config[0].children[4].hidden = false;
          this.form.config[0].children[2].hidden = true;
          this.form.config[0].children[7].hidden = true;
          this.form.config[0].children[10].hidden = true;
        } else {
          // 隐藏小程序id 和 页面地址
          this.form.config[0].children[3].hidden = true;
          this.form.config[0].children[4].hidden = true;
          this.form.config[0].children[2].hidden = false;
          this.form.config[0].children[7].hidden = false;
          this.form.config[0].children[10].hidden = false;
        } // 参访类显示活动预算


        var index = this.form.config.findIndex(function (item) {
          return item.title === '活动预算';
        });

        if (newValue !== '4') {
          if (index > -1) {
            this.form.config.splice(index, 1);
          }

          this.form.model.budgetList = [];
        } else if (index < 0) {
          this.form.config.splice(1, 0, {
            title: '活动预算',
            children: [{
              labelWidth: 150,
              type: 'slot',
              label: '',
              prop: 'budgetList',
              col: 24
            }]
          });
        }
      }
    }
  }
};