import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.string.pad-start.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import DATA_FORMAT_TYPE from '@/maps/enum/data-format-type';
import { FILTER_PROPS, FILTER_FORMS } from './option/filter'; // 过滤条件的form

import TABLE_LIST from './option/list'; // 表头

import DynamicForm from '@/components/common/dynamic-form';
import DynamicTable from '@/components/common/dynamic-table';
import { formatAmountToString, formatDate } from '@/libs/crm/format';
import { listOrgBusiness, exportExcelOrgBusiness, testSumBusiness } from '@api/crm/report/report';
export default {
  name: 'business',
  components: {
    DynamicForm: DynamicForm,
    DynamicTable: DynamicTable
  },
  data: function data() {
    return {
      loading: false,
      filterProps: FILTER_PROPS,
      filterForms: FILTER_FORMS,
      headers: TABLE_LIST,
      table: {
        auto: false
      },
      sumAmount: 0,
      //总合同金额
      sumYearAmount: 0,
      //年化入金统计
      pagination: {},
      query: {},
      tableData: [],
      currentRow: {} // 当前点击行

    };
  },
  computed: {},
  methods: {
    init: function init() {
      var _this = this;

      // 获取当前月份
      var now = new Date();
      var year = now.getFullYear(); // 得到年份

      var month = now.getMonth(); // 得到月份
      // let date = now.getDate(); //得到日期

      month = month + 1;
      month = month.toString().padStart(2, '0'); // date = date.toString().padStart(2, '0');

      var defaultDate = "".concat(year, "-").concat(month);
      this.$nextTick(function (item) {
        // 初始化默认时间
        _this.$refs.form.model.month = defaultDate;
      });
      this.loading = false;
      this.tableData = [];
    },
    // 初始化搜索条件
    initFilter: function initFilter() {
      var query = this.$refs.form.getForm();
      this.query = _objectSpread(_objectSpread({}, this.query), query);
    },
    // 导出Excel
    clickExport: function clickExport() {
      var _this2 = this;

      this.$confirm('按查询条件导出数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this2.initFilter();

        var param = {
          query: _this2.query
        };
        exportExcelOrgBusiness(param).catch(function (e) {
          _this2.$message({
            message: e,
            type: 'error'
          });
        });
      }).catch(function () {});
    },
    //点击刷新
    clickRefresh: function clickRefresh() {
      this.$refs.jTable.onRefresh();
      this.$message({
        message: '数据已刷新',
        type: 'info',
        duration: 500
      });
    },
    //点击重置
    clickReset: function clickReset() {
      this.$refs.form.reset();
    },
    // TODO 搜索
    clickSearch: function clickSearch() {
      this.initFilter();
      this.$refs.jTable.pageChange(1);
    },
    // TODO 获取列表
    loadData: function loadData(_ref) {
      var _this3 = this;

      var _ref$page = _ref.page,
          page = _ref$page === void 0 ? {} : _ref$page;
      this.loading = true;
      this.initFilter();
      var param = {
        page: page,
        query: this.query
      };
      this.loading = true;
      listOrgBusiness(param, DATA_FORMAT_TYPE.USRE_SETUP).then(function (res) {
        _this3.loading = false;
        _this3.tableData = [];

        if (res.data != null) {
          var _res$data = res.data,
              data = _res$data === void 0 ? [] : _res$data,
              _page = res.page;
          var _page$totalCount = _page.totalCount,
              totalCount = _page$totalCount === void 0 ? 0 : _page$totalCount;
          _this3.tableData = data;
          _this3.pagination = {
            total: totalCount
          };
        } else {
          _this3.$message({
            message: '暂无数据',
            type: 'info'
          });
        }
      }).catch(function (e) {
        _this3.loading = false;

        _this3.$message({
          message: '查询列表数据出错',
          type: 'error'
        });
      });
    },
    // TODO 选中行
    selectRow: function selectRow(row) {
      this.currentRow = row;
    },
    ////测试短信，上线删除///////////////////////////////////////////////////
    // TODO
    testSendSms: function testSendSms() {
      var _this4 = this;

      this.initFilter();

      if (this.query.dateEnd == null || this.query.dateEnd == '') {
        var date = new Date();
        var today = formatDate(date);
        this.query.date = today;
      } else {
        this.query.date = this.query.dateEnd;
      }

      var param = {
        query: this.query
      };
      testSumBusiness(param).then(function (res) {
        console.dir(res);

        _this4.$message(res.data);
      }).catch(function (e) {
        _this4.loading = false;

        _this4.$message({
          message: '查询列表数据出错',
          type: 'error'
        });
      });
    },
    ///////////////////////////////////////////////////////////////////////
    // 日期 和 日期时间只能选择一个
    itemChange: function itemChange(e) {
      var valueKey = e.valueKey;

      if (valueKey === 'month') {
        this.$refs.form.model.dateBegin = '';
        this.$refs.form.model.dateEnd = '';
      } else if (valueKey === 'dateBegin' || valueKey === 'dateEnd') {
        this.$refs.form.model.month = '';
      }
    }
  },
  created: function created() {
    this.init();
  }
};