import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
// 查询条件
var PUBLIC_CONFIG = {
  labelWidth: 80
}; // 表格列宽

var PUBLIC_TABLE_LABEL_CONFIG = {
  width: 150
};
export var TOP_FORM = {
  dateType: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '日期类型',
    col: 6,
    props: {
      placeholder: '请选择'
    },
    options: [{
      label: '全部',
      value: ''
    }, {
      label: '公司资讯',
      value: '1'
    }, {
      label: '员工资讯',
      value: '2'
    }, {
      label: '目标达成',
      value: '3'
    }, {
      label: '业绩冲刺',
      value: '4'
    }, {
      label: '付息',
      value: '5'
    }, {
      label: '活动',
      value: '6'
    }, {
      label: '新人入职',
      value: '7'
    }]
  }),
  festivalName: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '节日名称',
    props: {
      placeholder: '请输入节日名称'
    }
  })
};
export var TABLE_LABEL = [{
  label: '流水号',
  prop: 'code',
  type: 'link',
  width: 200
}, _objectSpread(_objectSpread({}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  label: '节日名称',
  prop: 'festivalTypeValue'
}), _objectSpread(_objectSpread({}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  label: '节日名称',
  prop: 'festivalName'
}), _objectSpread(_objectSpread({}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  label: '节日日期',
  prop: 'festivalDate'
}), {
  label: '节日祝福',
  prop: 'festivalGreeting',
  width: 300
}, _objectSpread(_objectSpread({}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  label: '是否自动发送',
  prop: 'autoSend',
  formatter: function formatter(row, column, cellValue) {
    var res;

    switch (cellValue) {
      case 1:
        res = '是';
        break;

      case 0:
        res = '否';
        break;

      default:
        res = '';
    }

    return res;
  }
}), _objectSpread(_objectSpread({}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  label: '发送时间',
  prop: 'sendTime'
}), _objectSpread(_objectSpread({}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  label: '是否全部客户',
  prop: 'isAllCustomer',
  formatter: function formatter(row, column, cellValue) {
    var res;

    switch (cellValue) {
      case 1:
        res = '是';
        break;

      case 0:
        res = '否';
        break;

      default:
        res = '';
    }

    return res;
  }
}), {
  label: '限制条件',
  prop: 'restrictedConditionValue',
  width: 300
}, _objectSpread(_objectSpread({}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  label: '客户性别',
  prop: 'sex',
  formatter: function formatter(row, column, cellValue) {
    var res;

    switch (cellValue) {
      case '1':
        res = '男';
        break;

      case '0':
        res = '女';
        break;

      default:
        res = '未知';
    }

    return res;
  }
}), _objectSpread(_objectSpread({}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  label: '客户等级',
  prop: 'customerLevelValue'
}), _objectSpread(_objectSpread({}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  label: '客户类型',
  prop: 'customerTypeValue'
}), _objectSpread(_objectSpread({}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  label: '客户年龄区间',
  prop: 'customerRange',
  formatter: function formatter(row, column, cellValue) {
    var res = '';

    if (row.maxAge && row.minAge) {
      res = row.minAge + '~' + row.maxAge;
    }

    return res;
  }
}), _objectSpread(_objectSpread({}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  label: '创建人',
  prop: 'createUserName'
}), _objectSpread(_objectSpread({}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  label: '创建时间',
  prop: 'createTime'
}), _objectSpread(_objectSpread({}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  label: '更新人',
  prop: 'updateUserName'
}), _objectSpread(_objectSpread({}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  label: '更新时间',
  prop: 'updateTime'
}), {
  type: 'operation',
  label: '操作',
  fixed: 'right',
  width: 100,
  formatter: function formatter(row) {
    var arr = [{
      type: 'compile',
      label: '编辑',
      permission: 'FestivalGreeting-update'
    }, {
      type: 'delete',
      label: '删除',
      permission: 'FestivalGreeting-delete'
    }];
    return arr;
  }
}];
export var TABLE_CONFIG = {
  isSelect: true,
  actions: [{
    label: '新增',
    type: 'add',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'FestivalGreeting-add'
  }, {
    label: '导出',
    type: 'export',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'FestivalGreeting-export'
  }]
};