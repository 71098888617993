import _toConsumableArray from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.dot-all.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.sticky.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from '@/mixins/dialog';
import Attachment from '@/components/business/attachment';
import AttachmentAudio from '@/components/business/attachment/audio';
import SearchCustomer from '@/components/business/search-customer';
import SearchUser from '@/components/business/search-user';
import ContractLog from '@/components/business/contract-log';
import ATTACHMENT_TYPE from '@/maps/enum/attachment-type';
import GENDER from '@/maps/enum/gender';
import ENUM from '@/maps/enum/enum';
import PRODUCT_CATEGORY1 from '@/maps/enum/product-category1';
import DATA_FORMAT_TYPE from '@/maps/enum/data-format-type';
import LOG_TYPE from '@/maps/enum/contract-log-type';
import YES_NO from '@/maps/enum/status/yesNo';
import BUY_STATUS from '@/maps/enum/status/buy';
import { getEnumList } from '@api/common/common';
import { getInfo as productInfo } from '@api/crm/setup/product';
import { listCustomerBankCard, checkBankCard3c as _checkBankCard3c } from '@api/crm/customer/bankCard';
import { computeContractEndDate, computeCustomerBirthday, computeCustomerGender } from '@/libs/crm/crm';
import { listEnum } from '@api/crm/setup/product';
import { save, submit, getInfo as _getInfo, computeEarnings, newContractCd } from '@api/crm/buy/buy'; //合同认购

export default {
  name: 'buy-private-add',
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {
    Attachment: Attachment,
    AttachmentAudio: AttachmentAudio,
    SearchCustomer: SearchCustomer,
    SearchUser: SearchUser,
    ContractLog: ContractLog
  },
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      formModel: {},
      tempFormModel: {},
      // 暂存表单值
      tabActive: 'tabContract',
      //标签框默认选中
      selectData: {
        //表单各项初始数据
        idType: [],
        //证件类型
        timeLimit: [],
        //合同期限
        returnPeriod: [],
        //返还周期
        payMethod: [],
        //收款方式
        accountType: [],
        //账户类型,
        productId: [],
        //产品
        sex: [] //性别

      },
      showSearchCustomer: false,
      showSearchUser: false,
      isCanSubmit: false,
      //是否可以提交
      showLogDialog: false,
      attachmentType: ATTACHMENT_TYPE.CONTRACT_STAFF,
      //附件类型
      attachmentTypeAudio: ATTACHMENT_TYPE.CONTRACT_AUDIO,
      //附件类型：合同录音
      contractLogType: LOG_TYPE.BUY,
      isAttachmentCanEdit: false,
      //附件是否可编辑（可上传删除）
      attachmentOpened: '',
      //附件打开状态
      product: null,
      //产品
      bankCardList: [],
      //客户银行卡信息
      showBankCardButton: true,
      //是否显示校验银行卡按钮
      bankCardButtonDisabled: false,
      //银行卡校验按钮是否可用
      rules: {
        contractCd: [{
          required: true,
          message: '请填写合同编号',
          trigger: 'input'
        }],
        customerName: [{
          required: true,
          message: '请填写客户姓名',
          trigger: 'input'
        }],
        identification: [{
          required: true,
          message: '请填写证件号码',
          trigger: 'input'
        }],
        birthday: [{
          required: true,
          message: '请填写客户生日',
          trigger: 'input'
        }],
        mobile: [{
          required: true,
          message: '请填写联系方式',
          trigger: 'input'
        }],
        email: [{
          required: true,
          message: '请填写email',
          trigger: 'input'
        }],
        userName: [{
          required: true,
          message: '请选择经纪人',
          trigger: 'input'
        }],
        address: [{
          required: true,
          message: '请填写客户地址',
          trigger: 'input'
        }],
        productId: [{
          required: true,
          message: '请选择产品',
          trigger: 'input'
        }],
        timeLimit: [{
          required: true,
          message: '请选择合同期限',
          trigger: 'input'
        }],
        returnPeriod: [{
          required: true,
          message: '请选择返还周期',
          trigger: 'input'
        }],
        startDate: [{
          required: true,
          message: '请选择开始日期',
          trigger: 'input'
        }],
        endDate: [{
          required: true,
          message: '请选择到期日期',
          trigger: 'input'
        }],
        payMethod: [{
          required: true,
          message: '请选择收款方式',
          trigger: 'input'
        }],
        amount: [{
          required: true,
          message: '请填写合同金额',
          trigger: 'input'
        }],
        rentRansomType: [{
          required: true,
          message: '请选择租赁赎回方式',
          trigger: 'input'
        }],
        bankName: [{
          required: true,
          message: '请填写银行+分行+支行信息',
          trigger: 'input'
        }],
        accountNo: [{
          required: true,
          message: '请填写银行帐号',
          trigger: 'input'
        }],
        accountName: [{
          required: true,
          message: '请填写银行户名',
          trigger: 'input'
        }],
        sex: [{
          required: true,
          message: '请选择客户性别',
          trigger: 'input'
        }]
      }
    };
  },
  created: function created() {
    var _this = this;

    //证件类型
    getEnumList({
      enumCd: ENUM.ENUM_CD_0208
    }).then(function (res) {
      _this.selectData.idType = res;
    }); //收款方式

    getEnumList({
      enumCd: ENUM.ENUM_CD_0204
    }).then(function (res) {
      _this.selectData.payMethod = res;
    }); //租赁赎回方式

    getEnumList({
      enumCd: ENUM.ENUM_CD_0249
    }).then(function (res) {
      _this.selectData.rentRansomType = res;
    }); //账户类型

    getEnumList({
      enumCd: ENUM.ENUM_CD_0207
    }).then(function (res) {
      _this.selectData.accountType = res;
    }); //产品.私募

    listEnum({
      query: {
        enabled: '1',
        arrayProductCategory1: [PRODUCT_CATEGORY1.SM]
      }
    }).then(function (res) {
      _this.selectData.productId = res.data;
    }); //性别

    this.selectData.sex = [GENDER.MALE, GENDER.FEMALE];
  },
  computed: {
    // 弹窗展示的标题
    title: function title() {
      return this.isEdit ? '修改' : '新增';
    },
    // 是否为编辑
    isEdit: function isEdit() {
      var contractId = this.propsModel.contractId;
      return !!contractId;
    },
    //判断表单内容是否变化
    isFormChange: function isFormChange() {
      var strTempForm = JSON.stringify(this.tempFormModel);
      var strForm = JSON.stringify(this.formModel);
      return strTempForm != strForm;
    },
    isHaveContractBuyCd: function isHaveContractBuyCd() {
      //是否有购销合同编号
      var rs = false;

      if (this.model.buyContractCd != null && this.model.buyContractCd != '') {
        rs = true;
      }

      return rs;
    }
  },
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();

          if (this.isEdit) {
            this.getInfo();
          } else {
            this.formModel = {
              idType: '01',
              accountType: '1',
              productId: this.propsModel.productId
            };
            this.changeProduct();
            this.isCanSubmit = false;
          }
        }
      },
      immediate: true
    },
    isFormChange: {
      handler: function handler(val) {}
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tabActive = 'tabContract'; //默认打开第一个标签页

      this.model = {};
      this.formModel = {};
      this.bankCardList = [];
      this.showBankCardButton = true; //是否显示校验银行卡按钮

      this.bankCardButtonDisabled = false; //银行卡校验按钮是否可用
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.showDialog = false;
      this.$refs.form.resetFields();
      this.$emit('refresh');
    },
    cancel: function cancel() {
      //对话框取消按钮
      this.dialogClose();
    },
    onAttachmentOpen: function onAttachmentOpen() {
      //初始化标签
      this.tabActive = 'tabContract';
      this.attachmentOpened = 'dialog-position';
    },
    // TODO 获取详情
    getInfo: function getInfo() {
      var _this2 = this;

      var contractId = this.propsModel.contractId;
      var accountNo = this.propsModel.accountNo;
      var identification = this.propsModel.identification; //this.loading = true;

      var promiseArr = []; //多个请求数组

      promiseArr[0] = _getInfo({
        contractId: contractId
      }, DATA_FORMAT_TYPE.NO); //合同信息

      var param2 = {
        page: {
          pageSize: 999,
          pageNumber: 1
        },
        query: {
          identification: identification
        }
      };
      promiseArr[1] = listCustomerBankCard(param2); //客户银行卡列表

      Promise.all(promiseArr).then(function (res) {
        _this2.formModel = res[0].data;
        _this2.isAttachmentCanEdit = true;
        _this2.isCanSubmit = true;

        _this2.changeProduct(); //产品各下拉选项


        _this2.tempFormModel = Object.assign({}, _this2.formModel); //临时表单值，用于比较表单是否变化
        //客户银行卡信息

        _this2.bankCardList = res[1].data;

        _this2.isShowBankCard3cButton(); //检查银行卡校验按钮是否显示

      }).catch(function (e) {
        _this2.loading = false;
      });
    },
    //保存前检查
    checkBeforeSave: function checkBeforeSave() {
      var isCanSave = true; //检查合同编号是否合规

      if (this.product.prefix != null && this.product.prefix != '') {
        var reg = new RegExp(this.product.prefix + '[A-Z\\d]+'); //前缀+大写字母或数字多位

        if (!reg.test(this.formModel.contractCd)) {
          this.$message({
            message: '合同编号不规范',
            type: 'error'
          });
          isCanSave = false;
        }
      } //检查身份证


      if (this.formModel.idType == '01' && this.formModel.identification.length != 18) {
        this.$message({
          message: '身份证号位数错误',
          type: 'error'
        });
        isCanSave = false;
      }

      return isCanSave;
    },
    //提交前检查
    checkBeforeSubmit: function checkBeforeSubmit() {
      var _this3 = this;

      var isCanSubmit = true; //1、检查必须附件是否齐全

      if (this.product != null && this.product.attachment != null) {
        var fileCurrent = this.$refs.componentAttachment.tableData; //当前已上传文件

        var arrayNameCurrent = [];

        for (var i = 0; i < fileCurrent.length; i++) {
          arrayNameCurrent.push(fileCurrent[i].fileName);
        }

        var need = this.checkAttachment(this.product.attachment, arrayNameCurrent, this.formModel.idType, this.formModel.rentRansomType); //检查附件是否按要求上传

        if (need.length > 0) {
          this.$message({
            message: '请上传必须文件：' + need.toString(),
            type: 'error'
          });
          isCanSubmit = false;
        }
      } //检查银行卡信息校验


      if (isCanSubmit) {
        if (this.showBankCardButton && !this.bankCardButtonDisabled) {
          //银行卡3c按钮显示，需要校验
          var bankCard = this.bankCardList.find(function (n) {
            return n.certifyDataId == _this3.formModel.contractId;
          });

          if (bankCard == null) {
            this.$message({
              message: '请先点击校验按钮，检查姓名、证件号、银行账号一致性'
            });
            isCanSubmit = false;
          } else {
            if (bankCard.isCertify == '2') {
              //校验不通过
              isCanSubmit = false;

              if (bankCard.certifyTimes < 3) {
                //前2次校验不通过
                this.$message({
                  message: '姓名、证件号、银行账号不一致，请检查更正',
                  type: 'error'
                });
              } else if (bankCard.certifyTimes >= 3) {
                //>3次，可以提交，需用户确认
                isCanSubmit = true;
              }
            } else if (bankCard.isCertify == '3') {
              //银行卡已注销
              this.$message({
                message: bankCard.certifyDesc,
                type: 'error'
              });
              isCanSubmit = false;
            }
          }
        }
      } //检查附件 必传


      if (this.$refs.componentAttachment.tableData.length == 0 || this.$refs.componentAttachmentAudio.attachmentList.length == 0) {
        this.$message({
          message: '必须上传附件和录音才可以提交合同',
          type: 'error'
        });
        isCanSubmit = false;
      }

      return isCanSubmit;
    },
    // TODO 确定
    clickSave: function clickSave(done) {
      var _this4 = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          var isSave = _this4.checkBeforeSave(); //保存前检查


          if (isSave) {
            var param = {
              object: {}
            };
            param.object = _objectSpread({}, _this4.formModel);
            _this4.loading = true;
            save(param).then(function (res) {
              _this4.loading = false;
              _this4.formModel.contractId = res.data;
              _this4.isAttachmentCanEdit = true;
              _this4.isCanSubmit = true;
              _this4.tempFormModel = Object.assign({}, _this4.formModel); //临时表单值，用于比较表单是否变化

              _this4.$set(_this4.propsModel, 'contractId', _this4.formModel.contractId);

              _this4.$message({
                message: '信息已暂存',
                type: 'success'
              });
            }).catch(function (e) {
              _this4.loading = false;
            });
          }
        } else {
          return false;
        }
      });
    },
    clickSubmit: function clickSubmit(done) {
      var _this5 = this;

      if (!this.propsModel.contractId) {
        this.$message('请完善合同信息');
      }

      var isSubmit = this.checkBeforeSubmit(); //保存前检查

      if (isSubmit) {
        if (this.showBankCardButton && this.bankCardButtonDisabled) {
          //银行卡3次校验不通过
          this.$confirm('银行账号校验不通过，详情可联系总部客服，是否确认提交?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {
            _this5.doSubmit();
          }).catch(function () {});
        } else {
          this.doSubmit();
        }
      }
    },
    doSubmit: function doSubmit() {
      var _this6 = this;

      //提交
      if (this.isFormChange) {
        //表单有变化，没有暂存就提交
        var param = {
          object: {}
        };
        param.object = _objectSpread({}, this.formModel);
        this.loading = true;
        save(param).then(function (res) {
          submit({
            contractId: _this6.formModel.contractId
          }).then(function (data) {
            _this6.loading = false;

            _this6.$message({
              message: '已提交',
              type: 'success'
            });

            _this6.dialogClose();
          }).catch(function (e) {
            _this6.loading = false;
          });
        });
      } else {
        //已暂存过，直接提交
        this.loading = true;
        submit({
          contractId: this.formModel.contractId
        }).then(function (data) {
          _this6.loading = false;

          _this6.$message({
            message: '已提交',
            type: 'success'
          });

          _this6.dialogClose();
        }).catch(function (e) {
          _this6.loading = false;
        });
      }
    },
    popSelectCustomer: function popSelectCustomer(customer) {
      //给查找客户子组件选中时调用
      this.$set(this.formModel, 'customerName', customer.customerName);
      this.$set(this.formModel, 'idType', customer.idType);
      this.$set(this.formModel, 'identification', customer.identification);
      this.$set(this.formModel, 'mobile', customer.mobile);
      this.$set(this.formModel, 'accountName', customer.customerName); // 户名
      // if (customer.agentUserEnabled == '1') {
      //在职

      this.$set(this.formModel, 'orgCd', customer.orgCd);
      this.$set(this.formModel, 'orgName', customer.orgName);
      this.$set(this.formModel, 'userId', customer.agentUserId);
      this.$set(this.formModel, 'userName', customer.agentUserName);
      this.$set(this.formModel, 'birthday', customer.birthday);
      this.$set(this.formModel, 'sex', customer.sex); // this.$set(this.formModel, 'emName', customer.emName);
      // this.$set(this.formModel, 'emMobile', customer.emMobile);

      this.$set(this.formModel, 'email', customer.email);
      this.$set(this.formModel, 'address', customer.address);
      this.$set(this.formModel, 'customerId', customer.customerId);
      this.$set(this.formModel, 'customerCode', customer.customerCode);
      this.showSearchCustomer = false; //查询客户银行卡列表（经过实名认证比较准确）

      /*
      if (this.formModel.identification != null) {
        let param = {
          page: {
            pageSize: 999,
            pageNumber: 1,
          },
          query: {
            identification: this.formModel.identification,
          },
        };
        listCustomerBankCard(param).then(res => {
          this.bankCardList = res.data;
          if (this.bankCardList != null && this.bankCardList.length == 1) {
            //如果刚好只有一条
            let bankCard = this.bankCardList[0];
            this.selectBankCard(bankCard);
          } else {
            //没有卡，默认户名 = 姓名
            this.$set(this.formModel, 'accountName', this.formModel.customerName);
            this.$set(this.formModel, 'accountNo', '');
          }
          this.isShowBankCard3cButton(); //检查银行卡校验按钮是否显示
        });
      }*/
    },
    popSelectUser: function popSelectUser(user) {
      //给查找经纪人子组件选中时调
      this.$set(this.formModel, 'userId', user.personuuid);
      this.$set(this.formModel, 'orgCd', user.orgCd);
      this.$set(this.formModel, 'orgName', user.orgName);
      this.$set(this.formModel, 'userName', user.name);
      this.showSearchUser = false;
    },
    changeIdType: function changeIdType(val) {
      //证件类型变更，证件号码清空
      this.formModel.identification = '';
    },
    changeIdentification: function changeIdentification(val) {
      //变更证件号码，计算客户生日
      if (this.formModel.idType == '01' && this.formModel.identification.length != 18) {
        this.$message({
          message: '证件号位数错误',
          type: 'error'
        });
      } else {
        var birthday = computeCustomerBirthday(this.formModel.identification);

        if (birthday != null && birthday != '') {
          this.$set(this.formModel, 'birthday', birthday);
        } //计算客户性别


        var gender = computeCustomerGender(this.formModel.identification);

        if (gender != null) {
          //this.$set(this.formModel, "sex", gender);//不能直接设置值，否则下拉列表会显示value而不是text
          //需要匹配出数组的index，取数组的value
          var index = -1;

          for (var i = 0; i < this.selectData.sex.length; i++) {
            var n = this.selectData.sex[i];

            if (n.value == gender) {
              index = i;
              break;
            }
          }

          if (index > -1) {
            this.formModel.sex = this.selectData.sex[index].value;
          }
        }
      }
    },
    //变更产品
    changeProduct: function changeProduct() {
      var _this7 = this;

      var param = {
        productId: this.formModel.productId
      }; //产品详情

      productInfo(param).then(function (res) {
        _this7.product = res.data;

        if (_this7.product != null) {
          //合同前缀
          if (_this7.formModel.contractCd == null || _this7.formModel.contractCd == '' || _this7.formModel.contractCd.indexOf(_this7.product.prefix) != 0) {
            //如果合同编号不是按产品前缀开头
            _this7.$set(_this7.formModel, 'contractCd', _this7.product.prefix);
          }

          if (_this7.product.hasOwnProperty('prefixBuy')) {
            _this7.$set(_this7.formModel, 'buyContractCd', _this7.product.prefixBuy);
          } //产品固定到期日


          if (_this7.product.endDate != null && _this7.product.endDate != '') {
            _this7.selectEndDate = JSON.parse(_this7.product.endDate);
          } else {
            _this7.selectEndDate = [];
          } //合同期限


          if (_this7.product.timeLimit != null && _this7.product.timeLimit != '') {
            _this7.selectData.timeLimit = JSON.parse(_this7.product.timeLimit);
          } else {
            getEnumList({
              enumCd: ENUM.ENUM_CD_0201
            }).then(function (res) {
              _this7.selectData.timeLimit = res;
            });
          } //返还周期


          if (_this7.product.returnPeriod != null && _this7.product.returnPeriod != '') {
            _this7.selectData.returnPeriod = JSON.parse(_this7.product.returnPeriod);
          } else {
            getEnumList({
              enumCd: ENUM.ENUM_CD_0202
            }).then(function (res) {
              _this7.selectData.returnPeriod = res;
            });
          } //更新合同编号


          if (_this7.formModel.contractCd == null || _this7.formModel.contractCd == '' || _this7.formModel.contractCd == _this7.product.prefix) {
            console.log('1');

            if (_this7.product.isOfficialCd == YES_NO.NO.value) {
              //该产品需要由系统生成合同号
              _this7.changeContractCd(_this7.product.productId);
            }
          }

          _this7.changeEarnings(); //计算收益

        }
      });
    },
    //变更开始日期，计算到期日期
    changeStartDate: function changeStartDate(val) {
      var endDate = computeContractEndDate(this.product, this.formModel.timeLimit, this.formModel.startDate);

      if (endDate != null) {
        this.$set(this.formModel, 'endDate', endDate);
      }
    },
    //变更合同期限，计算到期日期
    changeTimeLimit: function changeTimeLimit(val) {
      var _this8 = this;

      var endDate = null;

      if (this.selectEndDate.length > 0) {
        //产品有固定到期日期
        this.selectEndDate.forEach(function (n) {
          if (n.month == _this8.formModel.timeLimit) {
            endDate = n.date;
          }
        });
      } else {
        //按合同期限和开始日期计算
        endDate = computeContractEndDate(this.product.productId, this.formModel.timeLimit, this.formModel.startDate);
      }

      this.$set(this.formModel, 'endDate', endDate);
    },
    //变更合同收益
    changeEarnings: function changeEarnings(val) {
      var _this9 = this;

      if (this.formModel.productId != null && this.formModel.amount > 0 && this.formModel.timeLimit != null && this.formModel.returnPeriod != null) {
        var param = {
          productId: this.formModel.productId,
          orgCd: this.formModel.orgCd,
          amount: this.formModel.amount,
          timeLimit: this.formModel.timeLimit,
          returnPeriod: this.formModel.returnPeriod,
          startDate: this.formModel.startDate,
          endDate: this.formModel.endDate
        };
        computeEarnings(param).then(function (res) {
          if (res.data.rate != null && res.data.rate != '') {
            if (res.data.lower > 0 || res.data.upper > 0) {
              _this9.isHaveFloatRate = true;
            } else {
              _this9.isHaveFloatRate = false;
            }

            _this9.$set(_this9.formModel, 'annualRate', res.data.rate);

            _this9.$set(_this9.formModel, 'floatAnnualRateFrom', res.data.lower);

            _this9.$set(_this9.formModel, 'floatAnnualRateTo', res.data.upper);

            _this9.$set(_this9.formModel, 'expectEarnings', res.data.earnings);
          } else {
            _this9.isHaveFloatRate = false;

            _this9.$set(_this9.formModel, 'annualRate', 0);

            _this9.$set(_this9.formModel, 'floatAnnualRateFrom', 0);

            _this9.$set(_this9.formModel, 'floatAnnualRateTo', 0);

            _this9.$set(_this9.formModel, 'expectEarnings', 0);
          }
        });
      }
    },
    // //生成新合同编号
    // changeContractCd() {
    //   if (this.formModel.contractCd == null || this.formModel.contractCd == '') {
    //     let productId = this.formModel.productId;
    //     newContractCd({ productId: productId }).then(res => {
    //       this.$set(this.formModel, 'contractCd', res.data);
    //     });
    //   }
    // },
    //生成新合同编号
    changeContractCd: function changeContractCd(productId) {
      var _this10 = this;

      var newCdProductId = productId;

      if ((newCdProductId == null || newCdProductId == '') && this.product != null) {
        newCdProductId = this.product.productId;
      }

      if (newCdProductId != null && newCdProductId != '') {
        newContractCd({
          productId: newCdProductId
        }).then(function (res) {
          _this10.$set(_this10.formModel, 'contractCd', res.data);
        });
      }
    },
    //检查附件名称
    //jsonNeedAll 该产品需要的全部文件json
    //arrayCurrent 当前已上传的文件数组
    //idType 合同的主体类型，个人还是机构
    //rentRansomType 赎回方式，赎回黄金，可能需要额外的文件
    checkAttachment: function checkAttachment(jsonNeedAll, arrayCurrent, idType, rentRansomType) {
      var arr = []; //还需要的文件名数组

      if (jsonNeedAll != null && jsonNeedAll != '') {
        var needAll = JSON.parse(jsonNeedAll);
        var com = needAll.com; //公共必须

        var con = ''; //合同主体所需，个人合同或机构合同

        if (idType == '06') {
          //机构投资者
          con = needAll.org;
        } else {
          con = needAll.person;
        }

        var all = com + ',' + con;

        if (rentRansomType != null && rentRansomType == '1' && needAll.jewelry != null) {
          //赎回实物
          all += ',' + needAll.jewelry;
        }

        var arrFileNeedAll = all.split(','); //系统必须的全部文件

        for (var i = 0; i < arrFileNeedAll.length; i++) {
          var n = arrFileNeedAll[i];

          if (!arrayCurrent.includes(n)) {
            arr.push(n);
          }
        }

        if (needAll.any != null) {
          //任意一个
          var isExist = false;
          var arrAny = needAll.any.split('|');

          for (var _i = 0; _i < arrAny.length; _i++) {
            var _n = arrFileNeedAll[_i];

            if (arrayCurrent.includes(_n)) {
              isExist = true;
              break;
            }
          }

          if (!isExist) {
            arr.push(needAll.any);
          }
        }
      }

      return arr;
    },
    //查询银行卡号
    queryBankCard: function queryBankCard(queryString, cb) {
      var array = _toConsumableArray(this.bankCardList);

      for (var i = 0; i < array.length; i++) {
        var n = array[i];
        n.value = n.accountNo; //value属性必填
      }

      cb(array);
    },
    //选择银行卡选项
    selectBankCard: function selectBankCard(item) {
      var bankFullName = item.bankName == null ? '' : item.bankName;
      bankFullName += item.branchName == null ? '' : item.branchName;
      this.$set(this.formModel, 'accountName', item.accountName);
      this.$set(this.formModel, 'accountNo', item.accountNo);
      this.$set(this.formModel, 'bankName', bankFullName);
    },
    //验证银行卡3要素（卡号，姓名，身份证）
    checkBankCard3c: function checkBankCard3c() {
      var _this11 = this;

      if (this.formModel.contractId == null) {
        this.$message('请先保存合同');
      } else {
        this.loading = true;
        var param = {
          certifyDataId: this.formModel.contractId,
          accountNo: this.formModel.accountNo,
          accountName: this.formModel.accountName,
          identification: this.formModel.identification
        };

        _checkBankCard3c(param).then(function (res) {
          _this11.loading = false;
          var bankCard = res.data;
          _this11.bankCardList[0] = bankCard;

          if (bankCard.isCertify == 1) {
            //验证一致
            _this11.$message({
              message: '银行账号校验通过',
              type: 'success'
            });
          } else if (bankCard.isCertify == 2) {
            //验证不一致
            _this11.$message({
              message: '姓名、证件号、银行账号不一致，请检查更正',
              type: 'warning'
            });
          } else if (bankCard.isCertify == 3) {
            //银行卡已注销
            _this11.$message({
              message: bankCard.certifyDesc,
              type: 'error'
            });
          }

          _this11.isShowBankCard3cButton(); //设置按钮显示样式

        });
      }
    },
    //是否显示校验银行卡信息按钮
    isShowBankCard3cButton: function isShowBankCard3cButton() {
      var _this12 = this;

      var isShow = true;

      if (this.formModel.contractState != null && this.formModel.contractState != BUY_STATUS.SAVE.value) {
        //待提交状态
        isShow = false;
      } else if (this.formModel.idType != '01') {
        //非身份证，无法校验
        isShow = false;
      } else {
        if (this.bankCardList != null) {
          //客户银行卡列表
          var bankCard = this.bankCardList.find(function (n) {
            return n.certifyDataId == _this12.formModel.contractId;
          }); //先从合同中检查

          if (bankCard == null) {
            //如果合同没有查到，再检查银行号
            bankCard = this.bankCardList.find(function (n) {
              return n.accountNo == _this12.formModel.accountNo;
            });
          }

          if (bankCard != null && bankCard.isCertify == YES_NO.YES.value) {
            //校验已通过
            if (this.formModel.identification == bankCard.identification && this.formModel.accountName == bankCard.accountName && this.formModel.accountNo == bankCard.accountNo) {
              //校验的信息和本次填的内容一致
              isShow = false;
            }
          }

          if (bankCard != null && bankCard.certifyTimes >= 3) {
            //校验超过3次，校验按钮不可用
            this.bankCardButtonDisabled = true;
          }
        }
      }

      this.showBankCardButton = isShow;
      return isShow;
    },
    //输入项转大写
    inputToUpperCase: function inputToUpperCase() {
      // //合同单号转大写
      // if (this.formModel.contractCd != null && this.formModel.contractCd != '') {
      //   this.formModel.contractCd = this.formModel.contractCd.toUpperCase();
      // }
      // //购销合同单号转大写
      if (this.formModel.buyContractCd != null && this.formModel.buyContractCd != '') {
        this.formModel.buyContractCd = this.formModel.buyContractCd.toUpperCase();
      } //身份证号转大写


      if (this.formModel.identification != null && this.formModel.identification != '') {
        this.formModel.identification = this.formModel.identification.toUpperCase();
      }
    },
    showLog: function showLog() {
      //显示审核日志
      this.showLogDialog = true;
    }
  }
};