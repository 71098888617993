var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        width: "85%",
        title: _vm.title,
        visible: _vm.showDialog,
        closeOnClickModal: false
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.close
      }
    },
    [
      _vm.showDialog
        ? _c(
            "el-tabs",
            {
              model: {
                value: _vm.tabActive,
                callback: function($$v) {
                  _vm.tabActive = $$v
                },
                expression: "tabActive"
              }
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "部门信息", name: "orgInfo" } },
                [
                  _c("OrgInfo", {
                    ref: "orgInfo",
                    attrs: {
                      org: _vm.org,
                      isEdit: _vm.isEdit,
                      loading: _vm.loading,
                      showDialog: _vm.showDialog
                    },
                    on: {
                      "update:loading": function($event) {
                        _vm.loading = $event
                      },
                      "update:showDialog": function($event) {
                        _vm.showDialog = $event
                      },
                      "update:show-dialog": function($event) {
                        _vm.showDialog = $event
                      },
                      refresh: _vm.refresh
                    }
                  })
                ],
                1
              ),
              _vm.isEdit
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "部门负责人", name: "orgHead" } },
                    [
                      _vm.tabActive === "orgHead"
                        ? _c("OrgHead", {
                            ref: "orgHead",
                            attrs: {
                              org: _vm.org,
                              isEdit: _vm.isEdit,
                              loading: _vm.loading,
                              showDialog: _vm.showDialog
                            },
                            on: {
                              "update:loading": function($event) {
                                _vm.loading = $event
                              },
                              "update:showDialog": function($event) {
                                _vm.showDialog = $event
                              },
                              "update:show-dialog": function($event) {
                                _vm.showDialog = $event
                              },
                              refresh: _vm.confirm
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isEdit
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "统计归属", name: "orgSuperior" } },
                    [
                      _vm.tabActive === "orgSuperior"
                        ? _c("OrgSuperior", {
                            ref: "orgSuperior",
                            attrs: {
                              org: _vm.org,
                              isEdit: _vm.isEdit,
                              loading: _vm.loading,
                              showDialog: _vm.showDialog
                            },
                            on: {
                              "update:loading": function($event) {
                                _vm.loading = $event
                              },
                              "update:showDialog": function($event) {
                                _vm.showDialog = $event
                              },
                              "update:show-dialog": function($event) {
                                _vm.showDialog = $event
                              },
                              refresh: _vm.confirm
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.tabActive === "orgInfo"
        ? _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                { attrs: { size: "mini" }, on: { click: _vm.cancel } },
                [_vm._v("取 消")]
              ),
              _vm.isEdit
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "mini",
                        loading: _vm.loading
                      },
                      on: { click: _vm.save }
                    },
                    [_vm._v("保存")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                    loading: _vm.loading
                  },
                  on: { click: _vm.clickSave }
                },
                [_vm._v("保存并关闭")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }