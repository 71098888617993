import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.keys.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import AUDIT_PROCESS from "@/maps/enum/audit-process";
import COLOR from "@/maps/enum/color";
import STATUS from "@/maps/enum/status/buy";
import PRODUCT_CATEGORY1 from '@/maps/enum/product-category1';
import PRODUCT_CATEGORY2 from '@/maps/enum/product-category2';
import DATA_FORMAT_TYPE from "@/maps/enum/data-format-type";
import { FILTER_PROPS, FILTER_FORMS } from "./option/filter"; // 过滤条件的form

import TABLE_LIST from "./option/list"; // 表头

import { list, remove } from "@api/crm/buy/buy";
import DynamicForm from "@/components/common/dynamic-form";
import DynamicTable from "@/components/common/dynamic-table";
import DialogAdd from "./components/dialog-add";
import DialogProduct from "./components/dialog-product";
import DialogInfo from "../../dialog-info";
export default {
  name: "buy-private",
  components: {
    DynamicForm: DynamicForm,
    DynamicTable: DynamicTable,
    DialogAdd: DialogAdd,
    DialogInfo: DialogInfo,
    DialogProduct: DialogProduct
  },
  data: function data() {
    return {
      loading: false,
      filterProps: FILTER_PROPS,
      filterForms: FILTER_FORMS,
      headers: TABLE_LIST,
      table: {},
      pagination: {},
      process: AUDIT_PROCESS.NEW.value,
      //当前审核流程          
      query: {
        arrayProductCategory1: [PRODUCT_CATEGORY1.SM] //私募

      },
      tableData: [],
      showAddDialog: false,
      // 展示新增/编辑弹窗
      showInfoDialog: false,
      // 展示信息
      showAddProductDialog: false,
      // 展示产品选择
      currentRow: {},
      // 当前点击行           
      canEditStatus: [STATUS.SAVE.value, STATUS.AUDIT_FAIL.value, STATUS.DEAL_FAIL.value, STATUS.FINANCE_AUDIT_FAIL.value, STATUS.COUNT_AUDIT_FAIL.value] //可以编缉的状态

    };
  },
  computed: {},
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
      this.$refs.jTable.pageChange(1);
    },
    //初始化搜索条件
    initFilter: function initFilter() {
      var query = this.$refs.form.getForm();
      this.query = _objectSpread(_objectSpread({}, this.query), query);
    },
    //点击刷新
    clickRefresh: function clickRefresh() {
      this.$refs.jTable.onRefresh();
      this.$message({
        message: "数据已刷新",
        type: "info",
        duration: 500
      });
    },
    //点击重置
    clickReset: function clickReset() {
      this.$refs.form.reset();
    },
    // TODO 搜索
    clickSearch: function clickSearch() {
      this.initFilter();
      this.$refs.jTable.pageChange(1);
    },
    // TODO 获取列表
    loadData: function loadData(_ref) {
      var _this = this;

      var _ref$page = _ref.page,
          page = _ref$page === void 0 ? {} : _ref$page;
      this.loading = true;
      this.initFilter();
      var param = {
        process: this.process,
        page: page,
        query: this.query
      };
      list(param, DATA_FORMAT_TYPE.USRE_SETUP).then(function (res) {
        _this.loading = false;
        _this.tableData = [];

        if (res.data != null) {
          var _res$data = res.data,
              data = _res$data === void 0 ? [] : _res$data,
              _page = res.page;
          var _page$totalCount = _page.totalCount,
              totalCount = _page$totalCount === void 0 ? 0 : _page$totalCount;
          _this.tableData = data;
          _this.pagination = {
            total: totalCount
          };
        } else {
          _this.$message({
            message: "暂无数据",
            type: "info"
          });
        }
      }).catch(function (e) {
        _this.loading = false;

        _this.$message({
          message: "查询列表数据出错",
          type: "error"
        });
      });
    },
    // TODO 选中行
    selectRow: function selectRow(row) {
      this.currentRow = row;
    },
    // TODO 点击新增
    clickEdit: function clickEdit() {
      var row = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.currentRow = row;
      this.showAddDialog = true;
    },
    //点击新增选择产品
    clickAddSelectProduct: function clickAddSelectProduct() {
      this.showAddProductDialog = true;
    },
    //查看详情
    clickInfo: function clickInfo() {
      var row = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.currentRow = row;
      this.showInfoDialog = true;
    },
    // TODO 点击删除
    clickRemove: function clickRemove() {
      var _this2 = this;

      if (Object.keys(this.currentRow).length === 0) {
        this.$message("请选择合同");
      } else if (this.currentRow.contractState != STATUS.SAVE.value) {
        this.$message("已生效无法删除");
      } else {
        this.$confirm("确定删除当前合同吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          _this2.loading = true;
          remove({
            contractId: _this2.currentRow.contractId
          }).then(function (res) {
            _this2.loading = false;

            _this2.init();
          }).catch(function (e) {
            _this2.loading = false;
          });
        }).catch(function () {});
      }
    },
    //新增选择产品确定
    popAddSelectProduct: function popAddSelectProduct(product) {
      this.currentRow = {
        productId: product.productId
      };

      if (PRODUCT_CATEGORY2.HJCZ == product.category2) {
        this.showAddHjczDialog = true;
      } else if (PRODUCT_CATEGORY2.HJWT == product.category2 || PRODUCT_CATEGORY2.HJXLS == product.category2) {
        this.showAddJxwyDialog = true;
      } else {
        this.showAddDialog = true;
      }
    },
    setStatusColor: function setStatusColor(status) {
      //合同状态颜色
      var className = "";

      switch (status) {
        case STATUS.SAVE.value:
          className = COLOR.BLUE;
          break;

        case STATUS.SUBMIT.value:
          className = COLOR.GREEN;
          break;

        case STATUS.AUDIT.value:
          className = COLOR.GREEN;
          break;

        case STATUS.COUNT_AUDIT.value:
          className = COLOR.GREEN;
          break;

        case STATUS.DEAL.value:
          className = "";
          break;

        case STATUS.SUSPEND.value:
          className = "";
          break;

        case STATUS.PAYED.value:
          className = "";
          break;

        case STATUS.AUDIT_FAIL.value:
          className = COLOR.RED;
          break;

        case STATUS.DEAL_FAIL.value:
          className = COLOR.RED;
          break;

        case STATUS.WAIT_FOR_FINANCE.value:
          className = COLOR.RED;
          break;

        case STATUS.FINANCE_AUDIT_FAIL.value:
          className = COLOR.RED;
          break;

        case STATUS.FINANCE_AUDIT.value:
          className = COLOR.RED;
          break;

        case STATUS.COUNT_AUDIT_FAIL.value:
          className = COLOR.RED;
          break;

        case STATUS.DEAL_RETURN.value:
          className = COLOR.RED;
          break;

        case STATUS.RESERVE_BUY.value:
          className = COLOR.GREEN;
          break;
      }

      return className;
    }
  },
  created: function created() {}
};