import _defineProperty from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";

var _name$components$prop;

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import ENABLE from '@/maps/enum/status/enable';
import TreeOrganization from '@/components/business/tree-organization';
import { listRight } from '@api/rm/person'; //经纪人信息

export default (_name$components$prop = {
  name: 'search-user',
  components: {},
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    searchUser: {
      // 是否 传值理顾 有值禁止修改
      type: String,
      default: ''
    }
  }
}, _defineProperty(_name$components$prop, "components", {
  TreeOrganization: TreeOrganization
}), _defineProperty(_name$components$prop, "data", function data() {
  return {
    loading: false,
    tableData: [],
    orgCd: '',
    //部门编号
    schWord: '',
    //搜索关健词
    selectItem: {} //选择的项

  };
}), _defineProperty(_name$components$prop, "watch", {
  showDialog: {
    handler: function handler(val) {
      if (val) {
        if (this.searchUser) {
          this.schWord = this.searchUser;
          this.clickSearch();
        }
      } else {
        this.init();
      }
    },
    immediate: true
  }
}), _defineProperty(_name$components$prop, "computed", {
  // 树的配置
  treeConfig: function treeConfig() {}
}), _defineProperty(_name$components$prop, "methods", {
  init: function init() {
    this.tableData = [];
    this.orgCd = '';
    this.schWord = '';
  },
  // TODO 搜索
  clickSearch: function clickSearch() {
    var _this = this;

    if (this.orgCd != null && this.orgCd != '' || this.schWord != null && this.schWord != '') {
      var param = {
        page: {
          pageNumber: 0,
          pageSize: 999
        },
        query: {
          enabled: ENABLE.YES.value,
          orgCd: this.orgCd,
          name: this.schWord
        }
      };
      this.loading = true;
      listRight(param).then(function (res) {
        _this.loading = false;
        _this.tableData = res.data;
      }).catch(function (e) {
        _this.loading = false;
      });
    }
  },
  clickSelectItem: function clickSelectItem(index, row) {
    this.selectItem = row;
    this.$emit('onSelectItem', this.selectItem); //调用父组件方法，将结果传回。参数1：父组件方法名，参数2：传回参数
  },
  // TODO 点击树节点
  treeNodeClick: function treeNodeClick(node) {
    this.orgCd = node.orgCd; //(不要点击根全部01展示经纪人，这样会导致渲染很慢，打开界面要很久，原因不明)

    if (this.orgCd != '01') {
      this.clickSearch();
    }
  }
}), _name$components$prop);