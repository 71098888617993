import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import useImageParse from '@/hooks/use-image-parse';
var PUBLIC_CONFIG = {
  labelWidth: 80
};
export var TOP_FORM = {
  name: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '模板名称',
    props: {
      placeholder: '请输入分类名称'
    }
  }),
  createUserName: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '创建人',
    props: {
      placeholder: '请输入创建人'
    }
  }),
  createTime: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'datePicker',
    label: '创建时间',
    propGroup: ['startTime', 'endTime'],
    props: {
      type: 'daterange'
    }
  }),
  status: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '状态',
    props: {
      placeholder: '请选择状态'
    },
    options: [{
      label: '启用',
      value: 1
    }, {
      label: '禁用',
      value: 0
    }]
  })
};
export var TABLE_LABEL = [{
  label: '模板名称',
  prop: 'name'
}, {
  label: '排序值',
  prop: 'sort',
  type: 'inputNumber',
  width: 200,
  props: {
    max: 999
  }
}, {
  label: '创建人',
  prop: 'createUserName'
}, {
  label: '创建时间',
  prop: 'createTime',
  sortable: 'custom',
  sortProp: 'createTime'
}, {
  label: '状态',
  prop: 'status',
  formatter: function formatter(row, column, cellValue) {
    var res = '';

    switch (Number(cellValue)) {
      case 0:
        res = '停用';
        break;

      case 1:
        res = '启用';
        break;

      default:
        res = '';
    }

    return res;
  }
}, {
  type: 'operation',
  label: '操作',
  width: 200,
  formatter: function formatter(row) {
    var arr = [{
      type: 'edit',
      label: '修改',
      permission: 'activityEvaluation-edit'
    }];

    if (row.status === 0) {
      arr.push({
        type: 'start',
        label: '启用',
        permission: 'activityEvaluation-status'
      }, {
        type: 'delete',
        label: '删除',
        permission: 'activityEvaluation-delete'
      });
    } else {
      arr.push({
        type: 'stop',
        label: '停用',
        permission: 'activityEvaluation-status'
      });
    }

    return arr;
  }
}];
export var TABLE_CONFIG = {
  isSelect: true,
  actions: [{
    label: '新增模板',
    type: 'add',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'activityEvaluation-edit'
  }]
};