import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var PUBLIC_CONFIG = {
  labelWidth: 100
}; // 弹窗中form表单配置

export var DIALOG_FORM_CONFIG = {
  id: {
    hidden: true
  },
  // 类别名称
  classifyName: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '分类名称',
    props: {
      placeholder: '请输入分类名称'
    },
    rules: [{
      required: true,
      message: '分类名称不能为空',
      trigger: 'blur'
    }]
  }),
  sort: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'inputNumber',
    label: '排序',
    props: {
      max: 999
    }
  }),
  status: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'radio',
    label: '状态',
    options: [{
      'label': '启用',
      'value': 1
    }, {
      'label': '停用',
      'value': 0
    }]
  })
};
export var DIALOG_FORM_DATA = {
  id: null,
  classifyName: '',
  sort: '',
  status: 1
};