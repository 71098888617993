//推介费申请状态
export default {
  WAIT_PLAN: {
    value: '1',
    text: '待计划'
  },
  ORG_SET: {
    value: '2',
    text: '待分配'
  },
  COUNT_AUDIT: {
    value: '3',
    text: '待审核'
  },
  COUNT_AUDIT_FAIL: {
    value: '4',
    text: '分配不通过'
  },
  END: {
    value: '5',
    text: '完成'
  },
  FINANCE_AUDIT_FAIL: {
    value: '6',
    text: '审核不通过'
  },
  FINANCE_AUDIT_COUNT_FAIL: {
    value: '7',
    text: '审核分配不通过'
  },
  MONEY_AUDIT: {
    value: '8',
    text: '待支付'
  },
  OLD_INIT: {
    value: '9',
    text: '旧数据初始化'
  },
  // 旧数据初始化
  MONEY_AUDIT_FAIL: {
    value: '10',
    text: '支付不通过'
  },
  // 支付不通过
  DELETE: {
    value: '-99',
    text: '已删除'
  } // 逻辑删除

};