import _slicedToArray from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.entries.js";
import "core-js/modules/es.array.map.js";
import cookies from './util.cookies';
import db from './util.db';
import log from './util.log';
import calc from './util.calc';
import validate from './util.validate';
import dataType from './util.type';
var util = {
  cookies: cookies,
  db: db,
  log: log,
  calc: calc,
  validate: validate,
  dataType: dataType
};
/**
 * @description 更新标题
 * @param {String} title 标题
 */

util.title = function (titleText) {
  var processTitle = process.env.VUE_APP_TITLE || 'D2Admin';
  window.document.title = "".concat(processTitle).concat(titleText ? " | ".concat(titleText) : '');
};
/**
 * @description 打开新页面
 * @param {String} url 地址
 */


util.open = function (url) {
  var a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('target', '_blank');
  a.setAttribute('id', 'd2admin-link-temp');
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(document.getElementById('d2admin-link-temp'));
}; // TODO 函数节流


util.throttle = function (fn) {
  var gapTime = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1000;
  var _lastTime = null; // 触发前点击的时间
  // 返回新的函数

  return function () {
    var _nowTime = +new Date();

    if (_nowTime - _lastTime > gapTime || !_lastTime) {
      fn.apply(this, arguments); //将this和参数传给原函数

      _lastTime = _nowTime;
    }
  };
}; // TODO 下载


util.download = function (content, fileName) {
  var type = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'blob';
  var blob = new Blob([content]);
  var elink = document.createElement('a');
  elink.download = fileName;
  elink.style.display = 'none';
  elink.href = URL.createObjectURL(blob);
  document.body.appendChild(elink);
  elink.click();
  document.body.removeChild(elink);
}; // TODO 深拷贝，传入拷贝对象


function deepClone(target) {
  var type = Object.prototype.toString.call(target);
  var clone = target;

  switch (type) {
    // 对象类型
    case '[object Object]':
      clone = {};
      Object.entries(target).forEach(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            key = _ref2[0],
            value = _ref2[1];

        clone[key] = deepClone(value);
      });
      break;
    // 数组类型

    case '[object Array]':
      clone = [];
      clone = target.map(function (item) {
        return deepClone(item);
      });
      break;

    default:
      break;
  }

  return clone;
}

util.deepClone = deepClone;
export default util;