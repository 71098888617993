//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from '@/mixins/dialog';
import { listBase } from '@api/crm/buy/buy'; //合同

import STATUS from '@/maps/enum/status/buy';
export default {
  name: 'search-contract',
  components: {},
  props: {
    initSchWord: {
      type: String,
      default: ''
    },
    isState: {
      type: String,
      default: ''
    }
  },
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      tableData: [],
      model: {
        contractCd: '',
        // 合同编号
        customerName: '' // 客户姓名

      },
      selectItem: {} //选择的项

    };
  },
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
      this.model = {
        contractCd: '',
        // 合同编号
        customerName: '' // 客户姓名

      };
      this.selectItem = {};
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {// this.$refs.form.resetFields();
    },
    // TODO 搜索
    clickSearch: function clickSearch() {
      var _this = this;

      if (this.model.contractCd == '' && this.model.customerName == '') {
        this.$message('请填写客户或者合同号');
      } else {
        var param = {
          query: {
            contractCd: this.model.contractCd,
            customerName: this.model.customerName,
            isVvip: this.model.isVvip
          }
        };
        var array = []; // 合同状态

        for (var key in STATUS) {
          // 选择状态
          if (this.isState) {
            if (STATUS[key].value === this.isState) {
              array.push(STATUS[key].value);
            }
          } else {
            if (STATUS[key].value !== '-99') {
              array.push(STATUS[key].value);
            }
          }
        }

        param.query.arrayBuyState = array;
        this.loading = true;
        listBase(param).then(function (res) {
          _this.loading = false;
          _this.tableData = res.data;
        }).catch(function (e) {
          _this.loading = false;
        });
      }
    },
    clickSelectItem: function clickSelectItem(index, row) {
      this.showDialog = false;
      this.selectItem = row;
      this.$emit('onSelectItem', this.selectItem); // 调用父组件方法，将结果传回。参数1：父组件方法名，参数2：传回参数
    }
  }
};