import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import DynamicTable from '@/components/common/dynamic-table';
import { OrgSuperiorTableList } from '../option/list'; // 表头

import { orgSuperiorRemove, orgSuperiorList } from '@api/rm/org';
import OrgSuperiorAdd from './orgSuperior-add';
export default {
  name: 'OrgSuperior',
  components: {
    DynamicTable: DynamicTable,
    OrgSuperiorAdd: OrgSuperiorAdd
  },
  props: {
    org: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    // 是否为编辑
    isEdit: {
      type: Boolean,
      default: false
    },
    loading: Boolean,
    showDialog: Boolean
  },
  data: function data() {
    return {
      headers: OrgSuperiorTableList,
      table: {},
      pagination: {},
      tableData: [],
      query: {},
      currentRow: {},
      // 当前点击行
      OrgHeadAddShowAddDialog: false,
      // 展示新增/编辑弹窗
      OrgHeadAddIsEdit: false // 是否为编辑

    };
  },
  created: function created() {},
  computed: {},
  watch: {},
  methods: {
    // 初始化搜索条件
    initFilter: function initFilter() {
      this.query = {
        orgCd: this.org.orgCd
      };
    },
    // 获取列表
    loadData: function loadData(_ref) {
      var _this = this;

      var _ref$page = _ref.page,
          page = _ref$page === void 0 ? {} : _ref$page;
      this.initFilter();
      var param = {
        page: page,
        query: this.query
      };
      this.$emit('update:loading', true);
      orgSuperiorList(param).then(function (res) {
        _this.$emit('update:loading', false);

        _this.tableData = [];

        if (res.data != null) {
          var _res$data = res.data,
              data = _res$data === void 0 ? [] : _res$data,
              _page = res.page;
          var _page$totalCount = _page.totalCount,
              totalCount = _page$totalCount === void 0 ? 0 : _page$totalCount;
          _this.tableData = data.map(function (item) {
            return _objectSpread(_objectSpread({}, item), {}, {
              isCurHeadText: item.isCurHead === 1 ? '是' : '否'
            });
          });
          _this.pagination = {
            total: totalCount
          };
        } else {
          _this.$message({
            message: '暂无数据',
            type: 'info'
          });
        }
      }).catch(function (e) {
        _this.$emit('update:loading', false);

        _this.$message({
          message: '查询列表数据出错',
          type: 'error'
        });
      });
    },
    // 选中行
    selectRow: function selectRow(row) {
      this.currentRow = row;
    },
    // 点击新增
    clickAdd: function clickAdd() {
      var row = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.OrgHeadAddIsEdit = false;
      this.OrgHeadAddShowAddDialog = true;
      this.currentRow = row;
    },
    // 点击编辑
    clickEdit: function clickEdit() {
      var row = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.OrgHeadAddIsEdit = true;
      this.OrgHeadAddShowAddDialog = true;
      this.currentRow = row;
    },
    // 点击删除
    remove: function remove() {
      var _this2 = this;

      var row = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var param = {
        recId: row.recId
      };
      this.$confirm('确定删除该数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        orgSuperiorRemove(param).then(function (res) {
          _this2.$message({
            type: 'success',
            message: '删除成功!'
          });

          _this2.$refs.jTable.pageChange(1);
        });
      });
    },
    // 新增/编辑弹窗确认
    confirm: function confirm() {
      this.$refs.jTable.pageChange(1);
    },

    /** 确定事件 */
    clickSave: function clickSave() {
      this.$emit('refresh');
    }
  }
};