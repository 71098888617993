var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        { name: "dialogDrag", rawName: "v-dialogDrag" },
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "dialog-info",
      attrs: {
        "custom-class": _vm.attachmentOpened,
        top: "10vh",
        width: "800px",
        title: "信息",
        visible: _vm.showDialog
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        },
        close: _vm.dialogClose
      }
    },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.tabActive,
            callback: function($$v) {
              _vm.tabActive = $$v
            },
            expression: "tabActive"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "合同信息", name: "tabContract" } },
            [
              _c(
                "el-collapse",
                {
                  model: {
                    value: _vm.collapseActive,
                    callback: function($$v) {
                      _vm.collapseActive = $$v
                    },
                    expression: "collapseActive"
                  }
                },
                [
                  _c(
                    "el-collapse-item",
                    { attrs: { title: "合同信息", name: "1" } },
                    [
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v(_vm._s(_vm.labelContractCd) + ":")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.contractCd) + " ")
                          ]),
                          _vm.isHaveContractBuyCd
                            ? _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v(_vm._s(_vm.labelBuyContractCd) + ":")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.model.buyContractCd) + " "
                                )
                              ])
                            : _vm._e(),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("状态:")
                            ]),
                            _vm._v(
                              " " + _vm._s(_vm.model.contractStateText) + " "
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 7 } },
                            [
                              _c("span", { staticClass: "d2-fwb" }, [
                                _vm._v("姓名:")
                              ]),
                              this.model.isFirstContract == "1"
                                ? _c(
                                    "el-badge",
                                    {
                                      staticClass: "badgeNewClass",
                                      attrs: { value: "new", type: "success" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "d2-emphasis" },
                                        [_vm._v(_vm._s(_vm.model.customerName))]
                                      )
                                    ]
                                  )
                                : _c("span", { staticClass: "d2-emphasis" }, [
                                    _vm._v(_vm._s(_vm.model.customerName))
                                  ])
                            ],
                            1
                          ),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("证件类型:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.idTypeText) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("证件号码:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.identification) + " ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("客户编号:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.customerCode) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("性别:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.sexText) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("生日:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.birthday) + " ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("email:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.email) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("联系方式:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.mobile) + " ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 24 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("住址:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.address) + " ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("机构:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.orgName) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("顾问:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.userName) + " ")
                          ]),
                          _c(
                            "el-col",
                            { attrs: { span: 7 } },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "d2-fwb",
                                  staticStyle: { "margin-right": "10px" }
                                },
                                [_vm._v("是否VVIP合同:")]
                              ),
                              _vm.isCompile
                                ? _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.formModel.isVvip,
                                        callback: function($$v) {
                                          _vm.$set(_vm.formModel, "isVvip", $$v)
                                        },
                                        expression: "formModel.isVvip"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        { attrs: { label: "1" } },
                                        [_vm._v("是")]
                                      ),
                                      _c(
                                        "el-radio",
                                        { attrs: { label: "0" } },
                                        [_vm._v("否")]
                                      )
                                    ],
                                    1
                                  )
                                : _c("span", [
                                    _vm._v(_vm._s(_vm.model.isVvipText))
                                  ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("el-divider"),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("产品:")
                            ]),
                            _c("span", { staticClass: "d2-emphasis" }, [
                              _vm._v(_vm._s(_vm.model.productName))
                            ])
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("合同金额:")
                            ]),
                            _c("span", { staticClass: "d2-emphasis" }, [
                              _vm._v(_vm._s(_vm.model.amount))
                            ])
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("实收金额:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.receiveAmount) + " ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("期限(月):")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.timeLimit) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("返还周期(月):")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.returnPeriod) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("年化入金:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.yearAmount) + " ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("开始日期:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.startDate) + " "),
                            this.model.leftDay > 0
                              ? _c("span", [
                                  _vm._v(
                                    "(存续:" + _vm._s(_vm.model.holdDay) + "天)"
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("到期日期:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.endDate) + " "),
                            this.model.leftDay > 0
                              ? _c("span", [
                                  _vm._v(
                                    "(到期:" + _vm._s(_vm.model.leftDay) + "天)"
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("收款方式:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.payMethodText) + " ")
                          ])
                        ],
                        1
                      ),
                      _vm.isHaveAnnualRate
                        ? _c(
                            "el-row",
                            { staticClass: "block-col-3" },
                            [
                              _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("收益率(%):")
                                ]),
                                _c("span", { staticClass: "d2-emphasis" }, [
                                  _vm._v(_vm._s(_vm.model.annualRate))
                                ])
                              ]),
                              _vm.model.floatAnnualRateTo > 0
                                ? _c("el-col", { attrs: { span: 7 } }, [
                                    _c("span", { staticClass: "d2-fwb" }, [
                                      _vm._v("浮动(%):")
                                    ]),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.model.floatAnnualRateFrom) +
                                        " ~ " +
                                        _vm._s(_vm.model.floatAnnualRateTo) +
                                        " "
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.isProductCategorySM
                        ? _c(
                            "el-row",
                            { staticClass: "block-col-3" },
                            [
                              _c(
                                "el-col",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isProduct1004,
                                      expression: "isProduct1004"
                                    }
                                  ],
                                  attrs: { span: 7 }
                                },
                                [
                                  _c("span", { staticClass: "d2-fwb" }, [
                                    _vm._v("积存方式:")
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(_vm.model.saveMethodText) + " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _vm.isHaveExpectEarnings
                            ? _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v(
                                    _vm._s(_vm.model.extLabelExpectEarnings) +
                                      ":"
                                  )
                                ]),
                                _c("span", { staticClass: "d2-emphasis" }, [
                                  _vm._v(_vm._s(_vm.model.extExpectEarnings))
                                ])
                              ])
                            : _vm._e(),
                          _vm.isHaveGoldWeight
                            ? _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("金价:")
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.model.contractGoldPrice) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          _vm.isHaveGoldWeight
                            ? _c(
                                "el-col",
                                { attrs: { span: 10 } },
                                [
                                  _c("span", { staticClass: "d2-fwb" }, [
                                    _vm._v("克重:")
                                  ]),
                                  _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        placement: "right",
                                        width: "650",
                                        trigger: "click"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: {
                                            slot: "reference",
                                            type: "warning"
                                          },
                                          slot: "reference"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.model.contractGoldWeight)
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm.isHaveActualEndDate
                        ? _c(
                            "el-row",
                            { staticClass: "block-col-3" },
                            [
                              _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("结算日期:")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.model.actualEndDate) + " "
                                )
                              ]),
                              _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v(
                                    _vm._s(_vm.model.extLabelActualEarnings) +
                                      ":"
                                  )
                                ]),
                                _c("span", { staticClass: "d2-emphasis" }, [
                                  _vm._v(_vm._s(_vm.model.extActualEarnings))
                                ])
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.model.rentRansomType != null &&
                      _vm.model.rentRansomType != ""
                        ? _c(
                            "el-row",
                            { staticClass: "block-col-3" },
                            [
                              _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("赎回方式:")
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.model.rentRansomTypeText) +
                                    " "
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isHaveContact
                        ? _c(
                            "el-row",
                            { staticClass: "block-col-3" },
                            [
                              _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("联系人:")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.model.contact.name) + " "
                                )
                              ]),
                              _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("联系电话:")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.model.contact.mobile) + " "
                                )
                              ]),
                              _c("el-col", { attrs: { span: 10 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("联系地址:")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.model.contact.address) + " "
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isProduct1004 && this.model.gold
                        ? _c(
                            "el-row",
                            { staticClass: "block-col-3" },
                            [
                              _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("积存类型:")
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.model.gold.saveTypeText) +
                                    " "
                                )
                              ]),
                              _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("积存金价:")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.model.gold.goldPrice) + " "
                                )
                              ]),
                              _c("el-col", { attrs: { span: 10 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("扣款日:")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.model.gold.cutDate) + " "
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isProduct1004 && this.model.gold.saveType == "01"
                        ? _c(
                            "el-row",
                            { staticClass: "block-col-3" },
                            [
                              _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("月积存量:")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.model.gold.monthWeight) + " "
                                )
                              ]),
                              _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("总积存克重:")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.model.gold.totalWeight) + " "
                                )
                              ]),
                              _c("el-col", { attrs: { span: 10 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("预期赠送量:")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.model.gold.giftWeight) + " "
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isProduct1004 && this.model.gold.saveType == "02"
                        ? _c(
                            "el-row",
                            { staticClass: "block-col-3" },
                            [
                              _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("月积存额:")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.model.gold.monthAmount) + " "
                                )
                              ]),
                              _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("总积存金额:")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.model.gold.totalAmount) + " "
                                )
                              ]),
                              _c("el-col", { attrs: { span: 10 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("预期赠送金额:")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.model.gold.giftAmount) + " "
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("el-divider"),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("账户类型:")
                            ]),
                            _vm._v(
                              " " + _vm._s(_vm.model.accountTypeText) + " "
                            )
                          ]),
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("银行户名:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.accountName) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("银行账号:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.accountNo) + " ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-1" },
                        [
                          _c("el-col", { attrs: { span: 24 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("银行+分行+支行:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.bankName) + " ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-2" },
                        [
                          _c("el-col", { attrs: { span: 14 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("合同备注:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.remark) + " ")
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("业绩归属日:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.bussinessDate) + " ")
                          ])
                        ],
                        1
                      ),
                      _vm.model.changeDesc != null && _vm.model.changeDesc != ""
                        ? _c(
                            "el-row",
                            { staticClass: "block-col-2" },
                            [
                              _c("el-col", { attrs: { span: 14 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("变更内容:")
                                ]),
                                _vm._v(" " + _vm._s(_vm.model.changeDesc) + " ")
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-divider",
                        { attrs: { "content-position": "left" } },
                        [_vm._v("其他")]
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-1" },
                        [
                          _c("el-col", { attrs: { span: 24 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("银行卡较验:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.bankCard3cDesc) + " ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-1" },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 7 } },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "d2-fwb",
                                  staticStyle: { "margin-right": "10px" }
                                },
                                [_vm._v("是否换人续约:")]
                              ),
                              _vm.isCompile
                                ? _c(
                                    "el-select",
                                    {
                                      staticStyle: {
                                        height: "25px",
                                        width: "50%"
                                      },
                                      attrs: {
                                        clearable: "",
                                        placeholder: "请选择"
                                      },
                                      model: {
                                        value: _vm.formModel.originType,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formModel,
                                            "originType",
                                            $$v
                                          )
                                        },
                                        expression: "formModel.originType"
                                      }
                                    },
                                    _vm._l(_vm.selectData.originType, function(
                                      item
                                    ) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.text,
                                          value: item.value
                                        }
                                      })
                                    }),
                                    1
                                  )
                                : _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.model.originType === "2"
                                            ? "是"
                                            : "否"
                                        ) +
                                        " "
                                    )
                                  ])
                            ],
                            1
                          ),
                          _vm.formModel.originType === "2"
                            ? _c(
                                "el-col",
                                { attrs: { span: 7 } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "d2-fwb",
                                      staticStyle: { "margin-right": "10px" }
                                    },
                                    [_vm._v("原客户姓名:")]
                                  ),
                                  _vm.isCompile
                                    ? _c(
                                        "el-input",
                                        {
                                          staticStyle: {
                                            height: "25px",
                                            width: "60%"
                                          },
                                          attrs: { readonly: "" },
                                          model: {
                                            value:
                                              _vm.formModel.originCustomerName,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.formModel,
                                                "originCustomerName",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formModel.originCustomerName"
                                          }
                                        },
                                        [
                                          _c(
                                            "template",
                                            { slot: "append" },
                                            [
                                              _c(
                                                "el-popover",
                                                {
                                                  attrs: {
                                                    placement: "right",
                                                    width: "780",
                                                    trigger: "click"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.showOriginCustomerName,
                                                    callback: function($$v) {
                                                      _vm.showOriginCustomerName = $$v
                                                    },
                                                    expression:
                                                      "showOriginCustomerName"
                                                  }
                                                },
                                                [
                                                  _c("search-customer", {
                                                    ref: "searchCustomer",
                                                    attrs: {
                                                      "sch-word":
                                                        _vm.formModel
                                                          .originCustomerName
                                                    },
                                                    on: {
                                                      onSelectItem:
                                                        _vm.popSelectOriginCustomerName
                                                    }
                                                  }),
                                                  _c("el-button", {
                                                    attrs: {
                                                      slot: "reference",
                                                      type: "primary",
                                                      icon: "el-icon-zoom-in",
                                                      plain: ""
                                                    },
                                                    slot: "reference"
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        2
                                      )
                                    : _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.model.originCustomerName
                                            ) +
                                            " "
                                        )
                                      ])
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-col",
                            { attrs: { span: 7 } },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "d2-fwb",
                                  staticStyle: { "margin-right": "10px" }
                                },
                                [_vm._v("是否统计业绩:")]
                              ),
                              _vm.isCompile
                                ? _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.formModel.isPerformance,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formModel,
                                            "isPerformance",
                                            $$v
                                          )
                                        },
                                        expression: "formModel.isPerformance"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        { attrs: { label: "1" } },
                                        [_vm._v("是")]
                                      ),
                                      _c(
                                        "el-radio",
                                        { attrs: { label: "0" } },
                                        [_vm._v("否")]
                                      )
                                    ],
                                    1
                                  )
                                : _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.model.isPerformanceText) +
                                        " "
                                    )
                                  ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _vm.model.financeConfirmDate != null
                            ? _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("财务收款确认:")
                                ]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.model.financeConfirmDate) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          _vm.model.moneyConfirmDate != null
                            ? _c("el-col", { attrs: { span: 7 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("资金收款确认:")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.model.moneyConfirmDate) + " "
                                )
                              ])
                            : _vm._e(),
                          _vm.model.bankSerialNumber != null
                            ? _c("el-col", { attrs: { span: 10 } }, [
                                _c("span", { staticClass: "d2-fwb" }, [
                                  _vm._v("流水号:")
                                ]),
                                _vm._v(
                                  " " + _vm._s(_vm.model.bankSerialNumber) + " "
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticClass: "block-col-3" },
                        [
                          _c("el-col", { attrs: { span: 7 } }, [
                            _c("span", { staticClass: "d2-fwb" }, [
                              _vm._v("总监团队:")
                            ]),
                            _vm._v(" " + _vm._s(_vm.model.teamName) + " ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: "buy-serviceAccountStatement",
                              expression: "'buy-serviceAccountStatement'"
                            }
                          ]
                        },
                        [
                          _c(
                            "el-divider",
                            { attrs: { "content-position": "left" } },
                            [_vm._v("银行流水")]
                          ),
                          this.accountStatementList.length > 0
                            ? [
                                _c(
                                  "div",
                                  { staticStyle: { "margin-bottom": "10px" } },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          icon: _vm.isUnfoldAccountStatement
                                            ? "el-icon-arrow-up"
                                            : "el-icon-arrow-down",
                                          size: "mini"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.isUnfoldAccountStatement = !_vm.isUnfoldAccountStatement
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.isUnfoldAccountStatement
                                              ? "收起"
                                              : "展开"
                                          )
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _vm._l(_vm.accountStatementList, function(
                                  item,
                                  index
                                ) {
                                  return [
                                    _vm.isUnfoldAccountStatement
                                      ? _c(
                                          "div",
                                          {
                                            key: index,
                                            staticStyle: {
                                              "margin-bottom": "10px"
                                            }
                                          },
                                          [
                                            _c(
                                              "el-row",
                                              { staticClass: "block-col-1" },
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 7 } },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "d2-fwb" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.type === "1"
                                                              ? "转账流水号"
                                                              : "pos参考号"
                                                          ) + ":"
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.type === "1"
                                                            ? item.waterNum
                                                            : item.systemNumber
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 7 } },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "d2-fwb" },
                                                      [_vm._v("收款日期:")]
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.tallyDate) +
                                                        " "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 10 } },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "d2-fwb" },
                                                      [_vm._v("实收金额:")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "d2-emphasis",
                                                        staticStyle: {
                                                          "margin-right": "10px"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.debtor)
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-row",
                                              { staticClass: "block-col-1" },
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 7 } },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "d2-fwb" },
                                                      [_vm._v("终端号:")]
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.endNumber) +
                                                        " "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 7 } },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "d2-fwb" },
                                                      [_vm._v("银行账号:")]
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.bankAccount
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 10 } },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "d2-fwb" },
                                                      [_vm._v("客户姓名:")]
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          item.accountName
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-row",
                                              { staticClass: "block-col-1" },
                                              [
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 7 } },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "d2-fwb" },
                                                      [_vm._v("收款银行/公司:")]
                                                    ),
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.kindText) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                }),
                                _c(
                                  "el-row",
                                  { staticClass: "block-col-1" },
                                  [
                                    _c("el-col", { attrs: { span: 7 } }, [
                                      _c("span", { staticClass: "d2-fwb" }, [
                                        _vm._v("银行流水合计:")
                                      ]),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "d2-emphasis",
                                          staticStyle: {
                                            "margin-right": "10px"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.sumDebtorAmount.toFixed(2)
                                            )
                                          )
                                        ]
                                      ),
                                      _vm.sumDebtorAmount ==
                                      _vm.model.receiveAmount
                                        ? _c("i", {
                                            staticClass: "el-icon-check"
                                          })
                                        : _vm._e()
                                    ]),
                                    _vm.sumdiFferenceAmount
                                      ? _c("el-col", { attrs: { span: 7 } }, [
                                          _c(
                                            "span",
                                            { staticClass: "d2-fwb" },
                                            [_vm._v("差异金额:")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "d2-emphasis",
                                              staticStyle: {
                                                "margin-right": "10px"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.sumdiFferenceAmount
                                                  )
                                              )
                                            ]
                                          )
                                        ])
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            : _c("div", [_vm._v("暂无数据")])
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "附件", name: "tabAttachment" } },
            [
              _c("attachment", {
                ref: "componentAttachment",
                attrs: {
                  "is-attachment-edit": false,
                  "attachment-contract-id": _vm.propsModel.contractId,
                  "attachment-type": _vm.attachmentType
                },
                on: { onAttachmentOpen: _vm.onAttachmentOpen }
              })
            ],
            1
          ),
          _vm.isProductCategorySM
            ? _c(
                "el-tab-pane",
                { attrs: { label: "录音", name: "tabAttachmentAudio" } },
                [
                  _c("attachment-audio", {
                    ref: "componentAttachmentAudio",
                    attrs: {
                      "is-attachment-edit": false,
                      "attachment-contract-id": _vm.propsModel.contractId,
                      "attachment-type": _vm.attachmentTypeAudio
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.propsModel.productCategory2 === "20"
            ? _c(
                "el-tab-pane",
                { attrs: { label: "视频", name: "tabVideo" } },
                [
                  _c("AttachmentVideo", {
                    attrs: {
                      isAttachmentEdit: false,
                      attachmentContractId: _vm.propsModel.contractId,
                      attachmentType: _vm.attachmentVideoType
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-tab-pane",
            {
              attrs: { label: "回单凭证", name: "tabAttachmentReceptionBill" }
            },
            [
              _c("attachment", {
                ref: "componentAttachmentReceptionBill",
                attrs: {
                  "is-attachment-edit": false,
                  "attachment-contract-id": _vm.propsModel.contractId,
                  "attachment-type": _vm.attachmentTypeReceptionBill
                },
                on: { onAttachmentOpen: _vm.onAttachmentOpen }
              })
            ],
            1
          ),
          _vm.model.contractId != ""
            ? _c(
                "el-tab-pane",
                { attrs: { label: "变更资料", name: "tabAttachmentChange" } },
                [
                  _c("attachment", {
                    ref: "componentAttachment",
                    attrs: {
                      "is-attachment-edit": false,
                      "attachment-contract-id": _vm.model.contractId,
                      "attachment-ext-id": _vm.model.changeApplyId,
                      "attachment-type": _vm.attachmentTypeChange
                    },
                    on: { onAttachmentOpen: _vm.onAttachmentOpen }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-tab-pane",
            { attrs: { label: "责任状业绩", name: "tabConzrz" } },
            [
              _vm.tabActive === "tabConzrz"
                ? _c(
                    "Conzrz",
                    _vm._b(
                      {
                        attrs: {
                          attachmentContractId: _vm.propsModel.contractId
                        }
                      },
                      "Conzrz",
                      _vm.conzrzConfig,
                      false
                    )
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "基本法业绩", name: "tabConjbf" } },
            [
              _vm.tabActive === "tabConjbf"
                ? _c(
                    "Conjbf",
                    _vm._b(
                      {
                        attrs: {
                          attachmentContractId: _vm.propsModel.contractId
                        }
                      },
                      "Conjbf",
                      _vm.conjbfConfig,
                      false
                    )
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !["tabConzrz", "tabConjbf"].includes(_vm.tabActive),
              expression: "!['tabConzrz', 'tabConjbf'].includes(tabActive)"
            }
          ],
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              staticClass: "contract-log-tip",
              attrs: { plain: "", size: "mini", icon: "el-icon-finished" },
              on: {
                click: function($event) {
                  return _vm.showLog()
                }
              }
            },
            [_vm._v("审核日志")]
          ),
          _vm.isHasPermission("contract-SysLog") && _vm.isShowDelet
            ? _c(
                "el-button",
                {
                  staticClass: "button-left",
                  attrs: { plain: "", size: "mini", icon: "el-icon-user" },
                  on: {
                    click: function($event) {
                      return _vm.showLog(false)
                    }
                  }
                },
                [_vm._v("操作日志")]
              )
            : _vm._e(),
          _vm.isCompile
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "", size: "mini" },
                  on: { click: _vm.clickSave }
                },
                [_vm._v("暂存")]
              )
            : _vm._e(),
          _vm.isHasPermission("buy-compile") && _vm.isShowCompile
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "", size: "mini" },
                  on: { click: _vm.clickCompile }
                },
                [_vm._v("编辑")]
              )
            : _vm._e(),
          _vm.isHasPermission("buy-delete") &&
          _vm.isShowDelet &&
          _vm.propsModel.buyState !== "-99"
            ? _c(
                "el-button",
                {
                  attrs: {
                    type: "danger",
                    plain: "",
                    size: "mini",
                    icon: "el-icon-delete"
                  },
                  on: {
                    click: function($event) {
                      return _vm.clickRemove()
                    }
                  }
                },
                [_vm._v("删除")]
              )
            : _vm._e(),
          _c(
            "el-button",
            { attrs: { plain: "", size: "mini" }, on: { click: _vm.cancel } },
            [_vm._v("关闭")]
          )
        ],
        1
      ),
      _c("contract-log", {
        attrs: {
          "contract-id": _vm.propsModel.contractId,
          active: _vm.contractLogType
        },
        model: {
          value: _vm.showLogDialog,
          callback: function($$v) {
            _vm.showLogDialog = $$v
          },
          expression: "showLogDialog"
        }
      }),
      _c("contract-sysLog", {
        attrs: { "contract-id": _vm.propsModel.contractId, active: "1" },
        model: {
          value: _vm.showLogSysLogDialog,
          callback: function($$v) {
            _vm.showLogSysLogDialog = $$v
          },
          expression: "showLogSysLogDialog"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }