import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getEnumList } from '@api/common/common';
import CRMENUM from '@/maps/enum/dingTalkCrm';
import dialogMixin from '@/mixins/dialog';
import { getOrgTree, orgSuperiorAdd, orgSuperiorEdit, orgSuperiorGetInfo } from '@api/rm/org'; // import { listRight as personList } from '@api/rm/person';
// import { formatDate } from '@/libs/crm/format'

export default {
  name: 'OrgSuperiorAdd',
  props: {
    /** 选中部门 */
    org: {
      type: Object,
      default: function _default() {
        return {};
      }
    },

    /** 选中归属部门 */
    orgSuperior: {
      type: Object,
      default: function _default() {
        return {};
      }
    },

    /** 是否为编辑 */
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    var _this = this;

    return {
      model: {},
      // 表单值
      formModel: {},
      rules: {
        statisticalType: [{
          required: true,
          message: '请填写统计类型',
          trigger: 'select'
        }],
        belongType: [{
          required: true,
          message: '请填写统计归属部门类型',
          trigger: 'select'
        }],
        superiorOrgCd: {
          validator: function validator(rule, value, callback) {
            // 不能用自带的required校验 因为内部校验规则监听不到值
            if (!_this.formModel.superiorOrgCd) {
              callback(new Error('请填写统计归属部门'));
            } else {
              callback();
            }
          },
          trigger: 'blur'
        },
        beginTime: [{
          required: true,
          message: '请填写统计归属开始日期',
          trigger: 'date'
        }],
        endTime: [{
          required: true,
          message: '请填写统计归属结束日期',
          trigger: 'date'
        }]
      },
      loading: false,
      selectData: {
        person: [],
        statisticalTypeList: [],
        // 统计类型
        belongTypeList: [],
        // 统计归属部门类型
        arrayOrg: [],
        // 机构列表
        superiorOrgCdList: [] // 机构列表 特殊分组

      }
    };
  },
  created: function created() {
    var _this2 = this;

    // 统计类型
    getEnumList({
      enumCd: CRMENUM.ORGSTATISTICALTYPE
    }).then(function (res) {
      _this2.selectData.statisticalTypeList = res;
    }); // 统计归属部门类型

    getEnumList({
      enumCd: CRMENUM.PERFORMANCETYPE2
    }).then(function (res) {
      _this2.selectData.belongTypeList = res;
    }); // 机构列表

    getOrgTree().then(function (res) {
      var array = [];
      array[0] = res.data;
      _this2.selectData.arrayOrg = array;
    });
    this.selectData.superiorOrgCdList = [{
      orgCd: '010001',
      orgName: '直营-其他'
    }];
  },
  computed: {
    // 弹窗展示的标题
    title: function title() {
      return this.isEdit ? '修改' : '新增';
    }
  },
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();

          if (this.isEdit) {
            this.getInfo();
          } else {
            this.formModel = {
              recId: null,
              // 记录id
              recStatus: 1,
              // 记录状态
              orgCd: this.org.orgCd,
              orgName: this.org.orgName,
              joinSuperActualDate: this.org.joinSuperActualDate,
              // 加入上级部门日期_实际
              leaveSuperActualDate: this.org.leaveSuperActualDate,
              // 离开上级部门日期_实际
              statisticalType: '1',
              // 统计类型 1-包含本部门 2-剔除本部门
              belongType: '2',
              // 统计归属部门类型 2-现有其他部门  3-特殊分组
              beginTime: this.org.joinSuperActualDate,
              // 统计归属开始日期 生效日期
              endTime: this.org.leaveSuperActualDate // 统计归属结束日期 失效日期

            };
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
    },
    // 获取详情
    getInfo: function getInfo() {
      var _this3 = this;

      var param = {
        recId: this.orgSuperior.recId
      };
      orgSuperiorGetInfo(param).then(function (res) {
        var _data$statisticalType, _data$belongType;

        var _res$data = res.data,
            data = _res$data === void 0 ? {} : _res$data;
        _this3.formModel = data;
        _this3.formModel.statisticalType = String((_data$statisticalType = data.statisticalType) !== null && _data$statisticalType !== void 0 ? _data$statisticalType : '');
        _this3.formModel.belongType = String((_data$belongType = data.belongType) !== null && _data$belongType !== void 0 ? _data$belongType : '');
      });
    },
    //  确定
    clickSave: function clickSave(done) {
      var _this4 = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          var method = _this4.isEdit ? orgSuperiorEdit : orgSuperiorAdd;
          _this4.loading = true;
          var param = {
            object: _objectSpread({}, _this4.formModel)
          };
          method(param).then(function (res) {
            _this4.showDialog = false;

            _this4.$emit('confirm');
          }).catch(function (e) {
            _this4.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    // 统计归属部门类型
    belongTypeChange: function belongTypeChange(e) {
      if (e === '3') {
        this.formModel.superiorOrgCd = this.selectData.superiorOrgCdList[0].orgCd;
        this.formModel.superiorOrgName = this.selectData.superiorOrgCdList[0].orgName;
      } else this.formModel.superiorOrgCd = null;
    },
    // 统计归属部门
    superiorOrgCdChange: function superiorOrgCdChange(e) {
      var _this5 = this;

      var getItem = function getItem(arr) {
        arr.find(function (item) {
          if (item.orgCd === e) {
            _this5.formModel.superiorOrgCd = item.orgCd || null;
            _this5.formModel.superiorOrgName = item.orgName || null;
          } else if (item.children && item.children.length > 0) {
            getItem(item.children);
          }
        });
      };

      if (!e) {
        this.superiorOrgCd = null;
        this.superiorOrgName = null;
      } else {
        getItem(this.selectData.arrayOrg);
      }
    }
  }
};