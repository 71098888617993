//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from '@/mixins/dialog';
import crmMixin from '@/mixins/crm';
import LOG_TYPE from '@/maps/enum/contract-log-type';
import ATTACHMENT_TYPE from '@/maps/enum/attachment-type';
import AUDIT from '@/maps/enum/status/audit';
import DATA_FORMAT_TYPE from '@/maps/enum/data-format-type';
import Attachment from '@/components/business/attachment';
import ContractGoldWeight from '@/components/business/contract-gold-weight';
import ContractLog from '@/components/business/contract-log';
import { setExtendAttribute } from '@/libs/crm/crm';
import { getInfo as _getInfo, auditCount } from '@api/crm/count/count';
export default {
  name: 'count-count-audit',
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {
    Attachment: Attachment,
    ContractLog: ContractLog,
    ContractGoldWeight: ContractGoldWeight
  },
  mixins: [dialogMixin, crmMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      tabActive: 'tabContract',
      //标签框默认选中
      collapseActive: ['1', '2'],
      //信息折叠默认展开
      attachmentType: ATTACHMENT_TYPE.CONTRACT_STAFF,
      //附件类型
      attachmentTypeReceptionBill: ATTACHMENT_TYPE.RECEPTION_BILL,
      //附件类型：回单凭证
      attachmentTypeCount: ATTACHMENT_TYPE.APPLY_FGSKF_SUBMIT_STAFF,
      //附件类型：核算申请
      showLogDialog: false,
      contractLogType: LOG_TYPE.COUNT,
      attachmentOpened: '',
      //附件打开状态
      formModel: {},
      //表单
      rules: {}
    };
  },
  computed: {
    isProductCountType: function isProductCountType() {
      var isShow = false; // FIXED_DATE_GOLD: { value: '2', text: '固定日到期（黄金类）' },
      // FIXED_DATE: { value: '3', text: '固定日到期（非黄金类）' } ,
      // 判断固定日

      if (this.model.productCountType == '2' || this.model.productCountType == '3') {
        isShow = true;
      }

      return isShow;
    },
    isCounterglow: function isCounterglow() {
      var isShow = false; // SAME_DATE_GOLD: { value: '1', text: '对日到期（黄金类）' },
      // 判断对日

      if (this.model.productCountType == '1') {
        isShow = true;
      }

      return isShow;
    }
  },
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.getInfo();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tabActive = 'tabContract'; //默认打开第一个标签页
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.$refs.form.resetFields();
    },
    onAttachmentOpen: function onAttachmentOpen() {
      //初始化标签
      this.tabActive = 'tabContract';
      this.attachmentOpened = 'dialog-position';
    },
    clickAuditFail: function clickAuditFail() {
      //审核不通过
      if (this.formModel.advice == null || this.formModel.advice == '') {
        this.$message('请填写审核意见');
      } else {
        this.audit(AUDIT.NO.value);
      }
    },
    clickAudit: function clickAudit() {
      //审核通过
      this.audit(AUDIT.YES.value);
    },
    // TODO 确定
    audit: function audit(result) {
      var _this = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          var param = {
            arrayCountId: [_this.model.countId],
            audit: result,
            advice: _this.formModel.advice
          };
          _this.loading = true;
          auditCount(param).then(function (res) {
            _this.loading = false;
            _this.showDialog = false;

            _this.$emit('refresh');
          }).catch(function (e) {
            _this.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    // TODO 获取详情
    getInfo: function getInfo() {
      var _this2 = this;

      var countId = this.propsModel.countId; //this.loading = true;

      _getInfo({
        countId: countId
      }, DATA_FORMAT_TYPE.USRE_SETUP).then(function (res) {
        //this.loading = false;
        var _res$data = res.data,
            data = _res$data === void 0 ? {} : _res$data;
        _this2.model = data;
        setExtendAttribute(_this2.model); //包装必要展示字段
      }).catch(function (e) {
        _this2.loading = false;
      });
    },
    showLog: function showLog() {
      //显示审核日志
      this.showLogDialog = true;
    }
  }
};