import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.join.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import api from '@/api-new';
import { cloneDeep } from 'lodash';
import { FORM_BASE } from './config/basic';
import { useSuccessTip } from '@/hooks/use-tip';
import useJSON from '@/hooks/use-json';
export default {
  name: 'PageSysConfigBasic',
  data: function data() {
    return {
      formConfig: cloneDeep(FORM_BASE),
      formModel: {
        name: '',
        logoUrl: null,
        protocolContent: '',
        module: []
      },
      footerConfig: {
        show: true,
        showCancel: false,
        confirmText: '保存'
      },
      loading: false,
      submiting: false
    };
  },
  methods: {
    /** 请求基本信息配置 */
    getDetail: function getDetail() {
      var _this = this;

      return api.GET_SYS_CONFIG_BASIC().then(function (res) {
        // let { logo, backgroundImg } = res || {};
        var _useJSON = useJSON(),
            parse = _useJSON.parse;

        _this.formModel = _objectSpread(_objectSpread({}, res), {}, {
          logoUrl: res.logoUrl ? {
            url: res === null || res === void 0 ? void 0 : res.logoUrl
          } : res.logoUrl,
          module: res.module ? res.module.split(',') : [] // logo: parse(logo),
          // backgroundImg: parse(backgroundImg),

        });
      });
    },

    /** 保存数据 */
    formSubmit: function formSubmit(model) {
      var _this2 = this;

      var _model$module = model.module,
          module = _model$module === void 0 ? [] : _model$module,
          logoUrl = model.logoUrl;

      var _useJSON2 = useJSON(),
          stringify = _useJSON2.stringify;

      var params = _objectSpread(_objectSpread({}, model), {}, {
        module: module.length > 0 ? module.join(',') : null,
        logoUrl: logoUrl ? logoUrl === null || logoUrl === void 0 ? void 0 : logoUrl.url : logoUrl
      });

      this.submiting = true;
      api.SYS_CONFIG_BASIC_UPDATE(params).then(function () {
        _this2.submiting = false;
        useSuccessTip();
      }).catch(function () {
        _this2.submiting = false;
      });
    }
  },
  created: function created() {
    this.getDetail();
  }
};