import { render, staticRenderFns } from "./audio.vue?vue&type=template&id=65dd71f9&"
import script from "./audio.vue?vue&type=script&lang=js&"
export * from "./audio.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./audio.vue?vue&type=custom&index=0&blockType=vue-filename-injector"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/data/jenkins/home/workspace/jq-front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('65dd71f9')) {
      api.createRecord('65dd71f9', component.options)
    } else {
      api.reload('65dd71f9', component.options)
    }
    module.hot.accept("./audio.vue?vue&type=template&id=65dd71f9&", function () {
      api.rerender('65dd71f9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/business/attachment/audio.vue"
export default component.exports