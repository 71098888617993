import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
export var TABLE_LABEL = [{
  label: '流水号',
  prop: 'code',
  width: 100
}, {
  label: '转化类型',
  prop: 'statusValue',
  width: 120
}, {
  label: '客户',
  prop: 'customerName',
  width: 120
}, {
  label: '客户类型',
  prop: 'newOrOld',
  width: 120,
  formatter: function formatter(row, column, cellValue) {
    var res;

    switch (cellValue) {
      case 0:
        res = '老客户';
        break;

      case 1:
        res = '新客户';
        break;
    }

    return res;
  }
}, {
  label: '产品大类',
  prop: 'productCategoryValue',
  width: 160
}, {
  label: '合同金额（万）',
  prop: 'contractAmount',
  width: 120
}, {
  label: '打款日期',
  prop: 'paymentDate',
  width: 120
}, {
  label: '提报日期',
  prop: 'createTime',
  width: 120
}, {
  label: '合同开始日期',
  prop: 'startDate',
  width: 120
}, {
  label: '合同编码',
  prop: 'contractCd',
  width: 120
}, {
  label: '理顾',
  prop: 'agentUserName',
  width: 120
}, {
  label: '部门',
  prop: 'orgName',
  width: 210
}, {
  label: '区域',
  prop: 'areaValue',
  width: 210
}, {
  label: '收款方式',
  prop: 'paymentMethodValue',
  width: 150
}, {
  type: 'slot',
  label: '上传打款凭证',
  prop: 'paymentVoucher',
  width: 400
}, {
  label: '有效状态',
  prop: 'validStatusValue',
  width: 120
}, {
  label: '作废原因',
  prop: 'reason',
  width: 150
}];
var PUBLIC_CONFIG = {
  labelWidth: 80
};
export var TOP_FORM = {
  status: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '状态',
    col: 8,
    props: {
      placeholder: '请选择状态'
    },
    options: [{
      label: '未转合同',
      value: '1'
    }, {
      label: '已转合同',
      value: '2'
    }]
  }),
  area: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '区域',
    col: 8,
    props: {
      placeholder: '请选择区域'
    },
    options: []
  }),
  validStatus: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '有效状态',
    col: 8,
    props: {
      placeholder: '请选择'
    },
    options: [{
      label: '有效',
      value: '0'
    }, {
      label: '作废',
      value: '1'
    }]
  }),
  paymentDateType: {
    labelWidth: 120,
    type: 'slot',
    col: 8,
    label: '打款日期'
  },
  createDateType: {
    labelWidth: 120,
    type: 'slot',
    col: 8,
    label: '创建日期'
  }
};
export var TABLE_CONFIG = {
  isSelect: true,
  actions: [{
    label: '导出',
    type: 'export',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'CustomerPaymentSubmission-export'
  }, {
    label: '导入',
    type: 'import',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'CustomerPaymentSubmission-import'
  }, {
    label: '转化',
    type: 'convert',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'CustomerPaymentSubmission-convert'
  }, {
    label: '作废',
    type: 'cancel',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'CustomerPaymentSubmission-cancel'
  }]
};