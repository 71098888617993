/** 变更 */
export default {
  /** 检查合同是否可以申请续约 */
  isContractCanChange: '/change/isContractCanChange',

  /** 保存 */
  save: '/change/save',

  /** 提交 */
  submit: '/change/submit',

  /** 删除 */
  remove: '/change/remove',

  /** 客服审核 */
  auditService: '/change/auditService',

  /** 结算审核 */
  auditFinance: '/change/auditFinance',

  /** 资金审核 */
  auditMoney: '/change/auditMoney',

  /** 列表 */
  list: '/change/list',

  /** 明细 */
  getInfo: '/change/getInfo',

  /** 变更历史 */
  history: '/change/history',

  /** 导出Excel */
  exportExcel: '/change/exportExcel'
};