import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var PUBLIC_CONFIG = {
  labelWidth: 100
}; // 弹窗中form表单配置

export var DIALOG_FORM_CONFIG = {
  id: {
    hidden: true
  },
  name: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '广告位名称',
    rules: [{
      required: true,
      message: '广告位不能为空',
      trigger: 'blur'
    }]
  }),
  code: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '编码',
    rules: [{
      required: true,
      message: '广告名称不能为空',
      trigger: 'blur'
    }]
  }),
  width: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '广告位宽度',
    props: {
      tips: 'px'
    }
  }),
  height: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '广告位高度',
    props: {
      tips: 'px'
    }
  }),
  describe: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '广告描述',
    props: {
      type: 'textarea',
      rows: 4,
      maxlength: 300,
      showWordLimit: true
    }
  }),
  // sort: {
  //   ...PUBLIC_CONFIG,
  //   type: 'inputNumber',
  //   label: '优先级',
  //   props: {
  //     max: 999,
  //   },
  // },
  status: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'radio',
    label: '状态',
    options: [{
      label: '启用',
      value: 1
    }, {
      label: '禁用',
      value: 0
    }]
  })
};
export var DIALOG_FORM_DATA = {
  id: null,
  code: '',
  name: '',
  describe: '',
  width: '',
  height: '',
  status: 1
};