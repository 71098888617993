import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import { add } from "@api/crm/buy/visit";
export default {
  name: "buy-visit-apply-add",
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      formModel: {},
      //表单
      selectData: {},
      rules: {
        appointDate: [{
          required: true,
          message: "请选择预约日期",
          trigger: "input"
        }]
      }
    };
  },
  computed: {},
  created: function created() {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.$set(this.formModel, "applyId", this.propsModel.id);
          this.$set(this.formModel, "contractId", this.propsModel.contractId);
          this.$set(this.formModel, "customerName", this.propsModel.customerName);
        } else {
          this.formModel = {};
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.model = {};
      this.formModel = {};
      this.selectData = {};
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.$refs.form.resetFields();
    },
    // TODO 确定
    confirm: function confirm() {
      var _this = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          var param = _objectSpread({}, _this.formModel);

          if (_this.formModel.rangTime != null) {
            param.timeStart = _this.formModel.rangTime[0];
            param.timeEnd = _this.formModel.rangTime[1];
          }

          _this.loading = true;
          add(param).then(function (res) {
            _this.loading = false;
            _this.showDialog = false;

            _this.$emit("refresh");
          }).catch(function (e) {
            _this.loading = false;
          });
        } else {
          return false;
        }
      });
    }
  }
};