













































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/change/money/index.vue"
}
