import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { TABLE_CONFIG, TABLE_LABEL, TOP_FORM } from './config/list';
import { TABLE_CONFIG1, TABLE_LABEL1, TOP_FORM1 } from './config/check';
import { DIALOG_FORM_CONFIG, DIALOG_FORM_DATA } from './config/add';
import { getHeaders } from '@/api-new/service';
import { cloneDeep } from 'lodash-es';
import api from '@/api-new';
import url from '@/api-new/url';
import { useSuccessTip } from '@/hooks/use-tip';
export default {
  name: 'CustomerList',
  data: function data() {
    return {
      selectedInfo: {
        id: '',
        name: ''
      },
      dialogCheckVisible: false,
      loading: false,
      topForm: TOP_FORM,
      topForm1: TOP_FORM1,
      filtersData: {},
      filtersData1: {},
      tableConfig: TABLE_CONFIG,
      tableLabel: TABLE_LABEL,
      tableConfig1: TABLE_CONFIG1,
      tableLabel1: TABLE_LABEL1,
      httpConfig: {},
      httpConfig1: {},

      /* 弹窗数据 */
      dialogFormData: cloneDeep(DIALOG_FORM_DATA),
      dialogFormConfig: cloneDeep(DIALOG_FORM_CONFIG),

      /* 弹窗是否显示 */
      dialogVisible: false,

      /* 弹窗标题 */
      dialogTitle: '新增广告'
    };
  },
  computed: {},
  watch: {
    'dialogFormData.linkType': {
      handler: function handler(val) {
        // 除小程序 都不需要 路径和id
        this.dialogFormConfig.appId.hidden = true;
        this.dialogFormConfig.path.hidden = true;

        if (val === 2) {
          this.dialogFormConfig.informationId.hidden = false;
          this.dialogFormConfig.imglinkUrl.hidden = true; // 图片

          this.dialogFormConfig.linkUrl.hidden = true; // 广告链接
        } else if (val === 3) {
          this.dialogFormConfig.appId.hidden = false;
          this.dialogFormConfig.path.hidden = false;
          this.dialogFormConfig.imglinkUrl.hidden = true; // 图片

          this.dialogFormConfig.linkUrl.hidden = true; // 广告链接

          this.dialogFormConfig.informationId.hidden = true; // 资讯
        } else if (val === 4) {
          this.dialogFormConfig.imglinkUrl.hidden = false;
          this.dialogFormConfig.linkUrl.hidden = true;
        } else {
          this.dialogFormConfig.imglinkUrl.hidden = true; // 图片

          this.dialogFormConfig.linkUrl.hidden = false; // 广告链接

          this.dialogFormConfig.informationId.hidden = true; // 资讯
        }
      },
      immediate: true,
      deep: true
    },
    'selectedInfo.id': {
      handler: function handler(val) {
        this.dialogFormData.informationId = val;
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    closeDialog: function closeDialog() {
      this.dialogFormData.path = null;
      this.dialogFormData.appId = null;
      this.dialogFormData.linkUrl = null;
    },
    dialogConfirmCheck: function dialogConfirmCheck() {
      this.dialogCheckVisible = false;
    },
    tableRowClick1: function tableRowClick1(row) {
      this.selectedInfo.id = row.id;
      this.selectedInfo.name = row.name;
    },
    getAdvertiseLocation: function getAdvertiseLocation() {
      var _this = this;

      var params = {
        page: {
          pageNum: 1,
          pageSize: 999
        }
      };
      api.GET_ADVERTISE_LOCATION(params).then(function (res) {
        _this.topForm.addressLocationId.options = res.list.map(function (item) {
          return {
            label: item.name,
            value: item.id
          };
        });
        _this.dialogFormConfig.addressLocationId.options = _this.topForm.addressLocationId.options;
      });
    },

    /** 接口参数过滤函数 */
    filtersFormatter: function filtersFormatter() {},

    /** 接口参数过滤函数 */
    filtersFormatter1: function filtersFormatter1(params) {
      var classifyId = params.classifyId;
      return _objectSpread(_objectSpread({}, params), {}, {
        classifyId: classifyId && classifyId.length ? classifyId[classifyId.length - 1] : null,
        releaseStatus: 1
      });
    },

    /* 弹窗确认 */
    dialogConfirm: function dialogConfirm() {
      this.$refs.form.submitForm();
    },
    dialogSubmit: function dialogSubmit() {
      var _this2 = this;

      var _this$dialogFormData = this.dialogFormData,
          pictureUrl = _this$dialogFormData.pictureUrl,
          imglinkUrl = _this$dialogFormData.imglinkUrl;

      var params = _objectSpread(_objectSpread({}, this.dialogFormData), {}, {
        pictureUrl: pictureUrl ? pictureUrl.url : '',
        informationId: this.selectedInfo.id
      });

      if (params.linkType === 4) {
        params.linkUrl = imglinkUrl ? imglinkUrl.url : '';
      }

      if (params.linkType === 3) {
        // 小程序 需要存appid 以及 路径
        params.linkUrl = JSON.stringify({
          path: this.dialogFormData.path,
          appId: this.dialogFormData.appId
        });
      }

      api.ADD_ADVERTISE_LIST(params).then(function (res) {
        useSuccessTip();
        _this2.dialogVisible = false;

        _this2.$refs.jPage.getPageData();
      });
    },
    tableRowClick: function tableRowClick(action) {
      var _ref = action || {},
          clickItem = _ref.clickItem,
          row = _ref.row;

      var _ref2 = clickItem || {},
          type = _ref2.type;

      switch (type) {
        // 处理列表审核
        case 'edit':
          this.dialogVisible = true;
          this.dialogTitle = '修改广告';
          this.getDetail(row.id);
          break;

        case 'delete':
          this.Delete(row.id);
          break;
      }
    },

    /* 处理列表删除 */
    Delete: function Delete(id) {
      var _this3 = this;

      this.$confirm('确定要删除所选广告吗？', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        api.DELETE_ADVERTISE_DETAIL({
          id: id
        }).then(function () {
          useSuccessTip();

          _this3.$refs.jPage.getPageData();
        });
      });
    },
    getDetail: function getDetail(id) {
      var _this4 = this;

      api.GET_ADVERTISE_DETAIL({
        id: id
      }).then(function (res) {
        res.pictureUrl = {
          url: res.pictureUrl
        }; // 图片 类型为4 广告链接为图片地址

        if (res.linkType === 4) {
          res.imglinkUrl = {
            url: res.linkUrl
          };
        }

        _this4.dialogFormData = res;

        if (res.linkType === 3) {
          // 小程序 需要存appid 以及 路径
          var linkUrl = JSON.parse(res.linkUrl);

          if (linkUrl) {
            _this4.dialogFormData.appId = linkUrl.appId ? linkUrl.appId : '';
            _this4.dialogFormData.path = linkUrl.path ? linkUrl.path : '';
          }

          _this4.dialogFormData.linkUrl = null;
        }

        _this4.selectedInfo.id = res.informationId;
        _this4.selectedInfo.name = res.informationName;
      });
    },

    /* 获取所属分类下拉 */
    getChooseList: function getChooseList() {
      var _this5 = this;

      api.GET_CHOOSE_LIST().then(function (res) {
        _this5.topForm1.classifyId.options = res;
      });
    },
    tableClick: function tableClick(params) {
      console.log('params: ', params);

      var _ref3 = params || {},
          clickItem = _ref3.clickItem;

      var _ref4 = clickItem || {},
          type = _ref4.type;

      if (type === 'add') {
        this.dialogTitle = '新增广告';
        this.dialogFormData = {};
        this.dialogVisible = true;
      }
    }
  },
  created: function created() {
    this.getAdvertiseLocation();
    var host = url.host,
        apiPrev = url.apiPrev;
    var apiUrl = url.advertising;
    this.httpConfig = {
      url: "".concat(host).concat(apiPrev).concat(apiUrl.getAdvertise),
      headers: getHeaders()
    };
    this.httpConfig1 = {
      url: "".concat(host).concat(apiPrev).concat(url.info.document.infoList),
      headers: getHeaders()
    };
    this.getChooseList();
  }
};