//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from '@/mixins/dialog';
import OrgInfo from './orgInfo.vue';
import OrgHead from './orgHead.vue';
import OrgSuperior from './orgSuperior.vue';
export default {
  name: 'DialogAddEdit',
  props: {
    /** 选中部门 */
    org: {
      type: Object,
      default: function _default() {
        return {};
      }
    },

    /** 是否为编辑 */
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  components: {
    OrgInfo: OrgInfo,
    OrgHead: OrgHead,
    OrgSuperior: OrgSuperior
  },
  mixins: [dialogMixin],
  data: function data() {
    return {
      tabActive: 'orgInfo',
      // 标签框默认选中
      loading: false,
      isSave: false
    };
  },
  created: function created() {},
  computed: {
    // 弹窗展示的标题
    title: function title() {
      return this.isEdit ? '修改' : '新增';
    }
  },
  watch: {
    showDialog: {
      handler: function handler(val) {
        this.tabActive = 'orgInfo';
        if (val) this.isSave = false;
      },
      immediate: true
    }
  },
  methods: {
    close: function close() {
      if (this.isSave) this.refresh();else this.$emit('cancel');
    },
    cancel: function cancel() {
      this.showDialog = false;
    },

    /** 保存成功 事件 */
    refresh: function refresh() {
      this.$emit('refresh');
    },

    /** 保存事件 */
    save: function save() {
      var _ref$save;

      var ref = this.$refs[this.tabActive];
      var refSave = ref === null || ref === void 0 ? void 0 : (_ref$save = ref.save) === null || _ref$save === void 0 ? void 0 : _ref$save.call(ref);
      if (refSave === 'stop') return false;
      this.isSave = true;
    },

    /** 运行子组件确定事件 */
    clickSave: function clickSave() {
      var _ref$clickSave;

      var ref = this.$refs[this.tabActive];
      var refConfirm = ref === null || ref === void 0 ? void 0 : (_ref$clickSave = ref.clickSave) === null || _ref$clickSave === void 0 ? void 0 : _ref$clickSave.call(ref);
      if (refConfirm === 'stop') return false;
    }
  }
};