import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
// 产品
import request from '@/plugin/axios';
import { blobRequest } from '@/plugin/axios';
import func from '@/maps/url/crm/setup/product';
import util from '@/libs/util';
import { formatDate } from '@/libs/crm/format';
import { indexOf } from 'lodash'; // TODO 新增

export function add(params) {
  return request.post(func.add, {
    body: params
  });
} // TODO 编辑

export function edit(params) {
  return request.post(func.edit, {
    body: params
  });
} // TODO 删除

export function remove(params) {
  return request.post(func.remove, {
    body: params
  });
} // TODO 停用

export function stop(params) {
  return request.post(func.stop, {
    body: params
  });
} // TODO 列表

export function list(params) {
  return request.post(func.list, {
    body: params
  });
} // TODO 产品下拉列表

export function listEnum(params) {
  return request.post(func.listEnum, {
    body: params
  });
} // TODO 详情

export function getInfo(params) {
  return request.post(func.getInfo, {
    body: params
  });
} // TODO 类型列表

export function listType(params) {
  return request.post(func.listType, {
    body: params
  });
} // TODO 类型列表

export function saveExt(params) {
  return request.post(func.saveExt, {
    body: params
  });
} // TODO 类型列表

export function listExt(params) {
  return request.post(func.listExt, {
    body: params
  });
} // TODO 保存产品机构配置

export function saveSms(params) {
  return request.post(func.saveSms, {
    body: params
  });
} // TODO 产品机构配置列表

export function listSms(params) {
  return request.post(func.listSms, {
    body: params
  });
} // TODO 产品 机构配置树

export function listGetOrgTree(params) {
  return request.post(func.listGetOrgTree, {
    body: params
  }).then(function (res) {
    var _res$data = res.data,
        data = _res$data === void 0 ? {} : _res$data;

    var assembleTree = function assembleTree(arr) {
      return arr.map(function (item) {
        var _item$orgName = item.orgName,
            orgName = _item$orgName === void 0 ? '' : _item$orgName,
            _item$orgCd = item.orgCd,
            orgCd = _item$orgCd === void 0 ? '' : _item$orgCd,
            _item$children = item.children,
            children = _item$children === void 0 ? [] : _item$children,
            _item$smsTemplateId = item.smsTemplateId,
            smsTemplateId = _item$smsTemplateId === void 0 ? '' : _item$smsTemplateId;
        var renew_success = '';
        var deal_succes_to_customer = '';
        var contract_expires = '';

        if (smsTemplateId != null) {
          renew_success = smsTemplateId.indexOf('renew_success') != -1 ? smsTemplateId : '';
          deal_succes_to_customer = smsTemplateId.indexOf('deal_succes_to_customer') != -1 ? smsTemplateId : '';
          contract_expires = smsTemplateId.indexOf('contract_expires') != -1 ? smsTemplateId : '';
        }

        var orgItem = _objectSpread(_objectSpread({}, item), {}, {
          label: orgName,
          value: orgCd,
          orgCd: orgCd,
          renew_success: renew_success ? renew_success : '',
          deal_succes_to_customer: deal_succes_to_customer ? deal_succes_to_customer : '',
          contract_expires: contract_expires ? contract_expires : ''
        }); // 如果有子菜单，则遍历子菜单


        if (children.length) {
          orgItem.children = assembleTree(children);
        }

        return orgItem;
      });
    };

    return assembleTree([data] || []);
  });
} // TODO 导出单个产品Excel

export function exportExcelProduct(params) {
  //let date = formatDate(new Date());
  var productId = params.productId;
  var productName = params.productName;
  return blobRequest.post(func.exportExcelProduct, {
    body: {
      productId: productId
    }
  }).then(function (blob) {
    util.download(blob, productId + "_" + productName + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // TODO 导入Excel

export function importExcelProduct(params) {
  return request.post(func.importExcelProduct, {
    file: params
  }, {
    responseType: 'arraybuffer'
  });
} // TODO 导出Excel

export function exportExcel(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcel, {
    body: params
  }).then(function (blob) {
    util.download(blob, '产品基础信息' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // TODO 导出产品与组织关系

export function exportProductOrgExcel(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportProductOrgExcel, {
    body: params
  }).then(function (blob) {
    util.download(blob, '产品与组织关系' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // TODO 导出Excel产品募集进度

export function exportExcelProcess(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcelProcess, {
    body: params
  }).then(function (blob) {
    util.download(blob, '产品募集进度' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // TODO 产品募集进度列表

export function listProcess(params) {
  return request.post(func.listProcess, {
    body: params
  });
}