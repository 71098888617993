import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { CONFIG, TOP_FORM } from './config';
import { cloneDeep } from 'lodash';
import { useSuccessTip } from '@/hooks/use-tip';
import { getHeaders } from '@/api-new/service';
import url from '@/api-new/url';
import api from '@/api-new';
var host = url.host,
    apiPrev = url.apiPrev;
var apiUrl = url.info.classify;
export default {
  name: 'PageInfoCategory',
  data: function data() {
    return {
      filtersData: {},
      defaultKeys: [],
      config: cloneDeep(CONFIG),
      topForm: cloneDeep(TOP_FORM),
      formData: {
        classifyName: '',
        parentId: null
      },
      treeShow: true,
      acllClassShow: true,
      defaultProps: {
        children: 'childClassifyList',
        label: 'classifyName'
      },
      categoryTreeData: []
    };
  },
  created: function created() {
    this.httpConfig = {
      url: "".concat(host).concat(apiPrev).concat(apiUrl.getClassifyDocumentPageList),
      headers: getHeaders()
    };
    this.init();
  },
  methods: {
    handleChange: function handleChange(data) {
      var _this = this;

      api.UPDATE_BOTTOM_SORT({
        id: data.id,
        sort: data.sort
      }).then(function () {
        _this.getClassifyTreeList();
      });
    },

    /* 获取分类树状数据 */
    getClassifyTreeList: function getClassifyTreeList() {
      var _this2 = this;

      this.categoryTreeData = [];
      api.GET_CLASSIFY_TREE_LIST().then(function (res) {
        _this2.categoryTreeData = res;
        console.log(_this2.categoryTreeData);
      });
    },

    /* 获取弹窗新增/下级分类的上级分类下拉列表(所有状态) */
    getAllStatusChooseList: function getAllStatusChooseList(id) {
      var _this3 = this;

      api.GET_ALL_STATUS_CHOOSE_LIST({
        id: id
      }).then(function (res) {
        /* 新增分类的上级分类 */
        _this3.config.formConfig.parentId.options = res;
      });
    },
    init: function init() {
      this.getClassifyTreeList();
      this.getAllStatusChooseList();
    },

    /* 处理拖拽 */
    handleDrop: function handleDrop(draggingNode, dropNode, dropType) {
      var arr = [];
      var Id = draggingNode.data.parentId ? draggingNode.data.parentId : draggingNode.data.id; // 添加打开节点的记录

      this.defaultKeys.push(Id); // 寻找排序的id

      this.categoryTreeData.forEach(function (item) {
        // if (item.parentId === dropNode.data.parentId) {
        // 替换的id
        if (item.id === dropNode.data.id) {
          arr.push({
            id: draggingNode.data.id,
            sort: arr.length
          });
        } else if (item.id === draggingNode.data.id) {
          arr.push({
            id: dropNode.data.id,
            sort: arr.length
          });
        } else {
          arr.push({
            id: item.id,
            sort: arr.length
          });
        } // }

      });

      if (arr.length > 0) {
        api.UPDATE_BOTTOM_SORT({
          list: arr
        }).then(function () {});
      }
    },

    /* 操作按钮事件 */
    operation: function operation(data, type) {
      var _this4 = this;

      console.log('data: ', data);
      var classifyName = data.classifyName,
          id = data.id;

      switch (type) {
        case 'edit':
          this.config.dialogData.dialogVisible = true;
          this.config.dialogData.footActive = false;
          this.config.dialogData.title = '编辑分类';
          this.config.dialogData.type = 'edit';
          this.config.dialogData.width = '600px';
          this.config.dialogData.height = '730px';
          data.coverPicture = {
            url: data.coverPicture
          };
          data.backgroundPicture = {
            url: data.backgroundPicture
          };
          this.formData = data;
          this.config.formConfig.parentId.props.disabled = true;
          break;

        case 'add':
          this.config.dialogData.dialogVisible = true;
          this.config.dialogData.footActive = false;
          this.config.dialogData.title = '新增下级分类';
          this.config.dialogData.type = 'add';
          this.config.dialogData.width = '600px';
          this.config.dialogData.height = '730px';
          this.config.formConfig.parentId.props.disabled = true;
          this.formData = {
            parentId: id,
            classifyName: ''
          };
          break;

        case 'delete':
          this.$confirm('是否确定删除所选分类？', '删除', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {
            _this4.Delete(data.id); // this.init();

          }).catch(function () {
            _this4.$message({
              type: 'info',
              message: '已取消操作'
            });
          });
          break;
      }
    },

    /* 删除 */
    Delete: function Delete(id) {
      var _this5 = this;

      api.COMMON_REQUEST(apiUrl.deleteById, {
        id: id
      }).then(function () {
        useSuccessTip();

        _this5.init();
      });
    },

    /* 弹窗确认 */
    confirm: function confirm() {
      this.$refs.form.submitForm();
    },

    /* 新增分类弹窗确认 */
    submitForm: function submitForm(res) {
      /* 有传id则更新，没传则新增 */
      if (res.id) {
        this.updateClassify();
      } else {
        this.addClassify();
      }
    },

    /* 新增资讯分类 */
    addClassify: function addClassify() {
      var _this6 = this;

      var params = _objectSpread({}, this.formData);

      var _this$formData = this.formData,
          coverPicture = _this$formData.coverPicture,
          backgroundPicture = _this$formData.backgroundPicture;

      if (coverPicture) {
        params.coverPicture = coverPicture.url;
      }

      if (backgroundPicture) {
        params.backgroundPicture = backgroundPicture.url;
      }

      api.ADD_CLASSIFY(params).then(function () {
        useSuccessTip();
        _this6.config.dialogData.dialogVisible = false;
        _this6.formData = {
          classifyName: '',
          parentId: null
        };

        _this6.init();
      });
    },

    /* 更新资讯分类 */
    updateClassify: function updateClassify() {
      var _this7 = this;

      var params = _objectSpread({}, this.formData);

      var _this$formData2 = this.formData,
          coverPicture = _this$formData2.coverPicture,
          backgroundPicture = _this$formData2.backgroundPicture;

      if (coverPicture) {
        params.coverPicture = coverPicture.url;
      }

      if (backgroundPicture) {
        params.backgroundPicture = backgroundPicture.url;
      }

      api.UPDATE_CLASSIFY(params).then(function () {
        useSuccessTip();
        _this7.config.dialogData.dialogVisible = false;
        _this7.formData = {
          classifyName: '',
          id: null
        };

        _this7.init();
      });
    },

    /* 是否启用处理 */
    switchChange: function switchChange(data) {
      var id = data.id,
          status = data.status;
      api.UPDATE_STATUS_CLASSIFY({
        id: id,
        status: status
      }).then(function () {
        useSuccessTip();
      });
    },
    btnFuns: function btnFuns(val) {
      var _this8 = this;

      // 全部展开
      if (val === 'expand') {
        this.defaultKeys = [];
        this.treeShow = false;
        this.$nextTick(function () {
          _this8.treeShow = true;
          _this8.acllClassShow = true;
        });
      } else if (val === 'collapse') {
        // 全部收起
        this.defaultKeys = [];
        this.treeShow = false;
        this.$nextTick(function () {
          _this8.treeShow = true;
          _this8.acllClassShow = false;
        });
      } else if (val === 'add') {
        // 新增分类
        this.config.dialogData.width = '600px';
        this.config.dialogData.height = '730px';
        this.config.dialogData.dialogVisible = true;
        this.config.dialogData.footActive = false;
        this.config.formConfig.parentId.props.disabled = false;
        this.config.dialogData.title = '新增分类';
        this.config.dialogData.type = 'add';
        this.formData = {
          parentId: '',
          classifyName: ''
        };
      }
    },

    /* 点击文章数跳转处理 */
    jump: function jump(id) {
      /* 跳转到资讯管理列表页并按分类筛选 */
      this.$router.push({
        name: 'infoManager',
        query: {
          classifyId: id
        }
      });
    }
  }
};