import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import useImageParse from '@/hooks/use-image-parse';
var PUBLIC_CONFIG = {
  labelWidth: 150,
  col: 10
};
export var FORM_CONFIG = [{
  title: '基本信息',
  children: [_objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'radio',
    label: '资讯类型',
    prop: 'informationType',
    options: [{
      label: '平台文章',
      value: 1
    }, {
      label: '外部链接',
      value: 2
    }]
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '资讯标题',
    prop: 'name',
    rules: [{
      required: true,
      message: '资讯标题不能为空',
      trigger: 'blur'
    }]
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '资讯链接',
    prop: 'linkUrl',
    hidden: true
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'uploadImage',
    label: '资讯封面',
    prop: 'coverUrl',
    props: {
      placeholder: '最多可传1张，尺寸为：216*162px，图片大小不超过200KB，支GIF,JPEG,JPG,PNG,BMP格式'
    }
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'cascader',
    label: '所属分类',
    prop: 'classifyId',
    props: {
      props: {
        label: 'name',
        value: 'id',
        children: 'children',
        checkStrictly: true
      }
    },
    rules: [{
      required: true,
      message: '所属分类不能为空',
      trigger: 'blur'
    }],
    options: []
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '作者',
    prop: 'author',
    rules: [{
      required: true,
      message: '作者不能为空',
      trigger: 'blur'
    }]
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '排序值',
    prop: 'sort',
    rules: [{
      required: true,
      message: '请输入排序',
      trigger: 'blur'
    }]
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'slot',
    label: '发布时间',
    prop: 'releaseTime'
  })]
}, {
  title: '文章正文',
  children: [_objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'editor',
    label: '',
    prop: 'content',
    col: 24,
    hidden: false
  })]
}];