import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
var PUBLIC_CONFIG = {
  labelWidth: 80
};
export var TOP_FORM = {
  customerCode: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '客户编码',
    props: {
      placeholder: '请输入客户编码'
    }
  }),
  departmentId: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    label: '部门',
    type: 'cascader',
    options: [],
    props: {
      placeholder: '请选择部门',
      filterable: true,
      props: {
        checkStrictly: true,
        emitPath: false,
        value: 'orgCd',
        label: 'orgName',
        children: 'children'
      }
    }
  }),
  customerName: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '客户名称',
    props: {
      placeholder: '请输入客户名称'
    }
  }),
  customerType: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '客户类型',
    props: {
      placeholder: '请选择客户类型'
    },
    options: []
  }),
  levelId: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '客户等级',
    props: {
      placeholder: '请选择客户等级'
    },
    options: []
  }),
  year: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'slot',
    col: 6,
    label: '客户年限'
  }),
  createTime: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'datePicker',
    label: '创建时间',
    col: 6,
    propGroup: ['beginCreateTime', 'endCreateTime'],
    props: {
      type: 'daterange'
    }
  }),
  status: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '客户状态',
    col: 6,
    props: {
      placeholder: '请选择客户状态'
    },
    options: [{
      label: '启用',
      value: 1
    }, {
      label: '禁用',
      value: 0
    }]
  }),
  agentUserName: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '理顾',
    props: {
      placeholder: '请输入理顾名称'
    }
  })
};
export var TABLE_LABEL = [{
  label: '客户名称',
  prop: 'customerName',
  type: 'link',
  width: 120,
  sortable: 'customerList',
  sortProp: 'customerName'
}, {
  label: '性别',
  prop: 'sex',
  sortable: 'customerList',
  sortProp: 'sex',
  formatter: function formatter(row, column, cellValue) {
    var res;

    switch (cellValue) {
      case '1':
        res = '男';
        break;

      case '0':
        res = '女';
        break;

      default:
        res = '未知';
    }

    return res;
  }
}, {
  label: '年龄',
  prop: 'age',
  sortable: 'customerList',
  sortProp: 'age'
}, {
  label: '客户类型',
  prop: 'customerTypeValue',
  sortable: 'customerList',
  sortProp: 'customerTypeValue',
  width: 100
}, {
  label: '客户等级',
  prop: 'customerLevelValue',
  sortable: 'customerList',
  sortProp: 'customerLevelValue',
  width: 100
}, {
  label: '理顾',
  prop: 'agentUserName',
  sortable: 'customerList',
  sortProp: 'agentUserName',
  width: 100
}, {
  width: 150,
  label: '理顾是否离职',
  prop: 'agentUserHasResigned',
  sortable: 'customerList',
  sortProp: 'agentUserHasResigned',
  formatter: function formatter(row, column, cellValue) {
    var res;

    switch (cellValue) {
      case 0:
        res = '在职';
        break;

      case 1:
        res = '已离职';
        break;

      default:
    }

    return res;
  }
}, {
  label: '部门',
  prop: 'departmentName',
  sortable: 'customerList',
  sortProp: 'departmentName',
  width: 300
}, {
  width: 150,
  label: '理顾部门状态',
  prop: 'orgStatus',
  sortable: 'customerList',
  sortProp: 'orgStatus',
  formatter: function formatter(row, column, cellValue) {
    var res;

    switch (cellValue) {
      case '0':
        res = '停用';
        break;

      case '1':
        res = '启用';
        break;

      default:
    }

    return res;
  }
}, {
  label: '客户年限',
  prop: 'customerYearLimit',
  sortable: 'customerList',
  sortProp: 'customerYearLimit',
  width: 100
}, {
  label: '是否在存',
  prop: 'isExistValidContract',
  formatter: function formatter(row, column, cellValue) {
    var res;

    switch (cellValue) {
      case 0:
        res = '否';
        break;

      case 1:
        res = '在存';
        break;

      default:
    }

    return res;
  },
  width: 100
}, {
  label: '证件编码',
  prop: 'identification',
  sortable: 'customerList',
  sortProp: 'identification',
  width: 200
}, {
  label: '客户编号',
  prop: 'customerCode',
  sortable: 'customerList',
  sortProp: 'customerCode',
  width: 123
}, {
  label: '客户状态',
  prop: 'status',
  sortable: 'customerList',
  sortProp: 'status',
  width: 100,
  formatter: function formatter(row, column, cellValue) {
    var res = '';

    switch (Number(cellValue)) {
      case 0:
        res = '停用';
        break;

      case 1:
        res = '启用';
        break;

      default:
        res = '';
    }

    return res;
  }
}, {
  type: 'operation',
  label: '操作',
  fixed: 'right',
  width: 100,
  formatter: function formatter(row) {
    var arr = [];

    if (row.status === 1) {
      arr.push({
        type: 'stop',
        label: '停用',
        permission: 'customer-status'
      });
    } else {
      arr.push({
        type: 'start',
        label: '启用',
        permission: 'customer-status'
      }, {
        type: 'delete',
        label: '删除',
        permission: 'customer-delete'
      });
    }

    return arr;
  }
}];
export var TABLE_CONFIG = {
  isSelect: true,
  rowKey: 'id',
  actions: [{
    label: '新增',
    type: 'add',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'customer-add'
  }, {
    label: '导入',
    type: 'slot',
    slotName: 'importBtn',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'customer-import'
  }, {
    label: '导出',
    type: 'export',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'customer-export'
  }, {
    label: '分配',
    type: 'allot',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'customer-allot'
  }, {
    label: '合并客户',
    type: 'merge',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'customer-merge'
  }, {
    label: '转移',
    type: 'transfer',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'customer-transferNonContact'
  }],
  defaultSort: {
    prop: 'updateTime',
    order: 'descending'
  }
};