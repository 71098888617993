import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from '@/mixins/dialog';
import { formatAmountToNumber } from '@/libs/crm/format';
import { computeEarnings } from '@api/crm/buy/buy';
import { settleContactBatch } from '@api/crm/count/count';
export default {
  name: 'settle-batch',
  props: {
    propsModel: {
      type: Array,
      default: function _default() {
        return {};
      }
    }
  },
  components: {},
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      showLogDialog: false,
      tableData: [],
      formModel: {},
      //表单
      rules: {
        settleDate: [{
          required: true,
          message: '请选择结算日期',
          trigger: 'input'
        }]
      }
    };
  },
  created: function created() {},
  computed: {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.loadData();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
      this.formModel = {};
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {},
    // TODO 变更日期，计算各合同收益
    changeSettleDate: function changeSettleDate(result) {
      var _this = this;

      var _loop = function _loop(i) {
        var n = _this.tableData[i];
        var param = {
          contractId: n.contractId,
          productId: n.productId,
          orgCd: n.orgCd,
          amount: formatAmountToNumber(n.amount),
          timeLimit: n.timeLimit,
          returnPeriod: n.returnPeriod,
          startDate: n.startDate,
          endDate: _this.formModel.settleDate
        };
        computeEarnings(param).then(function (res) {
          //计算实际收益
          var d = res.data;
          n.holdDay = d.holdDay;
          n.actualEarnings = d.earnings;
        });
      };

      for (var i = 0; i < this.tableData.length; i++) {
        _loop(i);
      }
    },
    //确认
    clickSubmit: function clickSubmit() {
      var _this2 = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          _this2.$confirm('是否确认结算日期和实际收益正确?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(function () {
            _this2.loading = true;
            var param = {
              arrayObject: []
            };

            _this2.tableData.forEach(function (n) {
              var obj = {
                contractId: n.contractId,
                actualEarnings: n.actualEarnings,
                actualEndDate: _this2.formModel.settleDate
              };
              param.arrayObject.push(obj);
            });

            _this2.loading = true;
            settleContactBatch(param).then(function (res) {
              _this2.loading = false;
              _this2.showDialog = false;

              _this2.$emit('refresh');
            });
          }).catch(function (e) {
            _this2.loading = false;
          });
        }
      });
    },
    loadData: function loadData() {
      this.propsModel.forEach(function (n) {
        n.holdDay = null;
        n.actualEarnings = null;
      });
      this.tableData = Object.assign([], this.propsModel); //复制新对象
    }
  }
};