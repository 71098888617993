import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.keys.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import AUDIT from "@/maps/enum/status/audit";
import COLOR from "@/maps/enum/color";
import DATA_FORMAT_TYPE from "@/maps/enum/data-format-type";
import { FILTER_PROPS, FILTER_FORMS } from "./option/filter"; // 过滤条件的form

import TABLE_LIST from "./option/list"; // 表头

import DynamicForm from "@/components/common/dynamic-form";
import DynamicTable from "@/components/common/dynamic-table";
import DialogAudit from "./components/dialog-audit";
import DialogInfo from "./components/dialog-info";
import { listAudit, audit, exportExcelTemplate as _exportExcelTemplate, exportExcelBuyTemplate as _exportExcelBuyTemplate } from "@api/crm/paper/paper";
export default {
  name: "paper-service",
  components: {
    DynamicForm: DynamicForm,
    DynamicTable: DynamicTable,
    DialogAudit: DialogAudit,
    DialogInfo: DialogInfo
  },
  data: function data() {
    return {
      loading: false,
      filterProps: FILTER_PROPS,
      filterForms: FILTER_FORMS,
      headers: TABLE_LIST,
      table: {
        auto: false
      },
      pagination: {},
      // query: {
      //   arrayAuditState: [AUDIT.NOT_DO.value, AUDIT.NO.value], //未审核，拒绝
      // },
      tableData: [],
      showAuditDialog: false,
      // 审核窗口
      showInfoDialog: false,
      // 详情窗口
      currentRow: {} // 当前点击行

    };
  },
  computed: {},
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
      this.$refs.jTable.pageChange(1);
    },
    //初始化搜索条件
    initFilter: function initFilter() {
      var query = this.$refs.form.getForm();
      this.query = _objectSpread(_objectSpread({}, this.query), query);
    },
    //点击刷新
    clickRefresh: function clickRefresh() {
      this.$refs.jTable.onRefresh();
      this.$message({
        message: "数据已刷新",
        type: "info",
        duration: 500
      });
    },
    //点击重置
    clickReset: function clickReset() {
      this.$refs.form.reset();
    },
    // TODO 搜索
    clickSearch: function clickSearch() {
      this.initFilter();
      this.$refs.jTable.pageChange(1);
    },
    // TODO 获取列表
    loadData: function loadData(_ref) {
      var _this = this;

      var _ref$page = _ref.page,
          page = _ref$page === void 0 ? {} : _ref$page;
      this.initFilter();
      var param = {
        page: page,
        query: this.query
      };
      this.loading = true;
      listAudit(param, DATA_FORMAT_TYPE.USRE_SETUP).then(function (res) {
        _this.loading = false;
        _this.tableData = [];

        if (res.data != null) {
          var _res$data = res.data,
              data = _res$data === void 0 ? [] : _res$data,
              _page = res.page;
          var _page$totalCount = _page.totalCount,
              totalCount = _page$totalCount === void 0 ? 0 : _page$totalCount;
          _this.tableData = data;
          _this.pagination = {
            total: totalCount
          };
        } else {
          _this.$message({
            message: "暂无数据",
            type: "info"
          });
        }
      }).catch(function (e) {
        _this.loading = false;

        _this.$message({
          message: "查询列表数据出错",
          type: "error"
        });
      });
    },
    // TODO 选中行
    selectRow: function selectRow(row) {
      this.currentRow = row;
    },
    // TODO 点击审核
    clickAudit: function clickAudit() {
      var row = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.currentRow = row;
      this.showAuditDialog = true;
    },
    // TODO 点击详情
    clickInfo: function clickInfo() {
      var row = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.currentRow = row;
      this.showInfoDialog = true;
    },
    // TODO 点击忽略
    clickIgnore: function clickIgnore() {
      var _this2 = this;

      if (Object.keys(this.currentRow).length === 0) {
        this.$message("请选择合同");
      } else {
        this.$confirm("确定该合同不需要审核?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          var param = {
            contractId: _this2.currentRow.contractId,
            audit: AUDIT.IGNORE.value
          };
          _this2.loading = true;
          audit(param).then(function (res) {
            _this2.loading = false;

            _this2.init();
          }).catch(function (e) {
            _this2.loading = false;
          });
        }).catch(function () {});
      }
    },
    exportExcelTemplate: function exportExcelTemplate() {
      var _this3 = this;

      this.$confirm("按查询条件导出数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        _this3.initFilter();

        var param = {
          query: _this3.query
        };

        _exportExcelTemplate(param).catch(function (e) {
          _this3.$message({
            message: e,
            type: "error"
          });
        });
      }).catch(function () {});
    },
    exportExcelBuyTemplate: function exportExcelBuyTemplate() {
      var _this4 = this;

      this.$confirm("按查询条件导出数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        _this4.initFilter();

        var param = {
          query: _this4.query
        };

        _exportExcelBuyTemplate(param).catch(function (e) {
          _this4.$message({
            message: e,
            type: "error"
          });
        });
      }).catch(function () {});
    },
    setStatusColor: function setStatusColor(status) {
      //合同状态颜色
      var className = "";

      switch (status) {
        case AUDIT.NOT_DO.value:
          className = COLOR.BLUE;
          break;

        case AUDIT.YES.value:
          className = COLOR.GREEN;
          break;

        case AUDIT.NO.value:
          className = COLOR.RED;
          break;

        case AUDIT.IGNORE.value:
          className = COLOR.GREEN;
          break;
      }

      return className;
    }
  },
  created: function created() {}
};