import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var PUBLIC_CONFIG = {
  labelWidth: 100
};
export var TOP_FORM = {
  appointmentName: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '姓名',
    props: {
      placeholder: '请输入姓名'
    }
  }),
  appointmentMobile: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '手机号',
    props: {
      placeholder: '请输入手机号'
    }
  }),
  agentUserOrgCd: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    label: '部门',
    type: 'cascader',
    options: [],
    props: {
      placeholder: '请选择部门',
      filterable: true,
      props: {
        checkStrictly: true,
        emitPath: false,
        value: 'orgCd',
        label: 'orgName',
        children: 'children'
      }
    }
  })
};
export var TABLE_LABEL = [{
  label: '姓名',
  prop: 'appointmentName',
  type: 'link',
  sortable: 'agentAppointmentList',
  sortProp: 'appointmentName'
}, {
  label: '手机号',
  prop: 'appointmentMobile',
  sortable: 'agentAppointmentList',
  sortProp: 'appointmentMobile'
}, {
  label: '部门',
  prop: 'agentUserOrgName',
  sortable: 'agentAppointmentList',
  sortProp: 'agentUserOrgName',
  width: 250
}, {
  label: '顾问',
  prop: 'agentUserName',
  sortable: 'agentAppointmentList',
  sortProp: 'agentUserName'
}, {
  label: '预约时间',
  prop: 'appointmentTime',
  sortable: 'agentAppointmentList',
  sortProp: 'appointmentTime'
}, {
  label: '创建时间',
  prop: 'createTime',
  sortable: 'agentAppointmentList',
  sortProp: 'createTime'
}, {
  type: 'operation',
  label: '操作',
  width: 200,
  fixed: 'right',
  formatter: function formatter() {
    return [{
      type: 'delete',
      label: '删除',
      permission: 'agentAppointment-delete'
    }];
  }
}];
export var TABLE_CONFIG = {
  isSelect: true,
  actions: [{
    label: '导出',
    type: 'export',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'agentAppointment-export'
  }],
  defaultSort: {
    prop: 'createTime',
    order: 'descending'
  }
};