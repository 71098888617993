import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
var PUBLIC_CONFIG = {
  labelWidth: 100
};
export var TOP_FORM = {
  serialNo: {
    type: 'input',
    label: '活动明细编号',
    props: {
      placeholder: '请输入活动明细编号'
    }
  },
  createTime: {
    labelWidth: 110,
    type: 'datePicker',
    label: '创建时间',
    propGroup: ['beginCreateTime', 'endCreateTime'],
    props: {
      type: 'daterange'
    }
  },
  scoreType: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '积分类型',
    props: {
      placeholder: '请选择积分类型'
    },
    options: [{
      label: '合同积分',
      value: 1
    }, {
      label: '活动积分',
      value: 2
    }]
  }),
  operationType: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '操作类型',
    props: {
      placeholder: '请选择操作类型'
    },
    options: []
  }),
  operationMethod: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '变动方式',
    props: {
      placeholder: '请选择积分类型'
    },
    options: [{
      label: '增加',
      value: 1
    }, {
      label: '扣减',
      value: 2
    }]
  })
};
export var TABLE_LABEL = [{
  label: '积分明细编号',
  prop: 'serialNo',
  width: 120
}, {
  label: '积分类型',
  prop: 'scoreType',
  formatter: function formatter(row, column, cellValue) {
    var res;

    switch (Number(cellValue)) {
      case 1:
        res = '合同积分';
        break;

      case 2:
        res = '活动积分';
        break;

      default:
        res = '';
    }

    return res;
  }
}, {
  label: '操作类型',
  prop: 'operationTypeValue',
  width: 120
}, {
  label: '变动类型',
  prop: 'operationMethod',
  formatter: function formatter(row, column, cellValue) {
    var res;

    switch (Number(cellValue)) {
      case 1:
        res = '增加';
        break;

      case 2:
        res = '扣减';
        break;

      default:
        res = '';
    }

    return res;
  }
}, {
  label: '分值',
  prop: 'score'
}, {
  label: '成本机构',
  prop: 'costOrgName',
  width: 150
}, {
  label: '操作者',
  prop: 'createUserName',
  width: 100
}, {
  label: '创建时间',
  prop: 'createTime',
  sortable: 'ScoreDetailList',
  sortProp: 'createTime',
  width: 150
}, {
  label: '生效时间',
  prop: 'effectDate',
  sortable: 'ScoreDetailList',
  sortProp: 'effectDate',
  width: 100
}, {
  label: '失效时间',
  prop: 'expiryDate',
  sortable: 'ScoreDetailList',
  sortProp: 'expiryDate',
  width: 100
}, {
  label: '有效天数',
  prop: 'validDay'
}, {
  label: '积分路径',
  prop: 'dataId',
  width: 150
}, {
  label: '剩余积分',
  prop: 'remainScore'
}, {
  label: '备注',
  prop: 'remark',
  width: 250
}, {
  label: '状态',
  prop: 'status',
  formatter: function formatter(row, column, cellValue) {
    var res;

    switch (Number(cellValue)) {
      case 0:
        res = '失效';
        break;

      case 1:
        res = '有效';
        break;

      default:
        res = '';
    }

    return res;
  }
}, {
  type: 'operation',
  label: '操作',
  width: 100,
  formatter: function formatter() {
    return [{
      type: 'detail',
      label: '详情',
      permission: 'integralDetail-changeDetail'
    }];
  }
}];
export var TABLE_CONFIG = {
  isSelect: true,
  actions: [{
    label: '导出',
    type: 'export',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'integralDetail-export'
  }, {
    label: '返回积分管理',
    type: 'home',
    props: {
      size: 'small',
      type: 'primary'
    }
  }],
  defaultSort: {
    prop: 'createTime',
    order: 'descending'
  }
};