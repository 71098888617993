import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
// 付息申请打印
import { getLodop } from '@/plugin/c-lodop/LodopFuncs';
import { readPublicFile, replacePublicFileKeyFromObject } from '@/libs/crm/file';
import { formatAmountToNumber, formatAmountToChinese, formatObjectAmountToString } from '@/libs/crm/format';
/**
 * 付息对日
 * @param {*} model
 */

export function printInterestApply(model) {
  var printModel = _objectSpread({}, model);

  var printFile = '/file/print/interest_apply.html'; // 打印静态文件
  // 设置合同信息的字段

  setCountModelContract(printModel); // 付息日期拆分

  var arraySettlementsDates = printModel.settlementsDates.split('-');
  printModel.settlementsDatesYear = arraySettlementsDates[0];
  printModel.settlementsDatesMonth = arraySettlementsDates[1];
  printModel.settlementsDatesDay = arraySettlementsDates[2]; // 付息金额转中文大写

  if (printModel.interest > 0) {
    var interest = formatAmountToNumber(printModel.interest);
    var interestChinese = formatAmountToChinese(interest, 8, '/'); // 将金额格式化为中文大写，且整数8位，前面补#，小数2位.ⓧ 无法打印显示, 改用 ∕

    var arrInterestChinese = interestChinese.split('.');
    var arrInterestInt = arrInterestChinese[0].split(''); // 整数

    var arrInterestFloat = arrInterestChinese[1].split(''); // 小数

    for (var i = 0; i < arrInterestInt.length; i++) {
      // 整数
      var label = 'interest_i' + (arrInterestInt.length - i); // 金额位数        

      printModel[label] = arrInterestInt[i];
    }

    for (var _i = 0; _i < arrInterestFloat.length; _i++) {
      // 小数
      var _label = 'interest_f' + (_i + 1); // 金额位数    


      printModel[_label] = arrInterestFloat[_i];
    }
  } else {
    for (var _i2 = 0; _i2 < 8; _i2++) {
      var _label2 = 'interest_i' + (_i2 + 1);

      printModel[_label2] = '/';
    }

    for (var _i3 = 0; _i3 < 2; _i3++) {
      var _label3 = 'interest_f' + (_i3 + 1);

      printModel[_label3] = '/';
    }
  } //金额格式转千分位


  formatObjectAmountToString(printModel); // 加载申请文件打印

  readPublicFile(printFile).then(function (re) {
    var htmlString = re; // 读入文件内容字符串

    htmlString = replacePublicFileKeyFromObject(htmlString, printModel); // 代换掉其中的key        
    // 打印

    var LODOP = getLodop();
    if (!LODOP) return;
    LODOP.PRINT_INIT(printModel.customerName + '付息提取现金申请表');
    LODOP.ADD_PRINT_HTM(30, 0, '100%', '100%', htmlString);
    LODOP.SET_PRINT_STYLEA(0, 'FontSize', 5);
    LODOP.SET_PRINT_MODE("AUTO_CLOSE_PREWINDOW", 1); //打印完关闭预览窗口

    LODOP.PREVIEW();
  });
}
/**
 * 设置合同信息部分的字段
 * @param {*} model 
 */

function setCountModelContract(printModel) {
  // 合同开始日期拆分
  var arrayStartDate = printModel.startDate.split('-');
  printModel.startDateYear = arrayStartDate[0];
  printModel.startDateMonth = arrayStartDate[1];
  printModel.startDateDay = arrayStartDate[2]; // 合同到期日期拆分

  var arrayEndDate = printModel.endDate.split('-');
  printModel.endDateYear = arrayEndDate[0];
  printModel.endDateMonth = arrayEndDate[1];
  printModel.endDateDay = arrayEndDate[2]; // 合同金额转中文大写

  var amount = formatAmountToNumber(printModel.amount);
  var amountChinese = formatAmountToChinese(amount, 8, '/'); // 将金额格式化为中文大写，且整数8位，前面补#，小数2位.ⓧ 无法打印显示, 改用 ∕

  var arrAmountChinese = amountChinese.split('.');
  var arrAmountChineseInt = arrAmountChinese[0].split(''); // 整数

  var arrAmountChineseFloat = arrAmountChinese[1].split(''); // 小数

  for (var i = 0; i < arrAmountChineseInt.length; i++) {
    // 整数
    var label = 'amount_i' + (arrAmountChineseInt.length - i); // 金额位数    

    printModel[label] = arrAmountChineseInt[i];
  }

  for (var _i4 = 0; _i4 < arrAmountChineseFloat.length; _i4++) {
    // 小数
    var _label4 = 'amount_f' + (_i4 + 1); // 金额位数    


    printModel[_label4] = arrAmountChineseFloat[_i4];
  } // 收益金额转中文大写


  var expectEarnings = formatAmountToNumber(printModel.expectEarnings);
  var expectEarningsChinese = formatAmountToChinese(expectEarnings, 8, '/'); // 将金额格式化为中文大写，且整数8位，前面补#，小数2位.ⓧ 无法打印显示, 改用 ∕

  var arrExpectEarningsChinese = expectEarningsChinese.split('.');
  var arrExpectEarningsInt = arrExpectEarningsChinese[0].split(''); // 整数

  var arrExpectEarningsFloat = arrExpectEarningsChinese[1].split(''); // 小数

  for (var _i5 = 0; _i5 < arrExpectEarningsInt.length; _i5++) {
    // 整数
    var _label5 = 'expectEarnings_i' + (arrExpectEarningsInt.length - _i5); // 金额位数    


    printModel[_label5] = arrExpectEarningsInt[_i5];
  }

  for (var _i6 = 0; _i6 < arrExpectEarningsFloat.length; _i6++) {
    // 小数
    var _label6 = 'expectEarnings_f' + (_i6 + 1); // 金额位数    


    printModel[_label6] = arrExpectEarningsFloat[_i6];
  }
}