import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { FILTER_PROPS, FILTER_FORMS } from "./option/filter"; // 过滤条件的form

import DynamicForm from "@/components/common/dynamic-form";
import { formatDate } from "@/libs/crm/format";
import { orgAnalyze, exportExcelAnalyze } from "@api/crm/report/report";
export default {
  name: "report-org-analyze",
  components: {
    DynamicForm: DynamicForm
  },
  data: function data() {
    return {
      loading: false,
      filterProps: FILTER_PROPS,
      filterForms: FILTER_FORMS,
      tableTitle: {
        date: "",
        same: "",
        chain: ""
      },
      tableData: [],
      query: {}
    };
  },
  computed: {},
  mounted: function mounted() {
    this.setTableTitle();
    this.loadData();
  },
  methods: {
    //初始化搜索条件
    initFilter: function initFilter() {
      var query = this.$refs.form.getForm();
      this.query = _objectSpread(_objectSpread({}, this.query), query);
    },
    // TODO 搜索
    clickSearch: function clickSearch() {
      this.setTableTitle();
      this.loadData();
    },
    //点击重置
    clickReset: function clickReset() {
      this.$refs.form.reset();
    },
    // TODO 获取列表
    loadData: function loadData() {
      var _this = this;

      this.initFilter();
      var query = this.query;
      var param = {
        query: _objectSpread({}, query)
      };

      if (param.query.orgCd == "") {
        param.query.orgCd = "0150";
      }

      orgAnalyze(param).then(function (res) {
        if (res.data != null) {
          //金额转为万元
          var sumAmount = 0; //总金额

          var sumYearAmount = 0; //总年化入金

          var sumAmountChain = 0; //总金额

          var sumYearAmountChain = 0; //总年化入金

          var sumAmountSame = 0; //总金额

          var sumYearAmountSame = 0; //总年化入金

          for (var i = 0; i < res.data.length; i++) {
            var m = res.data[i];
            var amount = Math.floor(m.amount / 10000 * 100) / 100;
            var amountSame = Math.floor(m.amountSame / 10000 * 100) / 100;
            var amountChain = Math.floor(m.amountChain / 10000 * 100) / 100;
            var amountChainDi = m.amountChain == 0 ? 1 : m.amountChain; //环比除数，避免出现0

            var amountSameDi = m.amountSame == 0 ? 1 : m.amountSame; //同比除数，避免出现0

            var amountSamePercent = Math.floor((m.amount - m.amountSame) / amountSameDi * 100 * 100) / 100; //同比

            var amountChainPercent = Math.floor((m.amount - m.amountChain) / amountChainDi * 100 * 100) / 100; //环比

            var yearAmount = Math.floor(m.yearAmount / 10000 * 100) / 100;
            var yearAmountSame = Math.floor(m.yearAmountSame / 10000 * 100) / 100;
            var yearAmountChain = Math.floor(m.yearAmountChain / 10000 * 100) / 100;
            var yearAmountChainDi = m.yearAmountChain == 0 ? 1 : m.yearAmountChain; //环比除数，避免出现0

            var yearAmountSameDi = m.yearAmountSame == 0 ? 1 : m.yearAmountSame; //同比除数，避免出现0

            var yearAmountSamePercent = Math.floor((m.yearAmount - m.yearAmountSame) / yearAmountSameDi * 100 * 100) / 100; //同比

            var yearAmountChainPercent = Math.floor((m.yearAmount - m.yearAmountChain) / yearAmountChainDi * 100 * 100) / 100; //环比

            sumAmount += parseFloat(m.amount);
            sumYearAmount += parseFloat(m.yearAmount);
            sumAmountChain += parseFloat(m.amountChain);
            sumYearAmountChain += parseFloat(m.yearAmountChain);
            sumAmountSame += parseFloat(m.amountSame);
            sumYearAmountSame += parseFloat(m.yearAmountSame);

            _this.tableData.push({
              companyCd: m.companyCd,
              companyName: m.companyName,
              amount: amount,
              amountSame: amountSame,
              amountChain: amountChain,
              amountSamePercent: amountSamePercent,
              amountChainPercent: amountChainPercent,
              yearAmount: yearAmount,
              yearAmountSame: yearAmountSame,
              yearAmountChain: yearAmountChain,
              yearAmountChainPercent: yearAmountChainPercent,
              yearAmountSamePercent: yearAmountSamePercent,
              newCustomer: m.newCustomer
            });
          }

          var sumAmountChainDi = sumAmountChain == 0 ? 1 : sumAmountChain; //环比除数，避免出现0

          var sumYearAmountChainDi = sumYearAmountChain == 0 ? 1 : sumYearAmountChain; //环比除数，避免出现0

          var sumAmountSameDi = sumAmountSame == 0 ? 1 : sumAmountSame; //环比除数，避免出现0

          var sumYearAmountSameDi = sumYearAmountSame == 0 ? 1 : sumYearAmountSame; //环比除数，避免出现0

          _this.tableData.push({
            companyName: _this.tableTitle.date + "总计",
            amount: Math.floor(sumAmount / 10000 * 100) / 100,
            yearAmount: Math.floor(sumYearAmount / 10000 * 100) / 100
          });

          _this.tableData.push({
            companyName: _this.tableTitle.chain + "总计",
            amount: Math.floor(sumAmountChain / 10000 * 100) / 100,
            yearAmount: Math.floor(sumYearAmountChain / 10000 * 100) / 100
          });

          _this.tableData.push({
            companyName: _this.tableTitle.chain + "环比",
            amountChainPercent: Math.floor((sumAmount - sumAmountChain) / sumAmountChainDi * 100 * 100) / 100,
            yearAmountChainPercent: Math.floor((sumYearAmount - sumYearAmountChain) / sumYearAmountChainDi * 100 * 100) / 100
          });

          _this.tableData.push({
            companyName: _this.tableTitle.same + "总计",
            amount: Math.floor(sumAmountSame / 10000 * 100) / 100,
            yearAmount: Math.floor(sumYearAmountSame / 10000 * 100) / 100
          });

          _this.tableData.push({
            companyName: _this.tableTitle.same + "同比",
            amountSamePercent: Math.floor((sumAmount - sumAmountSame) / sumAmountSameDi * 100 * 100) / 100,
            yearAmountSamePercent: Math.floor((sumYearAmount - sumYearAmountSame) / sumYearAmountSameDi * 100 * 100) / 100
          });
        } else {
          _this.$message({
            message: "暂无数据",
            type: "info"
          });
        }
      }).catch(function (e) {
        _this.$message({
          message: "查询列表数据出错",
          type: "error"
        });
      });
    },
    //设置表头
    setTableTitle: function setTableTitle() {
      var query = this.$refs.form.getForm();
      var dateBegin = query.dateBegin;
      var dateEnd = query.dateEnd;

      if (dateBegin == "") {
        //搜索条件有日期，用搜索条件日期作表头，否则用当天日期
        var date = new Date();
        date.setDate(1);
        dateBegin = formatDate(date);
        this.$refs.form.$data.model.dateBegin = dateBegin;
      }

      if (dateEnd == "") {
        var _date = new Date();

        _date.setDate(1);

        _date.setMonth(_date.getMonth() + 1);

        _date.setDate(_date.getDate() - 1); //设置日期为月份最后一天（月+1再日-1）


        dateEnd = formatDate(_date);
        this.$refs.form.$data.model.dateEnd = dateEnd;
      }

      this.tableTitle.date = dateBegin + " ~ " + dateEnd; //环比

      var monthEnd = dateEnd.substring(5, 7);
      var monthBegin = dateBegin.substring(5, 7);
      var intervalMonth = monthEnd - monthBegin; //两个日期相差月份

      if (intervalMonth < 0) {
        intervalMonth += 12;
      } else if (intervalMonth == 0) {
        intervalMonth = 1;
      }

      var arrDateBeginChain = dateBegin.split("-");
      var arrDateEndChain = dateEnd.split("-");
      var dateBeginChain = new Date(arrDateBeginChain[0], arrDateBeginChain[1] - 1 - intervalMonth, arrDateBeginChain[2]);
      var dateEndChain = new Date(arrDateEndChain[0], arrDateEndChain[1] - 1 - intervalMonth, 1);
      dateEndChain.setMonth(dateEndChain.getMonth() + 1);
      dateEndChain.setDate(dateEndChain.getDate() - 1);
      this.tableTitle.chain = formatDate(dateBeginChain) + " ~ " + formatDate(dateEndChain); //同比

      var arrDateBeginSame = dateBegin.split("-");
      var arrDateEndSame = dateEnd.split("-");
      var dateBeginSame = new Date(arrDateBeginSame[0] - 1, arrDateBeginSame[1] - 1, arrDateBeginSame[2]);
      var dateEndSame = new Date(arrDateEndSame[0] - 1, arrDateEndSame[1] - 1, arrDateEndSame[2]);
      this.tableTitle.same = formatDate(dateBeginSame) + " ~ " + formatDate(dateEndSame);
    },
    //导出Excel
    clickExport: function clickExport() {
      var _this2 = this;

      this.$confirm("确定数据导出吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        var query = _this2.$refs.form.getForm();

        var param = {
          query: _objectSpread({}, query)
        };

        if (param.query.orgCd == "") {
          param.query.orgCd = "0150";
        }

        exportExcelAnalyze(param).catch(function (e) {
          _this2.$message({
            message: e,
            type: "error"
          });
        });
      }).catch(function () {});
    }
  },
  created: function created() {}
};