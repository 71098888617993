import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.json.stringify.js";
//缓存类相关接口
import request from '@/plugin/axios';
import STORE from "@/maps/enum/store";
import ENABLE from "@/maps/enum/status/enable";
import funcProduct from '@/maps/url/crm/setup/product';
import funcOrg from '@/maps/url/rm/org';
import funcSetUp from '@/maps/url/rm/setup'; // TODO 用户机构树

export function listCacheOrg() {
  var promise = new Promise(function (resolve, reject) {
    var str = sessionStorage.getItem(STORE.CRM_ORG);

    if (str != null && str != "") {
      var res = JSON.parse(str);
      resolve(res);
    } else {
      var params = {// query: {
        //     enabled: ENABLE.YES.value,
        // }
      };
      request.post(funcOrg.getOrgTree, {
        body: params
      }).then(function (res) {
        var array = [];
        array[0] = res.data;
        resolve(array);
        sessionStorage.setItem(STORE.CRM_ORG, JSON.stringify(array));
      }).catch(function (res) {
        reject(res);
      });
    }
  });
  return promise;
} // TODO 产品下拉列表。如果有缓存，直接读取，否则查询并保存进缓存

export function listCacheProduct() {
  var promise = new Promise(function (resolve, reject) {
    var str = sessionStorage.getItem(STORE.CRM_PRODUCT);

    if (str != null && str != "") {
      var res = JSON.parse(str);
      resolve(res);
    } else {
      var params = {
        query: {
          enabled: '1'
        }
      };
      request.post(funcProduct.listEnum, {
        body: params
      }).then(function (res) {
        var array = res.data;
        resolve(array);
        sessionStorage.setItem(STORE.CRM_PRODUCT, JSON.stringify(array));
      }).catch(function (res) {
        reject(res);
      });
    }
  });
  return promise;
} // TODO 用户信息隐藏设置。如果有缓存，直接读取，否则查询并保存进缓存

export function getUserSecrecyConfig() {
  var promise = new Promise(function (resolve, reject) {
    var str = sessionStorage.getItem(STORE.CRM_CONFIG);

    if (str != null && str != "") {
      var res = JSON.parse(str);
      resolve(res);
    } else {
      var params = {};
      request.post(funcSetUp.getSecrecySetup, {
        body: params
      }).then(function (res) {
        var array = res.data;
        resolve(array);
        sessionStorage.setItem(STORE.CRM_CONFIG, JSON.stringify(array));
      }).catch(function (res) {
        reject(res);
      });
    }
  });
  return promise;
}