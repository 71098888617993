import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import useImageParse from '@/hooks/use-image-parse';
var PUBLIC_CONFIG = {
  labelWidth: 80
};
var PUBLIC_COLUMN_CONFIG = {
  reserveSelection: true
};
export var TOP_FORM = {
  customerName: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '客户名称',
    props: {
      placeholder: '请输入客户名称'
    }
  }),
  sex: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '性别',
    props: {
      placeholder: '请选择性别'
    },
    options: [{
      label: '男',
      value: 1
    }, {
      label: '女',
      value: 2
    }]
  }),
  age: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '年龄',
    props: {
      placeholder: '请输入年龄'
    }
  }),
  levelId: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '客户等级',
    props: {
      placeholder: '请选择客户等级'
    },
    options: []
  }),
  industry: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '行业',
    props: {
      placeholder: '请输入行业'
    }
  }),
  orgCd: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'cascader',
    label: '部门',
    props: {
      placeholder: '请选择部门'
    },
    options: []
  })
};
export var TABLE_LABEL = [_objectSpread(_objectSpread({
  label: '客户名称',
  prop: 'customerName'
}, PUBLIC_COLUMN_CONFIG), {}, {
  props: _objectSpread({}, PUBLIC_COLUMN_CONFIG)
}), _objectSpread(_objectSpread({
  width: 100,
  label: '性别',
  prop: 'sex'
}, PUBLIC_COLUMN_CONFIG), {}, {
  props: _objectSpread({}, PUBLIC_COLUMN_CONFIG),
  formatter: function formatter(row, column, cellValue) {
    var res = '';

    switch (cellValue) {
      case 0:
        res = '女';
        break;

      case 1:
        res = '男';
        break;

      default:
        res = '未知';
    }

    return res;
  }
}), _objectSpread(_objectSpread({
  width: 100,
  label: '年龄',
  prop: 'age'
}, PUBLIC_COLUMN_CONFIG), {}, {
  props: _objectSpread({}, PUBLIC_COLUMN_CONFIG)
}), _objectSpread(_objectSpread({
  label: '客户等级',
  prop: 'customerLevelName'
}, PUBLIC_COLUMN_CONFIG), {}, {
  props: _objectSpread({}, PUBLIC_COLUMN_CONFIG)
}), _objectSpread(_objectSpread({
  label: '行业',
  prop: 'industryValue'
}, PUBLIC_COLUMN_CONFIG), {}, {
  props: _objectSpread({}, PUBLIC_COLUMN_CONFIG)
}), _objectSpread(_objectSpread({
  label: '机构',
  prop: 'orgName'
}, PUBLIC_COLUMN_CONFIG), {}, {
  props: _objectSpread({}, PUBLIC_COLUMN_CONFIG)
})];
export var TABLE_CONFIG = {
  isSelect: true,
  rowKey: 'customerId',
  reserveSelection: true
};