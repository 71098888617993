import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var PUBLIC_CONFIG = {
  labelWidth: 150,
  col: 10
};
export var FORM_CONFIG = [// {
//   title: '活动对象',
//   children: [
//     {
//       ...PUBLIC_CONFIG,
//       type: 'slot',
//       label: '部门',
//       prop: 'restrictOrgCds',
//     },
//     // V2.4.1 移除
//     // {
//     //   ...PUBLIC_CONFIG,
//     //   type: 'switch',
//     //   label: '人员限制',
//     //   prop: 'isCusRestrict',
//     //   desc: '开启后，只有选定的会员才能在会员小程序看到',
//     //   props: {
//     //     activeValue: 1,
//     //     inactiveValue: 0,
//     //   },
//     // },
//     // {
//     //   ...PUBLIC_CONFIG,
//     //   type: 'slot',
//     //   label: '选择会员',
//     //   prop: 'customerIds',
//     // },
//   ],
// },
{
  title: '预约设置',
  children: [_objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'switch',
    label: '是否可预约',
    prop: 'isAppointment',
    props: {
      activeValue: 1,
      inactiveValue: 0
    }
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '虚拟预约人数',
    prop: 'appointmentPersions',
    desc: '用户端显示预约人数 = 真实预约人数 + 虚拟预约人数',
    props: {
      type: 'number'
    }
  }) // {
  //   ...PUBLIC_CONFIG,
  //   type: 'datePicker',
  //   label: '预约开始时间',
  //   prop: 'appointmentStartTime',
  //   props: {
  //     type: 'datetime',
  //     valueFormat: 'yyyy-MM-dd HH:mm:ss'
  //   },
  // },
  // {
  //   ...PUBLIC_CONFIG,
  //   type: 'datePicker',
  //   label: '预约结束时间',
  //   prop: 'appointmentEndTime',
  //   props: {
  //     type: 'datetime',
  //     valueFormat: 'yyyy-MM-dd HH:mm:ss'
  //   },
  // },
  ]
}, {
  title: '签到设置',
  children: [_objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'switch',
    label: '签到开关',
    prop: 'isSignIn',
    props: {
      activeValue: 1,
      inactiveValue: 0
    }
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'datePicker',
    label: '签到开始时间',
    prop: 'signInStartTime',
    props: {
      type: 'datetime',
      valueFormat: 'yyyy-MM-dd HH:mm:ss'
    }
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'datePicker',
    label: '签到结束时间',
    prop: 'signInEndTime',
    props: {
      type: 'datetime',
      valueFormat: 'yyyy-MM-dd HH:mm:ss'
    }
  })]
}, {
  title: '活动积分',
  children: [_objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'switch',
    label: '活动积分',
    prop: 'isScores',
    props: {
      activeValue: 1,
      inactiveValue: 0
    }
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '评价积分',
    prop: 'commentScores',
    props: {
      type: 'number'
    }
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '签到积分',
    prop: 'signInScores',
    props: {
      type: 'number'
    }
  })]
}, {
  title: '评价设置',
  children: [_objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'switch',
    label: '评价开关',
    prop: 'isComment',
    desc: '开启后，活动详情页操作栏出现评价入口（活动结束后出现）',
    props: {
      activeValue: 1,
      inactiveValue: 0
    }
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '评价模板',
    prop: 'commentTemplateId',
    options: []
  })]
}, {
  title: '分享设置',
  children: [_objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'uploadImage',
    label: '邀请函封面',
    prop: 'invitationCoverImage',
    props: {
      placeholder: '最多可传1张，图片大小不超过2MB，支GIF,JPEG,JPG,PNG,BMP格式',
      fileSize: 2 * 1024,
      fileType: ['GIF', 'JPEG', 'JPG', 'PNG', 'BMP']
    } // rules: [{ required: true, message: '请选择', trigger: 'blur' }]

  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'uploadImage',
    label: '邀请函内容图',
    prop: 'invitationContentImage',
    props: {
      placeholder: '最多可传1张，图片大小不超过2MB，支GIF,JPEG,JPG,PNG,BMP格式',
      fileSize: 2 * 1024,
      fileType: ['GIF', 'JPEG', 'JPG', 'PNG', 'BMP']
    }
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    prop: 'invitationActivityId',
    label: '选择活动',
    options: [],
    props: {
      filterable: true
    }
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'slot',
    prop: 'invitationTextColor',
    label: '字体颜色'
  }), _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'editor',
    label: '内容详情',
    prop: 'invitationContent',
    col: 24
  })]
}];