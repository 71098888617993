import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { FILTER_PROPS, FILTER_FORMS } from './option/filter';
import TABLE_LIST from './option/list'; // 表头

import DynamicForm from '@/components/common/dynamic-form';
import DynamicTable from '@/components/common/dynamic-table';
import { commissionList, exportExcel } from '@api/crm/commissionV3/commissionV3';
import DialogAdd from './components/dialog-add';
import ProportionAdd from '../components/proportion-add';
export default {
  name: 'commissionV3Product',
  created: function created() {},
  components: {
    DynamicForm: DynamicForm,
    DynamicTable: DynamicTable,
    DialogAdd: DialogAdd,
    ProportionAdd: ProportionAdd
  },
  data: function data() {
    return {
      loading: false,
      filterProps: FILTER_PROPS,
      filterForms: FILTER_FORMS,
      query: {},
      currentRow: {},
      // 当前点击行
      table: {
        multiple: true
      },
      // 多选
      headers: TABLE_LIST,
      // 表头
      pagination: {},
      tableData: [],
      showAddDialog: false,
      // 展示新增/编辑弹窗
      showProportionDialog: false,
      // 查看比例规则
      isEdit: false,
      productInfo: {},
      proportionId: null,
      proportionCode: null,
      isAddProduct: false,
      // 判断是否打开新增推介费标识
      examine: false
    };
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
      this.$refs.jTable.pageChange(1);
    },
    // 初始化搜索条件
    initFilter: function initFilter() {
      var query = this.$refs.form.getForm();
      this.query = _objectSpread(_objectSpread({}, this.query), query);
    },
    // 点击规则
    clickRatioName: function clickRatioName(id) {
      this.isAddProduct = false; // 不打开新增推介费

      this.proportionId = id;
      this.showProportionDialog = true;
    },
    // 点击编码
    clickRatioCode: function clickRatioCode(_ref) {
      var id = _ref.id,
          code = _ref.code;
      this.isAddProduct = true;
      this.proportionId = id;
      this.proportionCode = code;
      this.showAddDialog = false; // 关闭新增产品推挤费

      this.showProportionDialog = true;
    },
    // 编码返回事件
    dialogProportionClose: function dialogProportionClose() {
      this.showProportionDialog = false;

      if (this.isAddProduct) {
        this.showAddDialog = true; // 打开新增产品推挤费
      }
    },
    // 新增
    clickEdit: function clickEdit(row) {
      var examine = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      this.isEdit = false;
      this.examine = examine; // 查看

      this.productInfo = {};

      if (row.id) {
        this.isEdit = true;
        this.productInfo = row;
      }

      this.showAddDialog = true;
    },
    // 点击刷新
    clickRefresh: function clickRefresh() {
      this.$refs.jTable.onRefresh();
      this.$message({
        message: '数据已刷新',
        type: 'info',
        duration: 500
      });
    },
    // 点击重置
    clickReset: function clickReset() {
      this.$refs.form.reset();

      if (this.$refs.form.model.category1 === '') {
        this.$refs.form.model.category1 = [];
      }

      if (this.$refs.form.model.category2 === '') {
        this.$refs.form.model.category2 = [];
      }

      if (this.$refs.form.model.category3 === '') {
        this.$refs.form.model.category3 = [];
      }
    },
    // TODO 搜索
    clickSearch: function clickSearch() {
      var _this = this;

      var query = this.$refs.form.getForm();
      this.query = _objectSpread(_objectSpread({}, this.query), query);
      this.$nextTick(function () {
        _this.$refs.jTable.pageChange(1);
      });
    },
    // 导出
    clickExport: function clickExport() {
      var _this2 = this;

      this.$confirm('确定按查询条件导出吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        _this2.initFilter();

        var param = {
          query: _this2.query
        };
        exportExcel(param).then(function (res) {
          _this2.$message({
            message: '导出成功',
            type: 'success'
          });
        }).catch(function (e) {
          _this2.$message({
            message: e,
            type: 'error'
          });
        });
      }).catch(function () {});
    },
    // TODO 选中行
    selectRow: function selectRow(row) {
      this.currentRow = row;
    },
    // 复选框选中
    handleSelectionChange: function handleSelectionChange(rows) {
      console.log(rows);
    },
    // TODO 获取列表
    loadData: function loadData(_ref2) {
      var _this3 = this;

      var _ref2$page = _ref2.page,
          page = _ref2$page === void 0 ? {} : _ref2$page;
      this.initFilter();
      var param = {
        page: page,
        query: this.query
      };
      this.loading = true;
      commissionList(param).then(function (res) {
        _this3.loading = false;
        _this3.tableData = [];

        if (res.data != null) {
          var _res$data = res.data,
              data = _res$data === void 0 ? [] : _res$data,
              _page = res.page;
          var _page$totalCount = _page.totalCount,
              totalCount = _page$totalCount === void 0 ? 0 : _page$totalCount;
          _this3.tableData = data;
          _this3.pagination = {
            total: totalCount
          };
        } else {
          _this3.$message({
            message: '暂无数据',
            type: 'info'
          });
        }
      }).catch(function (e) {
        _this3.loading = false;

        _this3.$message({
          message: '查询列表数据出错',
          type: 'error'
        });
      });
    },
    setStatusColor: function setStatusColor(status) {
      // 合同状态颜色
      var className = ''; // switch (status) {
      //   case STATUS.YES.value:
      //     className = COLOR.GREEN;
      //     break;
      //   case STATUS.NO.value:
      //     className = COLOR.RED;
      //     break;
      // }

      return className;
    }
  }
};