import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var PUBLIC_CONFIG = {
  labelWidth: 100
}; // 弹窗中form表单配置

export var DIALOG_FORM_CONFIG = {
  id: {
    hidden: true
  },
  // 类别名称
  name: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '标签',
    props: {
      placeholder: '请输入标签(不能超过四个字)'
    },
    rules: [{
      required: true,
      message: '标签不能为空',
      trigger: 'blur'
    }, {
      max: 4,
      message: '不能超过4个字',
      trigger: 'blur'
    }]
  }),
  backgroundColor: {
    labelWidth: '150',
    type: 'slot',
    slotName: 'backgroundColor',
    slotType: 'item',
    label: '标签颜色'
  }
};
export var DIALOG_FORM_DATA = {
  id: null,
  name: '',
  backgroundColor: ''
};