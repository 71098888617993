import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from '@/mixins/dialog';
import crmMixin from '@/mixins/crm';
import LOG_TYPE from '@/maps/enum/contract-log-type';
import ATTACHMENT_TYPE from '@/maps/enum/attachment-type';
import DATA_FORMAT_TYPE from '@/maps/enum/data-format-type';
import Attachment from '@/components/business/attachment';
import ContractLog from '@/components/business/contract-log';
import DialogHistory from './dialog-history';
import { setExtendAttribute } from '@/libs/crm/crm';
import { getInfo as _getInfo, remove as removeAPI } from '@api/crm/change/change';
import { getInfo as getBuyInfo, getEarlyInfo } from '@api/crm/buy/buy';
import ElePermission from '@/store/modules/d2admin/modules/elePermission';
export default {
  name: 'change-info',
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {
    Attachment: Attachment,
    DialogHistory: DialogHistory,
    ContractLog: ContractLog
  },
  mixins: [dialogMixin, crmMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      tabActive: 'tabContract',
      //标签框默认选中
      collapseActive: ['1'],
      //信息折叠默认展开
      attachmentType: ATTACHMENT_TYPE.CONTRACT_STAFF,
      //附件类型
      attachmentTypeChange: ATTACHMENT_TYPE.CHANGE_APPLY_STAFF,
      //附件类型
      showLogDialog: false,
      showHistoryDialog: false,
      // 变更历史
      contractLogType: LOG_TYPE.CHANGE,
      attachmentOpened: '',
      //附件打开状态
      oldModel: {} // 原表单值

    };
  },
  computed: {
    hasDeletePermission: function hasDeletePermission() {
      var permission = ElePermission.state.permission; // 新增，不允许删除

      if (!this.model.changeApplyId) return false; // 无权限，不允许删除

      if (!permission || permission.length <= 0) return false; // 变更状态为：已完成，不允许删除

      if (this.model.changeApplyState === '5') return false;
      return permission.includes('Contract-changeDelete');
    }
  },
  created: function created() {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.getInfo();
          this.getBuyInfo();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tabActive = 'tabContract'; // 默认打开第一个标签页

      this.model = {};
      this.oldModel = {};
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.showDialog = false;
    },
    onAttachmentOpen: function onAttachmentOpen() {
      // 初始化标签
      this.tabActive = 'tabContract';
      this.attachmentOpened = 'dialog-position';
    },
    getInfo: function getInfo() {
      var _this = this;

      var changeApplyId = this.propsModel.changeApplyId; // this.loading = true;

      _getInfo({
        changeApplyId: changeApplyId
      }, DATA_FORMAT_TYPE.USRE_SETUP).then(function (res) {
        // this.loading = false;
        var _res$data = res.data,
            data = _res$data === void 0 ? {} : _res$data;
        _this.model = data; // 赎回实物联系方式

        if (res.data.contact != null) {
          var contact = res.data.contact;
          _this.model.contactName = contact.name;
          _this.model.contactMobile = contact.mobile;
          _this.model.contactAddress = contact.address;
        } // null赋给''避免对比提示不完善


        for (var key in _this.model) {
          if (_this.model[key] == null) {
            _this.model[key] = '';
          }
        }

        setExtendAttribute(_this.model); // 包装必要展示字段
      }).catch(function (e) {
        _this.loading = false;
      });
    },
    // 原合同明细
    getBuyInfo: function getBuyInfo() {
      var _this2 = this;

      var _this$propsModel = this.propsModel,
          contractId = _this$propsModel.contractId,
          changeApplyId = _this$propsModel.changeApplyId; // getBuyInfo({ contractId: contractId }, DATA_FORMAT_TYPE.USRE_SETUP)
      //   .then(res => {
      //     let { data = {} } = res;
      //     this.oldModel = data;
      //     // 赎回实物联系方式
      //     if (res.data.contact != null) {
      //       let contact = res.data.contact;
      //       this.oldModel.contactName = contact.name;
      //       this.oldModel.contactMobile = contact.mobile;
      //       this.oldModel.contactAddress = contact.address;
      //     }
      //     // null赋给''避免对比提示不完善
      //     for (let key in this.oldModel) {
      //       if (this.oldModel[key] == null) {
      //         this.oldModel[key] = '';
      //       }
      //     }
      //     setExtendAttribute(this.oldModel); // 包装必要展示字段
      //   })
      //   .catch(e => {
      //     this.loading = false;
      //   });
      // 获取变更前详情信息对比

      getEarlyInfo({
        changeApplyId: changeApplyId
      }, DATA_FORMAT_TYPE.USRE_SETUP).then(function (res) {
        var _res$data2 = res.data,
            data = _res$data2 === void 0 ? {} : _res$data2;
        _this2.oldModel = data; // 赎回实物联系方式

        if (res.data.contact != null) {
          var contact = res.data.contact;
          _this2.oldModel.contactName = contact.name;
          _this2.oldModel.contactMobile = contact.mobile;
          _this2.oldModel.contactAddress = contact.address;
        } // null赋给''避免对比提示不完善


        for (var key in _this2.oldModel) {
          if (_this2.oldModel[key] == null) {
            _this2.oldModel[key] = '';
          }
        }

        setExtendAttribute(_this2.oldModel); // 包装必要展示字段
      }).catch(function (e) {
        _this2.loading = false;
      });
    },
    clickHistory: function clickHistory() {
      // 变更更历史列表
      this.showHistoryDialog = true;
    },
    showLog: function showLog() {
      // 显示审核日志
      this.showLogDialog = true;
    },
    remove: function remove() {
      var _this3 = this;

      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        removeAPI({
          changeApplyId: _this3.model.changeApplyId
        }).then(function () {
          _this3.$message({
            type: 'success',
            message: '删除成功!'
          });

          _this3.dialogClose();

          _this3.$emit('refresh');
        });
      }).catch(function () {});
    }
  }
};