import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { TREE } from './option';
import Emitter from 'element-ui/src/mixins/emitter';
export default {
  name: 'j-select-tree',
  props: {
    value: [String, Number],
    size: String,
    // 树的数据
    options: {
      type: [Array, Object],
      default: function _default() {
        return [];
      }
    },
    // 树的配置
    config: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  mixins: [Emitter],
  data: function data() {
    return {
      isShow: false,
      // 是否展示下拉选项
      currentNode: {},
      // 当前选中node
      currentNodeKey: ''
    };
  },
  inject: {
    elForm: {
      default: ''
    },
    elFormItem: {
      default: ''
    }
  },
  computed: {
    // form-item的size值
    _elFormItemSize: function _elFormItemSize() {
      return (this.elFormItem || {}).elFormItemSize;
    },
    // 当前组件的size值
    selectSize: function selectSize() {
      return this.size || this._elFormItemSize || (this.$ELEMENT || {}).size;
    },
    props: function props() {
      return _objectSpread(_objectSpread({}, TREE), this.config);
    }
  },
  watch: {
    options: {
      handler: function handler(val) {
        var _this = this;

        this.init();
        this.$nextTick(function () {
          // 获取匹配的节点
          _this.currentNode = _this.$refs.tree.getCurrentNode() || {};
        });
      },
      immediate: true
    },
    value: {
      handler: function handler(val) {
        this.currentNodeKey = val;
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {},
    // TODO 下拉切换
    visibleChange: function visibleChange(isShow) {
      this.isShow = isShow;
    },
    // TODO 点击树节点
    handleNodeClick: function handleNodeClick(node) {
      var nodeKey = this.props.nodeKey;
      var value = node[nodeKey];
      this.currentNodeKey = value;
      this.$refs.tree.dispatch('ElDropdown', 'menu-item-click', [node]);
      this.$emit('input', value);
    },
    // TODO 触发选中
    dropdownCommand: function dropdownCommand(node) {
      this.currentNode = node;
    }
  }
};