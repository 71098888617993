import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.keys.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { FILTER_PROPS, FILTER_FORMS } from './option/filter'; // 过滤条件的form

import PERSON_LIST from './option/list'; // 表头

import { listRight, exportExcel } from '@api/rm/person';
import DynamicForm from '@/components/common/dynamic-form';
import DynamicTable from '@/components/common/dynamic-table';
import DialogAdd from './components/dialog-add';
import DialogAccount from './components/dialog-account';
import DialogDimission from './components/dialog-dimission';
import DialogAnewPosition from './components/dialog-anewPosition';
import DialogOrganizationalStructure from './components/dialog-organizationalStructure';
import DialogRole from './components/dialog-role';
import TreeOrganization from '@/components/business/tree-organization';
import ROLE from '@/maps/enum/role';
export default {
  name: 'person',
  components: {
    DynamicForm: DynamicForm,
    DynamicTable: DynamicTable,
    DialogAdd: DialogAdd,
    DialogAccount: DialogAccount,
    DialogDimission: DialogDimission,
    DialogRole: DialogRole,
    TreeOrganization: TreeOrganization,
    DialogAnewPosition: DialogAnewPosition,
    DialogOrganizationalStructure: DialogOrganizationalStructure
  },
  data: function data() {
    return {
      loading: false,
      filename: __filename,
      filterProps: FILTER_PROPS,
      filterForms: FILTER_FORMS,
      tableData: [],
      query: {
        enabled: '1'
      },
      pagination: {},
      headers: PERSON_LIST,
      table: {},
      showAddDialog: false,
      // 展示新增/编辑弹窗
      showAccountDialog: false,
      // 展示账户弹窗
      showDimissionDialog: false,
      // 展示离职弹窗
      showAnewPosition: false,
      //展示重新入职弹窗
      showOrganizationalStructure: false,
      //展示变更组织架构
      showRoleDialog: false,
      // 展示角色弹窗
      treeOrg: {},
      //菜单树
      currentRow: {},
      // 当前点击行
      currentOrg: {} //当前机构

    };
  },
  computed: {
    //树的配置
    treeConfig: function treeConfig() {
      var orgCd = this.query.orgCd;
      return {
        currentNodeKey: orgCd
      };
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
      this.$refs.jTable.pageChange(1);
    },
    //初始化搜索条件
    initFilter: function initFilter() {
      var query = this.$refs.form.getForm();
      this.query = _objectSpread(_objectSpread({}, this.query), query);
    },
    //点击刷新
    clickRefresh: function clickRefresh() {
      this.$refs.jTable.onRefresh();
      this.$message({
        message: '数据已刷新',
        type: 'info',
        duration: 500
      });
    },
    //点击重置
    clickReset: function clickReset() {
      this.$refs.form.reset();
    },
    // TODO 搜索
    clickSearch: function clickSearch() {
      this.initFilter();

      if (this.query.haveCode) {
        this.query.haveCode = '1';
      }

      this.$refs.jTable.pageChange(1);
    },
    // TODO 点击树节点
    treeNodeClick: function treeNodeClick(node) {
      var orgCd = node.orgCd;
      var query = this.$refs.form.getForm();
      this.query = _objectSpread(_objectSpread(_objectSpread({}, this.query), query), {}, {
        orgCd: orgCd
      });
      this.currentOrg = node;
      this.treeOrg = this.$refs.tree.treeData[0];
      this.tableData = [];
      this.$refs.jTable.pageChange(1);
    },
    // TODO 获取列表
    loadData: function loadData(_ref) {
      var _this = this;

      var _ref$page = _ref.page,
          page = _ref$page === void 0 ? {} : _ref$page;
      this.loading = true;
      this.initFilter();
      listRight({
        page: page,
        query: this.query
      }).then(function (res) {
        _this.loading = false;
        _this.tableData = [];

        if (res.data != null) {
          var _res$data = res.data,
              data = _res$data === void 0 ? [] : _res$data,
              _page = res.page;
          var _page$totalCount = _page.totalCount,
              totalCount = _page$totalCount === void 0 ? 0 : _page$totalCount;
          _this.tableData = data;
          _this.pagination = {
            total: totalCount
          };
        } else {
          _this.$message({
            message: '暂无数据',
            type: 'info'
          });
        }
      }).catch(function (e) {
        _this.loading = false;

        _this.$message({
          message: '查询列表数据出错',
          type: 'error'
        });
      });
    },
    // TODO 选中行
    selectRow: function selectRow(row) {
      // console.log('row', row);
      this.currentRow = row;
      this.$refs.tree.setCheckedKeys(row.orgCd); //展开所在架构
    },
    // TODO 点击新增
    clickEdit: function clickEdit() {
      var row = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.currentRow = row;
      this.showAddDialog = true;
    },
    //分配权限
    clickSetRole: function clickSetRole() {
      var row = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      this.currentRow = row;
      this.showRoleDialog = true;
    },
    // TODO 点击导出
    clickExport: function clickExport() {
      var _this2 = this;

      this.$confirm('按此查询条件导出数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        if (_this2.currentOrg.value != null) {
          _this2.query.orgCd = _this2.currentOrg.value;
        }

        var param = {
          query: _this2.query
        };
        exportExcel(param).catch(function (e) {
          _this2.$message({
            message: e,
            type: 'error'
          });
        });
      });
    },
    // TODO 点击账号密码
    clickAccount: function clickAccount() {
      if (Object.keys(this.currentRow).length === 0) {
        this.$message('请选择职员');
      } else {
        this.showAccountDialog = true;
      }
    },
    // TODO 点击离职
    clickDimission: function clickDimission() {
      if (Object.keys(this.currentRow).length === 0) {
        this.$message('请选择职员');
      } else if (this.currentRow.enabled == '0') {
        this.$message('职员已离职');
      } else {
        this.showDimissionDialog = true;
      }
    },
    // TODO 重新入职
    clickAnewPosition: function clickAnewPosition() {
      // console.log('重新入职');
      console.log(this.currentRow);

      if (Object.keys(this.currentRow).length === 0) {
        this.$message('请选择职员');
      } else if (this.currentRow.enabled == '1') {
        this.$message('职员在职');
      } else {
        this.showAnewPosition = true;
      }
    },
    clickOrganizationalStructure: function clickOrganizationalStructure() {
      // console.log('更改组织架构');
      console.log(this.currentRow);

      if (Object.keys(this.currentRow).length === 0) {
        this.$message('请选择职员');
      } else {
        this.showOrganizationalStructure = true;
      }
    }
  },
  created: function created() {}
};