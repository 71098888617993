import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
var PUBLIC_CONFIG = {
  labelWidth: 100
};
export var TOP_FORM = {
  activityTitle: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '活动标题',
    props: {
      placeholder: '请输入活动标题'
    }
  }),
  orgCd: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    label: '主办方',
    type: 'cascader',
    options: [],
    props: {
      placeholder: '请选择主办方',
      filterable: true,
      // showAllLevels: false,
      props: {
        checkStrictly: true,
        emitPath: false,
        value: 'orgCd',
        label: 'orgName',
        children: 'children'
      }
    }
  }),
  activityType: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '活动类型',
    props: {
      placeholder: '请选择活动类型'
    },
    options: []
  }),
  classifyId: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '活动分类',
    props: {
      placeholder: '请选择活动分类'
    },
    options: []
  }),
  createTime: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'datePicker',
    label: '创建时间',
    propGroup: ['startTime', 'endTime'],
    props: {
      type: 'daterange'
    }
  })
};
export var TABLE_LABEL = [{
  label: '活动主图',
  prop: 'mainPicture',
  type: 'img',
  formatter: function formatter(row, column, cellValue) {
    return "".concat(process.env.VUE_APP_CLOUD_UPLOAD).concat(row.mainPicture);
  }
}, {
  label: '活动标题',
  prop: 'activityTitle'
}, {
  label: '活动类型',
  prop: 'activityTypeText'
}, {
  label: '活动分类',
  prop: 'classifyName'
}, {
  label: '创建时间',
  sortable: 'createTime',
  prop: 'createTime',
  sortProp: 'createTime'
}, {
  label: '主办方',
  prop: 'orgCdText'
}, {
  label: '备注',
  prop: 'remark'
}, {
  type: 'operation',
  label: '操作',
  width: 200,
  formatter: function formatter() {
    var arr = [{
      type: 'audit',
      label: '审核',
      permission: 'activity-audit'
    }];
    return arr;
  }
}];
export var TABLE_CONFIG = {
  isSelect: true,
  actions: [{
    label: '批量审核',
    type: 'batchAudit',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'activity-audit'
  }],
  defaultSort: {
    prop: 'createTime',
    order: 'descending'
  }
};