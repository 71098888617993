import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.slice.js";
var PUBLIC_CONFIG = {
  labelWidth: 80
};
export var TOP_FORM = {
  name: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '标题',
    props: {
      placeholder: '请输入标题'
    }
  }),
  informationType: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '类型',
    props: {
      placeholder: '请选择类型'
    },
    options: [{
      label: '平台文章',
      value: 1
    }, {
      label: '外部链接',
      value: 2
    }, {
      label: '视频',
      value: 3
    }]
  }),
  classifyId: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'cascader',
    label: '所属分类',
    props: {
      placeholder: '请选择所属分类',
      props: {
        label: 'name',
        value: 'id',
        children: 'children'
      }
    },
    options: []
  }),
  // organizationId: {
  //   ...PUBLIC_CONFIG,
  //   type: 'select',
  //   label: '发布组织',
  //   props: {
  //     placeholder: '请选择发布组织',
  //   },
  //   options: [],
  // },
  releaseTime: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'datePicker',
    label: '发布时间',
    propGroup: ['startReleaseTime', 'endReleaseTime'],
    props: {
      type: 'daterange'
    }
  }),
  isTop: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '是否置顶',
    props: {
      placeholder: '请选择是否置顶'
    },
    options: [{
      label: '是',
      value: 1
    }, {
      label: '否',
      value: 0
    }]
  }),
  releaseStatus: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '发布状态',
    props: {
      placeholder: '请选择状态'
    },
    options: [{
      label: '已发布',
      value: 1
    }, {
      label: '草稿',
      value: 2
    }]
  }),
  status: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '状态',
    props: {
      placeholder: '请选择状态'
    },
    options: [{
      label: '启用',
      value: 1
    }, {
      label: '禁用',
      value: 0
    }]
  })
};
export var TABLE_LABEL = [{
  label: '标题',
  prop: 'name',
  type: 'link'
}, {
  label: '类型',
  prop: 'informationType',
  formatter: function formatter(row, column, cellValue) {
    var res = '';

    switch (Number(cellValue)) {
      case 1:
        res = '平台文章';
        break;

      case 2:
        res = '外部链接';
        break;

      case 3:
        res = '视频';
        break;

      default:
        res = '';
    }

    return res;
  }
}, {
  label: '所属分类',
  prop: 'classifyName',
  formatter: function formatter(row, column, cellValue) {
    /** 如果分类超过两个 用...省略 */
    if (cellValue) {
      var arr = cellValue.split(',');

      if (arr.length > 2) {
        return "".concat(arr.slice(0, 2).join(','), "...");
      } else {
        return cellValue;
      }
    }
  }
}, {
  type: 'link',
  label: '阅读量',
  prop: 'readViews'
}, {
  label: '发布时间',
  prop: 'releaseTime',
  sortable: 'custom',
  sortProp: 'releaseTime'
}, {
  type: 'switch',
  label: '是否置顶',
  prop: 'isTop',
  props: {
    activeValue: 1,
    inactiveValue: 0,
    activeText: '是',
    inactiveText: '否'
  }
}, {
  type: 'inputNumber',
  label: '排序值',
  prop: 'sort',
  width: 150
}, {
  label: '发布状态',
  prop: 'releaseStatus',
  width: 150,
  formatter: function formatter(row, column, cellValue) {
    var res = '';

    switch (Number(cellValue)) {
      case 1:
        res = '已发布';
        break;

      case 2:
        res = '草稿';
        break;

      default:
        res = '';
    }

    return res;
  }
}, {
  label: '状态',
  prop: 'status',
  formatter: function formatter(row, column, cellValue) {
    var res = '';

    switch (Number(cellValue)) {
      case 0:
        res = '停用';
        break;

      case 1:
        res = '启用';
        break;

      default:
        res = '';
    }

    return res;
  }
}, {
  type: 'operation',
  label: '操作',
  width: 200,
  formatter: function formatter(row) {
    var arr = [{
      type: 'edit',
      label: '修改',
      permission: 'info-update'
    }];

    if (row.status === 1) {
      arr.push({
        type: 'stop',
        label: '停用',
        permission: 'info-status'
      });
    } else {
      arr.push({
        type: 'start',
        label: '启用',
        permission: 'info-status'
      });
    }

    arr.push({
      type: 'delete',
      label: '删除',
      permission: 'info-delete'
    });
    return arr;
  }
}];
export var TABLE_CONFIG = {
  isSelect: false,
  actions: [{
    label: '新增资讯',
    type: 'addInfo',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'info-add'
  }, {
    label: '新增视频',
    type: 'addVideo',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'info-addVideo'
  }],
  defaultSort: {
    prop: 'releaseTime',
    order: 'descending'
  }
};