import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
import { TOP_FORM, TABLE_CONFIG, TABLE_LABEL } from './config/list';
import url from '@/api-new/url';
import api from '@/api-new';
import { getHeaders } from '@/api-new/service'; // 获取请求头

import { getOrgTree } from '@/api/rm/org';
export default {
  // 礼品分类
  name: 'marketingCampaignDetails',
  data: function data() {
    return {
      topForm: TOP_FORM,
      filtersData: {},
      tableConfig: TABLE_CONFIG,
      tableLabel: TABLE_LABEL,
      httpConfig: {},
      detalisId: ''
    };
  },
  created: function created() {
    this.init();
    this.getAllOrganization();
  },
  computed: {},
  methods: {
    init: function init() {
      var id = this.$route.query.id;
      this.detalisId = id;
      var host = url.host,
          apiPrev = url.apiPrev;
      var apiUrl = url.marketingCampaign.manage; //获取列表数据

      this.httpConfig = {
        url: "".concat(host).concat(apiPrev).concat(apiUrl.getMarketingActivityDetailsPage),
        headers: getHeaders()
      };
    },

    /* 获取组织下拉 */
    getAllOrganization: function getAllOrganization() {
      var _this = this;

      getOrgTree({}).then(function (res) {
        TOP_FORM.departmentId.options = _this.formatChildData([res.data], 'children');
      });
    },
    // 获取下级组织
    formatChildData: function formatChildData(data, childStr) {
      if (data && data.length) {
        data.forEach(function (val) {
          if (val[childStr] && val[childStr].length < 1) {
            delete val[childStr];
          } else {
            this.formatChildData(val[childStr], childStr);
          }
        }.bind(this));
        return data;
      }
    },

    /** 接口参数过滤函数 */
    filtersFormatter: function filtersFormatter(params) {
      // console.log('params', params);
      var beginTime = params.beginTime,
          endTime = params.endTime;
      delete params.createTime;
      return _objectSpread(_objectSpread({}, params), {}, {
        beginTime: beginTime ? "".concat(beginTime, " 00:00:00") : null,
        endTime: endTime ? "".concat(endTime, " 23:59:59") : null,
        marketingActivityId: this.detalisId // 添加 id

      });
    },

    /** 表格数据改变 */
    tableRowChange: function tableRowChange(params) {
      var _ref = params || {},
          prop = _ref.prop,
          row = _ref.row;

      switch (prop) {
        case 'sort':
          // this.Sort(row);
          break;
      }
    },
    // 表格点击事件
    tableClick: function tableClick(params) {
      console.log('params', params);

      var _ref2 = params || {},
          clickItem = _ref2.clickItem,
          selections = _ref2.selections;

      var _ref3 = clickItem || {},
          type = _ref3.type;

      if (type === 'add') {
        this.$router.push({
          name: 'marketingCampaignAdd'
        });
      }
    },

    /** 点击表格项 */
    tableRowClick: function tableRowClick(action) {
      var _ref4 = action || {},
          clickItem = _ref4.clickItem,
          row = _ref4.row,
          type = _ref4.type;

      if (type === 'operation') {}
    }
  }
};