// 推介费类型
export default {
  COMMON: {
    value: '1',
    text: '常规'
  },
  SUSPEND: {
    value: '2',
    text: '提前终止'
  },
  OTHER: {
    value: '3',
    text: '额外方案申请'
  },
  TRANSFER: {
    value: '4',
    text: '转让型合同'
  },
  EARLY: {
    value: '5',
    text: '其他（2021年前）'
  },
  EXPEND: {
    value: '6',
    text: '调整期限补贴'
  }
};