import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from "@/mixins/dialog";
import DialogUpload from "./dialog-upload";
import { appendFile } from "@api/crm/buy/visit";
export default {
  name: "buy-visit-service-append",
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {
    DialogUpload: DialogUpload
  },
  mixins: [dialogMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      showUploadDialog: false,
      // 展示上传对话框
      collapseActive: ["1"],
      //信息折叠默认展开
      selectData: {
        audit: []
      },
      applyId: ""
    };
  },
  computed: {},
  created: function created() {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.$set(this.model, "applyId", this.propsModel.id);
        } else {
          this.model = {};
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {//this.$refs.form.resetFields();
    },
    // TODO 确定
    confirm: function confirm() {
      var _this = this;

      var param = _objectSpread({}, this.model);

      this.loading = true;
      appendFile(param).then(function (res) {
        _this.loading = false;
        _this.showDialog = false;

        _this.$emit("refresh");
      }).catch(function (e) {
        _this.loading = false;
      });
    },
    clickUpload: function clickUpload() {
      this.showUploadDialog = true;
      this.applyId = this.propsModel.id;
    },
    uploadSuccess: function uploadSuccess(val) {
      this.$message({
        message: "录音已上传",
        type: "success"
      });
      this.model.fileName = val.name;
      this.model.filePath = val.path; //直接调用保存

      this.confirm();
    }
  }
};