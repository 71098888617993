import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dialogMixin from '@/mixins/dialog';
import crmMixin from '@/mixins/crm';
import LOG_TYPE from '@/maps/enum/contract-log-type';
import ATTACHMENT_TYPE from '@/maps/enum/attachment-type';
import AUDIT from '@/maps/enum/status/audit';
import DATA_FORMAT_TYPE from '@/maps/enum/data-format-type';
import Attachment from '@/components/business/attachment';
import ContractLog from '@/components/business/contract-log';
import { setExtendAttribute } from '@/libs/crm/crm';
import { getInfo as getBuyInfo, getEarlyInfo } from '@api/crm/buy/buy';
import { getInfo as _getInfo, auditService } from '@api/crm/change/change';
export default {
  name: 'change-service-audit',
  props: {
    propsModel: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  components: {
    Attachment: Attachment,
    ContractLog: ContractLog
  },
  mixins: [dialogMixin, crmMixin],
  data: function data() {
    return {
      loading: false,
      model: {},
      // 表单值
      formModel: {},
      // 表单
      tabActive: 'tabContract',
      // 标签框默认选中
      collapseActive: ['1'],
      // 信息折叠默认展开
      attachmentType: ATTACHMENT_TYPE.CONTRACT_STAFF,
      // 附件类型
      attachmentTypeChange: ATTACHMENT_TYPE.CHANGE_APPLY_STAFF,
      // 附件类型
      showLogDialog: false,
      contractLogType: LOG_TYPE.CHANGE,
      attachmentOpened: '',
      // 附件打开状态
      rules: {},
      oldModel: {} // 原表单值

    };
  },
  computed: {},
  watch: {
    showDialog: {
      handler: function handler(val) {
        if (val) {
          this.init();
          this.getInfo();
          this.getBuyInfo();
        }
      },
      immediate: true
    }
  },
  methods: {
    init: function init() {
      this.loading = false;
      this.tabActive = 'tabContract'; // 默认打开第一个标签页
    },
    // TODO 弹窗关闭
    dialogClose: function dialogClose() {
      this.$refs.form.resetFields();
    },
    onAttachmentOpen: function onAttachmentOpen() {
      // 初始化标签
      this.tabActive = 'tabContract';
      this.attachmentOpened = 'dialog-position';
    },
    clickAuditFail: function clickAuditFail() {
      // 审核不通过
      if (this.formModel.advice == null || this.formModel.advice == '') {
        this.$message('请填写审核意见');
      } else {
        this.audit(AUDIT.NO.value);
      }
    },
    clickAudit: function clickAudit() {
      // 审核通过
      this.audit(AUDIT.YES.value);
    },
    // TODO 确定
    audit: function audit(result) {
      var _this = this;

      this.$refs.form.validate(function (valid) {
        if (valid) {
          var param = {
            changeApplyId: _this.model.changeApplyId,
            audit: result,
            advice: _this.formModel.advice
          };
          _this.loading = true;
          auditService(param).then(function (res) {
            _this.loading = false;
            _this.showDialog = false;

            _this.$emit('refresh');
          }).catch(function (e) {
            _this.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    // TODO 获取详情
    getInfo: function getInfo() {
      var _this2 = this;

      var changeApplyId = this.propsModel.changeApplyId; // this.loading = true;

      _getInfo({
        changeApplyId: changeApplyId
      }, DATA_FORMAT_TYPE.USRE_SETUP).then(function (res) {
        // this.loading = false;
        var _res$data = res.data,
            data = _res$data === void 0 ? {} : _res$data;
        _this2.model = data; // 赎回实物联系方式

        if (res.data.contact != null) {
          var contact = res.data.contact;
          _this2.model.contactName = contact.name;
          _this2.model.contactMobile = contact.mobile;
          _this2.model.contactAddress = contact.address;
        } // null赋给''避免对比提示不完善


        for (var key in _this2.model) {
          if (_this2.model[key] == null) {
            _this2.model[key] = '';
          }
        }

        setExtendAttribute(_this2.model); // 包装必要展示字段
      }).catch(function (e) {
        _this2.loading = false;
      });
    },
    // 原合同明细
    getBuyInfo: function getBuyInfo() {
      var _this3 = this;

      var _this$propsModel = this.propsModel,
          contractId = _this$propsModel.contractId,
          changeApplyId = _this$propsModel.changeApplyId; // getBuyInfo({ contractId: contractId }, DATA_FORMAT_TYPE.USRE_SETUP)
      //   .then(res => {
      //     let { data = {} } = res;
      //     this.oldModel = data;
      //     // 赎回实物联系方式
      //     if (res.data.contact != null) {
      //       let contact = res.data.contact;
      //       this.oldModel.contactName = contact.name;
      //       this.oldModel.contactMobile = contact.mobile;
      //       this.oldModel.contactAddress = contact.address;
      //     }
      //     // null赋给''避免对比提示不完善
      //     for (let key in this.oldModel) {
      //       if (this.oldModel[key] == null) {
      //         this.oldModel[key] = '';
      //       }
      //     }
      //     setExtendAttribute(this.oldModel); // 包装必要展示字段
      //   })
      //   .catch(e => {
      //     this.loading = false;
      //   });
      // 获取变更前详情信息对比

      getEarlyInfo({
        changeApplyId: changeApplyId
      }, DATA_FORMAT_TYPE.USRE_SETUP).then(function (res) {
        var _res$data2 = res.data,
            data = _res$data2 === void 0 ? {} : _res$data2;
        _this3.oldModel = data; // 赎回实物联系方式

        if (res.data.contact != null) {
          var contact = res.data.contact;
          _this3.oldModel.contactName = contact.name;
          _this3.oldModel.contactMobile = contact.mobile;
          _this3.oldModel.contactAddress = contact.address;
        } // null赋给''避免对比提示不完善


        for (var key in _this3.oldModel) {
          if (_this3.oldModel[key] == null) {
            _this3.oldModel[key] = '';
          }
        }

        setExtendAttribute(_this3.oldModel); // 包装必要展示字段
      }).catch(function (e) {
        _this3.loading = false;
      });
    },
    showLog: function showLog() {
      // 显示审核日志
      this.showLogDialog = true;
    }
  }
};