import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var PUBLIC_CONFIG = {
  labelWidth: 100
}; // 弹窗中form表单配置

export var DIALOG_FORM_CONFIG = {
  id: {
    hidden: true
  },
  // 标题
  name: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '标题',
    props: {
      placeholder: '请输入标题'
    },
    rules: [{
      required: true,
      message: '标题不能为空',
      trigger: 'blur'
    }]
  }),
  classifyId: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'cascader',
    label: '分类',
    props: {
      props: {
        label: 'name',
        value: 'id',
        children: 'children',
        checkStrictly: true
      }
    },
    options: []
  }),
  materialId: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '视频',
    options: []
  }),
  releaseTime: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'slot',
    label: '发布时间',
    slotName: 'release'
  }),
  status: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'switch',
    label: '状态',
    props: {
      activeValue: 1,
      inactiveValue: 0
    }
  })
};
export var DIALOG_FORM_DETAIL = {
  id: {
    hidden: true
  },
  // 标题
  name: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '标题',
    props: {
      placeholder: '请输入标题',
      readonly: true
    }
  }),
  classifyId: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'cascader',
    label: '分类',
    props: {
      props: {
        label: 'name',
        value: 'id',
        children: 'children'
      },
      disabled: true
    },
    options: []
  }),
  materialId: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '视频',
    props: {
      disabled: true
    },
    options: []
  }),
  releaseTime: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    label: '发布时间'
  }),
  status: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'switch',
    label: '状态',
    props: {
      disabled: true,
      activeValue: 1,
      inactiveValue: 0
    }
  })
};
export var DIALOG_FORM_DATA = {
  id: null,
  name: '',
  classifyId: '',
  materialId: '',
  status: '1'
};