import "core-js/modules/es.object.to-string.js";
// 变更
import request, { blobRequest } from '@/plugin/axios';
import { formatDate, formatUserDataPromise } from '@/libs/crm/format';
import func from '@/maps/url/crm/change/change';
import util from '@/libs/util'; // TODO 合同是否可申请变更

export function isContractCanChange(params) {
  var re = request.post(func.isContractCanChange, {
    body: params
  });
  return re;
} // TODO 保存

export function save(params) {
  var re = request.post(func.save, {
    body: params
  });
  return re;
} // TODO 提交

export function submit(params) {
  var re = request.post(func.submit, {
    body: params
  });
  return re;
} // TODO 删除

export function remove(params) {
  var re = request.post(func.remove, {
    body: params
  });
  return re;
} // TODO 客服审核

export function auditService(params) {
  var re = request.post(func.auditService, {
    body: params
  });
  return re;
} // TODO 结算审核

export function auditFinance(params) {
  var re = request.post(func.auditFinance, {
    body: params
  });
  return re;
} // TODO 资金审核

export function auditMoney(params) {
  var re = request.post(func.auditMoney, {
    body: params
  });
  return re;
} // TODO 列表

export function list(params, formatType) {
  var re = request.post(func.list, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // TODO 导出Excel

export function exportExcel(params) {
  var date = formatDate(new Date());
  return blobRequest.post(func.exportExcel, {
    body: params
  }).then(function (blob) {
    util.download(blob, '变更' + date + '.xlsx');
  }).catch(function (e) {
    return Promise.reject(e);
  });
} // TODO 详情

export function getInfo(params, formatType) {
  var re = request.post(func.getInfo, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
} // 历史列表

export function history(params, formatType) {
  var re = request.post(func.history, {
    body: params
  });
  formatUserDataPromise(re, formatType); //用户数据格式化

  return re;
}