





























































































































































































































































































































export default function (Component) {
  Component.options.__source = "src/views/crm/buy/search/index.vue"
}
