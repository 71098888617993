import PRODUCT_CATEGORY1 from '@/maps/enum/product-category1';
import PRODUCT_CATEGORY2 from '@/maps/enum/product-category2';
import PRODUCT from '@/maps/enum/product';
export default {
  computed: {
    // 是否是产品1004积存1
    isProduct1004: function isProduct1004() {
      var rs = false;

      if (this.model.productId == PRODUCT.PRODUCT_1004) {
        rs = true;
      }

      return rs;
    },
    // 是否是产品1021积存3
    isProduct1021: function isProduct1021() {
      var rs = false;

      if (this.model.productId == PRODUCT.PRODUCT_1021) {
        rs = true;
      }

      return rs;
    },
    // 是否私募产品
    isProductCategorySM: function isProductCategorySM() {
      var rs = false;

      if (this.model.hasOwnProperty('productCategory1') && this.model.productCategory1 == PRODUCT_CATEGORY1.SM) {
        rs = true;
      }

      return rs;
    },
    // 是否有购销合同编号
    isHaveContractBuyCd: function isHaveContractBuyCd() {
      var rs = false; // 非黄金类不展示

      if (this.model.productCountType === '3') return false;

      if (this.model.hasOwnProperty('buyContractCd') && this.model.buyContractCd != null && this.model.buyContractCd != '') {
        rs = true;
      }

      return rs;
    },
    // 是否有克重
    isHaveGoldWeight: function isHaveGoldWeight() {
      var rs = false;

      if (this.model.hasOwnProperty('contractGoldWeight') && this.model.contractGoldWeight != null && parseFloat(this.model.contractGoldWeight) > 0) {
        rs = true;
      }

      return rs;
    },
    // 是否有预期收益
    isHaveExpectEarnings: function isHaveExpectEarnings() {
      var rs = false;

      if (PRODUCT_CATEGORY1.SM != this.model.productCategory1 && this.model.hasOwnProperty('expectEarnings') && this.model.expectEarnings != null && parseFloat(this.model.expectEarnings) > 0) {
        rs = true;
      }

      return rs;
    },
    // 是否有预期收益
    isHaveExpectEarningsNew: function isHaveExpectEarningsNew() {
      var rs = false;

      if (this.model.hasOwnProperty('expectEarnings') && this.model.expectEarnings != null && parseFloat(this.model.expectEarnings) > 0) {
        rs = true;
      }

      return rs;
    },
    // 是否有预期现金流
    isHaveExpectCache: function isHaveExpectCache() {
      var rs = false;

      if (PRODUCT_CATEGORY1.SM != this.model.productCategory1 && this.model.hasOwnProperty('expectCache') && this.model.expectCache != null && parseFloat(this.model.expectCache) > 0) {
        rs = true;
      }

      return rs;
    },
    // 是否有预期现金流--2024/12/23
    isHaveExpectCacheNew: function isHaveExpectCacheNew() {
      var rs = true;
      console.log(this.model, 'model');

      if (this.model.productCountType === '3' && (this.model.productCategory2 === PRODUCT_CATEGORY2.GQ || this.model.productCategory2 === PRODUCT_CATEGORY2.DYZJXT || this.model.productCategory2 === PRODUCT_CATEGORY2.JD)) {
        rs = false;
      }

      return rs;
    },
    // 是否按实际结算日
    isHaveActualEndDate: function isHaveActualEndDate() {
      var rs = false;

      if (this.model.actualEndDate != null && this.model.actualEndDate != '') {
        rs = true;
      }

      return rs;
    },
    // 是否按收益率计算，没有总预期收益，则按收益率
    isHaveAnnualRate: function isHaveAnnualRate() {
      var rs = false; //  if ((!this.model.hasOwnProperty("expectEarnings") || this.model.expectEarnings == null) &&
      //      this.model.hasOwnProperty("annualRate") && this.model.annualRate != null && parseFloat(this.model.annualRate) > 0) {
      //      rs = true;
      //  }

      return rs;
    },
    // 是否是续约
    isRenew: function isRenew() {
      var rs = false;

      if (this.model.hasOwnProperty('originalContractId') && this.model.originalContractId != null && this.model.originalContractId != '') {
        rs = true;
      }

      return rs;
    },
    // 是否为珠宝现货
    isJewelry: function isJewelry() {
      var is = false;

      if (this.model.hasOwnProperty('applyType') && (this.model.applyType == '02' || this.model.applyType == '03' || this.model.applyType == '04')) {
        is = true;
      }

      return is;
    },
    // 是否有联系人
    isHaveContact: function isHaveContact() {
      var is = false;

      if (this.model.hasOwnProperty('contact') && this.model.contact != null && this.model.rentRansomType == '1') {
        is = true;
      }

      return is;
    },
    // 合同编号标题
    labelContractCd: function labelContractCd() {
      var lalel = '合同编号';

      if (this.model.hasOwnProperty('productCategory2') && (this.model.productCategory2 == PRODUCT_CATEGORY2.HJWT || this.model.productCategory2 == PRODUCT_CATEGORY2.HJXLS)) {
        lalel = '委托协议编号';
      } else if (this.model.productCategory2 == PRODUCT_CATEGORY2.HJCZ) {
        lalel = '出租合同号';
      }

      return lalel;
    },
    // 购销合同编号标题
    labelBuyContractCd: function labelBuyContractCd() {
      var lalel = '购销合同';

      if (this.model.hasOwnProperty('productCategory2') && (this.model.productCategory2 == PRODUCT_CATEGORY2.HJWT || this.model.productCategory2 == PRODUCT_CATEGORY2.HJXLS)) {
        lalel = '合同编号';
      } else if (this.model.productId == PRODUCT.PRODUCT_4003) {
        lalel = '买卖合同号';
      }

      return lalel;
    },
    // 预期现金流标题
    labelExpectCache: function labelExpectCache() {
      var lalel = '预期现金流';

      if (this.model.hasOwnProperty('productCategory2') && (this.model.productCategory2 == PRODUCT_CATEGORY2.HJWT || this.model.productCategory2 == PRODUCT_CATEGORY2.HJXLS)) {
        if (this.model.rentRansomType == '1') {
          lalel = '预期收益';
        } else if (this.model.rentRansomType == '2') {
          lalel = '现金价值与预期收益';
        }
      }

      return lalel;
    }
  }
};