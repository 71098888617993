import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { TOP_FORM, TABLE_CONFIG, TABLE_LABEL, FORM_CONFIG } from './config/list';
import url from '@/api-new/url';
import api from '@/api-new';
import { getHeaders } from '@/api-new/service'; // 获取请求头

export default {
  // 礼品分类
  name: 'giftClassify',
  data: function data() {
    return {
      topForm: TOP_FORM,
      filtersData: {},
      tableConfig: TABLE_CONFIG,
      tableLabel: TABLE_LABEL,
      httpConfig: {},
      visible: false,
      addFormConfig: FORM_CONFIG,
      addFormData: {},
      titles: ''
    };
  },
  created: function created() {
    this.init();
  },
  computed: {},
  methods: {
    /** 接口参数过滤函数 */
    filtersFormatter: function filtersFormatter(params) {
      var beginCreateTime = params.beginCreateTime,
          endCreateTime = params.endCreateTime;
      delete params.createTime;
      return _objectSpread(_objectSpread({}, params), {}, {
        beginCreateTime: beginCreateTime ? "".concat(beginCreateTime, " 00:00:00") : null,
        endCreateTime: endCreateTime ? "".concat(endCreateTime, " 23:59:59") : null
      });
    },
    // 表格点击事件
    tableClick: function tableClick(params) {
      var _ref = params || {},
          clickItem = _ref.clickItem,
          selections = _ref.selections;

      var _ref2 = clickItem || {},
          type = _ref2.type;

      if (type === 'add') {
        this.titles = '新增礼品分类';
        this.visible = true;
      }
    },

    /** 点击表格项 */
    tableRowClick: function tableRowClick(action) {
      var _ref3 = action || {},
          clickItem = _ref3.clickItem,
          row = _ref3.row,
          type = _ref3.type;

      if (type === 'operation') {
        if (clickItem.type === 'compile') {
          this.titles = '编辑礼品分类'; // 编辑

          this.upcompile(row);
        }

        if (clickItem.type === 'stop') {
          // 停用
          this.upStopStart(clickItem.type, row);
        }

        if (clickItem.type === 'start') {
          // 启用
          this.upStopStart(clickItem.type, row);
        }
      }
    },
    // 编辑
    upcompile: function upcompile(row) {
      var _this = this;

      // 获取详情
      api.GIFTALASSI_GETINFOBYID({
        id: row.id
      }).then(function (res) {
        _this.visible = true;

        _this.$set(_this.addFormData, 'picture', {
          url: res.picture
        });

        _this.$set(_this.addFormData, 'classificationName', res.classificationName);

        _this.$set(_this.addFormData, 'sort', res.sort);

        _this.$set(_this.addFormData, 'remark', res.remark);

        _this.$set(_this.addFormData, 'id', res.id);
      }).catch(function (error) {
        console.log(error);
      });
    },
    // 停用启用
    upStopStart: function upStopStart(type, row) {
      var _this2 = this;

      var params = {};
      params.id = row.id;

      if (type === 'stop') {
        params.status = 0;
      } else if (type === 'start') {
        params.status = 1;
      }

      api.GIFTALASSI_UPDATESTATUS(params).then(function (res) {
        var _this2$$refs$jPage;

        (_this2$$refs$jPage = _this2.$refs.jPage) === null || _this2$$refs$jPage === void 0 ? void 0 : _this2$$refs$jPage.getPageData();

        _this2.$message({
          message: '操作成功!',
          type: 'success'
        });

        _this2.visible = false;
      }).catch(function (error) {
        console.log(error);
      });
    },
    // 新增关闭事件
    closejForm: function closejForm() {
      this.visible = false;

      if (this.addFormData.id) {
        this.addFormData = {};
      }
    },

    /** 触发表单提交 */
    FormSubmit: function FormSubmit(model) {
      var _this3 = this;

      // 判断有无id 有id是编辑 无提交
      this.submiting = true;
      var arr = [];
      var data = {};

      if (model.picture.path) {
        arr = model.picture.path.split('om');
        var sort = model.sort,
            remark = model.remark,
            classificationName = model.classificationName;
        data = {
          sort: sort,
          remark: remark,
          classificationName: classificationName,
          picture: arr[1]
        };
      } else {
        data = _objectSpread(_objectSpread({}, model), {}, {
          picture: model.picture.url
        });
      }

      if (model.id) {
        data.id = model.id;
        api.GIFTALASSI_UPDATE(data).then(function (res) {
          var _this3$$refs$jPage;

          _this3.submiting = false;

          _this3.$message({
            message: '编辑成功!',
            type: 'success'
          });

          (_this3$$refs$jPage = _this3.$refs.jPage) === null || _this3$$refs$jPage === void 0 ? void 0 : _this3$$refs$jPage.getPageData();
          _this3.addFormData = {};
          _this3.visible = false;
        }).catch(function (error) {
          _this3.submiting = false;
          console.log(error);
        });
      } else {
        api.GIFTALASSI_ADD(data).then(function (res) {
          var _this3$$refs$jPage2;

          _this3.submiting = false;

          _this3.$message({
            message: '新增成功!',
            type: 'success'
          });

          (_this3$$refs$jPage2 = _this3.$refs.jPage) === null || _this3$$refs$jPage2 === void 0 ? void 0 : _this3$$refs$jPage2.getPageData();
          _this3.visible = false;
          _this3.addFormData = {};
        }).catch(function (error) {
          _this3.submiting = false;
          console.log(error);
        });
      }
    },
    // 新增
    addSubmit: function addSubmit() {
      this.FormSubmit(this.addFormData);
    },

    /** 表格数据改变 */
    tableRowChange: function tableRowChange(params) {
      var _ref4 = params || {},
          prop = _ref4.prop,
          row = _ref4.row;

      switch (prop) {
        case 'sort':
          this.Sort(row);
          break;
      }
    },

    /* 排序处理 */
    Sort: function Sort(row) {
      var _this4 = this;

      var id = row.id,
          sort = row.sort;
      api.GIFTALASSI_UPDATESORT({
        id: id,
        sort: sort
      }).then(function () {
        _this4.$message({
          message: '操作成功!',
          type: 'success'
        });

        _this4.$refs.jPage.getPageData();
      }).catch(function (error) {
        _this4.submiting = false;
        console.log(error);
      });
    },
    init: function init() {
      var host = url.host,
          apiPrev = url.apiPrev;
      var apiUrl = url.gift.classify; //获取列表数据

      this.httpConfig = {
        url: "".concat(host).concat(apiPrev).concat(apiUrl.getPageList),
        headers: getHeaders()
      };
    }
  }
};