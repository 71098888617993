import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { FILTER_PROPS, FILTER_FORMS } from "./option/filter"; // 过滤条件的form

import TABLE_LIST from "./option/list"; // 表头

import DynamicForm from "@/components/common/dynamic-form";
import DynamicTable from "@/components/common/dynamic-table";
import DialogApplication from "./components/dialog-application";
import DialogBatch from "./components/dialog-batch";
import DialogCloseDebt from "./components/dialog-closeDebt";
import { listNoCommissionCompany } from "@api/crm/commission/commission";
export default {
  name: "commission-deal",
  components: {
    DynamicForm: DynamicForm,
    DynamicTable: DynamicTable,
    DialogApplication: DialogApplication,
    DialogCloseDebt: DialogCloseDebt,
    DialogBatch: DialogBatch
  },
  data: function data() {
    return {
      loading: false,
      filterProps: FILTER_PROPS,
      filterForms: FILTER_FORMS,
      headers: TABLE_LIST,
      table: {},
      pagination: {},
      query: {},
      tableData: [],
      showApplicationDialog: false,
      // 推介费申请窗口
      showCloseDebtDialog: false,
      // 平帐录入窗口
      showBatchDialog: false,
      // 推介费台帐窗口
      currentRow: {} // 当前点击行

    };
  },
  computed: {},
  methods: {
    init: function init() {
      this.loading = false;
      this.tableData = [];
      this.$refs.jTable.pageChange(1);
    },
    //初始化搜索条件
    initFilter: function initFilter() {
      var query = this.$refs.form.getForm();
      this.query = _objectSpread(_objectSpread({}, this.query), query);
    },
    //点击刷新
    clickRefresh: function clickRefresh() {
      this.$refs.jTable.onRefresh();
      this.$message({
        message: "数据已刷新",
        type: "info",
        duration: 500
      });
    },
    //点击重置
    clickReset: function clickReset() {
      this.$refs.form.reset();
    },
    // TODO 搜索
    clickSearch: function clickSearch() {
      this.initFilter(); //手动截取月份

      if (this.query.planDateBegin != null && this.query.planDateBegin != '') {
        this.query.planDateBegin = this.query.planDateBegin.substr(0, 7);
      }

      if (this.query.planDateEnd != null && this.query.planDateEnd != '') {
        this.query.planDateEnd = this.query.planDateEnd.substr(0, 7);
      }

      this.$refs.jTable.pageChange(1);
    },
    // TODO 获取列表
    loadData: function loadData(_ref) {
      var _this = this;

      var _ref$page = _ref.page,
          page = _ref$page === void 0 ? {} : _ref$page;
      this.initFilter();

      if (this.query.planDateBegin != null && this.query.planDateBegin != '') {
        this.query.planDateBegin = this.query.planDateBegin.substr(0, 7);
      }

      if (this.query.planDateEnd != null && this.query.planDateEnd != '') {
        this.query.planDateEnd = this.query.planDateEnd.substr(0, 7);
      }

      var param = {
        page: page,
        query: this.query
      };
      this.loading = true;
      listNoCommissionCompany(param).then(function (res) {
        _this.loading = false;
        _this.tableData = [];

        if (res.data != null) {
          var _res$data = res.data,
              data = _res$data === void 0 ? [] : _res$data;
          var _page$totalCount = page.totalCount,
              totalCount = _page$totalCount === void 0 ? 0 : _page$totalCount;
          _this.tableData = data;
          _this.pagination = {
            total: totalCount
          };
        } else {
          _this.$message({
            message: "暂无数据",
            type: "info"
          });
        }
      }).catch(function (e) {
        _this.loading = false;

        _this.$message({
          message: "查询列表数据出错",
          type: "error"
        });
      });
    },
    // TODO 选中行
    selectRow: function selectRow(row) {
      this.currentRow = row;
    },
    //处理推介费
    clickApplication: function clickApplication(row) {
      this.currentRow = row;
      this.currentRow.planDateBegin = this.query.planDateBegin; //按查询日期查询推介费用合

      this.currentRow.planDateEnd = this.query.planDateEnd; //按查询日期查询推介费用合

      this.showApplicationDialog = true;
    },
    //平帐录入 
    clickClosedDebt: function clickClosedDebt() {
      this.showCloseDebtDialog = true;
    },
    //查询推介费表
    clickBatch: function clickBatch(row) {
      this.role = null;
      this.currentRow = row;
      this.showBatchDialog = true;
    },
    //未提交推介费表 
    clickNotSubmitBatch: function clickNotSubmitBatch() {
      this.currentRow = {};
      this.showBatchDialog = true;
    }
  },
  created: function created() {}
};