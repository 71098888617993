import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
// 查询条件
var PUBLIC_CONFIG = {
  labelWidth: 80
}; // 表格列宽

var PUBLIC_TABLE_LABEL_CONFIG = {
  width: 150
};
export var TOP_FORM = {
  messageType: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '消息类型',
    col: 6,
    props: {
      placeholder: '请选择'
    },
    options: [{
      label: '全部',
      value: ''
    }, {
      label: '公司资讯',
      value: '1'
    }, {
      label: '员工资讯',
      value: '2'
    }, {
      label: '目标达成',
      value: '3'
    }, {
      label: '业绩冲刺',
      value: '4'
    }, {
      label: '付息',
      value: '5'
    }, {
      label: '活动',
      value: '6'
    }, {
      label: '新人入职',
      value: '7'
    }]
  }),
  createDateType: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '创建日期',
    col: 6,
    props: {
      placeholder: '请选择'
    },
    options: [{
      label: '全部',
      value: ''
    }, {
      label: '本年',
      value: '1'
    }, {
      label: '本季度',
      value: '2'
    }, {
      label: '本月',
      value: '3'
    }, {
      label: '本周',
      value: '4'
    }, {
      label: '本日',
      value: '5'
    }]
  })
};
export var TABLE_LABEL = [{
  label: '流水号',
  prop: 'code',
  width: 200
}, _objectSpread(_objectSpread({}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  label: '消息名称',
  prop: 'name'
}), _objectSpread(_objectSpread({}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  label: '消息类型值',
  prop: 'messageTypeValue'
}), {
  label: '消息内容',
  prop: 'content',
  width: 300
}, _objectSpread(_objectSpread({}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  label: '发送对象',
  prop: 'recipientTypeValue'
}), _objectSpread(_objectSpread({}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  label: '总人数',
  prop: 'totalCount'
}), _objectSpread(_objectSpread({}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  label: '已读人数',
  prop: 'readCount'
}), _objectSpread(_objectSpread({}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  label: '未读人数',
  prop: 'unReadCount'
}), _objectSpread(_objectSpread({}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  label: '创建时间',
  prop: 'createTime'
}), _objectSpread(_objectSpread({}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  label: '消息状态',
  prop: 'statusValue'
}), _objectSpread(_objectSpread({}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  label: '推送时间',
  prop: 'pushTime'
}), _objectSpread(_objectSpread({}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  label: '开始日期',
  prop: 'startDate'
}), _objectSpread(_objectSpread({}, PUBLIC_TABLE_LABEL_CONFIG), {}, {
  label: '结束日期',
  prop: 'endDate'
}), {
  type: 'operation',
  label: '操作',
  fixed: 'right',
  width: 150,
  formatter: function formatter(row) {
    var arr = [{
      type: 'compile',
      label: '编辑',
      permission: 'EmployeeMessage-update'
    }, {
      type: 'delete',
      label: '删除',
      permission: 'EmployeeMessage-delete'
    }];

    if (row.status !== 2) {
      arr.push({
        type: 'cancel',
        label: '作废',
        permission: 'EmployeeMessage-cancel'
      });
    }

    return arr;
  }
}];
export var TABLE_CONFIG = {
  isSelect: true,
  actions: [{
    label: '新增',
    type: 'add',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'EmployeeMessage-add'
  }, {
    label: '导出',
    type: 'export',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'EmployeeMessage-export'
  }]
};