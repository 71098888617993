import _objectSpread from "/data/jenkins/home/workspace/jq-front/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
//查询条件
var PUBLIC_CONFIG = {
  labelWidth: 80
};
var PUBLIC_CONFIGS = {
  labelWidth: 100
};
export var TOP_FORM = {
  nameOrCode: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'input',
    label: '活动名称',
    props: {
      placeholder: '请输入名称/编号'
    }
  }),
  activityTime: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'datePicker',
    col: 6,
    label: '活动时间',
    propGroup: ['beginTime', 'endTime'],
    props: {
      type: 'daterange'
    }
  }),
  createTime: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'datePicker',
    col: 6,
    label: '创建时间',
    propGroup: ['createBeginTime', 'createEndTime'],
    props: {
      type: 'daterange'
    }
  }),
  status: _objectSpread(_objectSpread({}, PUBLIC_CONFIG), {}, {
    type: 'select',
    label: '状态',
    props: {
      placeholder: '请选择状态'
    },
    options: [{
      label: '启用',
      value: 1
    }, {
      label: '停用',
      value: 0
    }]
  })
};
export var TABLE_LABEL = [{
  label: '活动编码',
  prop: 'code'
}, {
  label: '活动名称',
  prop: 'name'
}, {
  label: '活动开始时间',
  prop: 'beginTime',
  sortable: 'marketingCampaign',
  sortProp: 'beginTime'
}, {
  label: '活动结束时间',
  prop: 'endTime',
  sortable: 'marketingCampaign',
  sortProp: 'endTime'
}, {
  label: '参与人数量',
  prop: 'participantCount'
}, {
  label: '创建时间',
  prop: 'createTime',
  sortable: 'marketingCampaign',
  sortProp: 'createTime'
}, {
  label: '状态',
  prop: 'status',
  formatter: function formatter(row, column, cellValue) {
    var res = '';

    switch (Number(cellValue)) {
      case 0:
        res = '停用';
        break;

      case 1:
        res = '启用';
        break;

      default:
        res = '';
    }

    return res;
  }
}, {
  width: 350,
  showMax: 6,
  type: 'operation',
  label: '操作',
  // fixed: 'right',
  formatter: function formatter(row) {
    var arr = [];

    if (row.status === 1) {
      arr.push({
        type: 'compile',
        label: '编辑',
        permission: 'marketingActivity-update'
      }, {
        type: 'activityReward',
        label: '活动奖励',
        permission: 'virtualGift-getList'
      }, {
        type: 'detail',
        label: '参与详情',
        permission: 'marketingActivity-participationInfo'
      }, {
        type: 'pageAddress',
        label: '页面地址'
      }, {
        type: 'stop',
        label: '停用',
        permission: 'marketingActivity-updateStatus'
      });
    } else {
      arr.push({
        type: 'compile',
        label: '编辑',
        permission: 'marketingActivity-update'
      }, {
        type: 'activityReward',
        label: '活动奖励',
        permission: 'virtualGift-getList'
      }, {
        type: 'detail',
        label: '参与详情',
        permission: 'marketingActivity-participationInfo'
      }, {
        type: 'pageAddress',
        label: '页面地址'
      }, {
        type: 'start',
        label: '启用',
        permission: 'marketingActivity-updateStatus'
      }, {
        type: 'delete',
        label: '删除',
        permission: 'marketingActivity-delete'
      });
    }

    return arr;
  }
}];
export var TABLE_CONFIG = {
  isSelect: true,
  rowKey: 'id',
  actions: [{
    label: '新增',
    type: 'add',
    props: {
      size: 'small',
      type: 'primary'
    },
    permission: 'marketingActivity-add'
  }]
};
export var FORM_CONFIG = [_objectSpread(_objectSpread({}, PUBLIC_CONFIGS), {}, {
  type: 'input',
  label: '礼品名称',
  prop: 'name',
  rules: [{
    required: true,
    message: '礼品名称不能为空',
    trigger: 'blur'
  }]
}), _objectSpread(_objectSpread({}, PUBLIC_CONFIGS), {}, {
  type: 'datePicker',
  label: '生效时间',
  prop: 'beginTime',
  props: {
    type: 'datetime',
    valueFormat: 'yyyy-MM-dd'
  },
  rules: [{
    required: true,
    message: '生效时间不能为空',
    trigger: 'blur'
  }]
}), _objectSpread(_objectSpread({}, PUBLIC_CONFIGS), {}, {
  type: 'datePicker',
  label: '失效时间',
  prop: 'endTime',
  props: {
    type: 'datetime',
    valueFormat: 'yyyy-MM-dd'
  },
  rules: [{
    required: true,
    message: '失效时间不能为空',
    trigger: 'blur'
  }]
}), _objectSpread(_objectSpread({}, PUBLIC_CONFIGS), {}, {
  type: 'uploadImage',
  label: '图标',
  prop: 'image',
  rules: [{
    required: true,
    message: '图标不能为空',
    trigger: 'blur'
  }],
  props: {
    placeholder: '最多可传1张，图片大小不超过1MB，支GIF,JPEG,JPG,PNG,BMP格式'
  }
}), _objectSpread(_objectSpread({}, PUBLIC_CONFIGS), {}, {
  type: 'slot',
  label: '领取人限制',
  prop: 'existReceiveLimit',
  rules: [{
    required: true,
    message: '有效期不能为空',
    trigger: 'blur'
  }]
})];